import * as consts from "../../utils/constant";
import * as utils from "../../utils/utils";

export const getCASReport = (props) => {
    const { type, region } = props

    const getProductDivisionReport = (ReportURL, code, region) => {
        if(region?.toLowerCase()==='na')
        return `${ReportURL}&filter=CAS/product_division_code eq '${code}' and CAS/geocenter eq 'North America'`
        else
        return `${ReportURL}&filter=CAS/product_division_code eq '${code}' and CAS/geocenter eq 'Europe'`
    }

    const getSBSReport = (ReportURL, divisionCode, code, region) => {
        if(region?.toLowerCase()==='na')
        return `${ReportURL}&filter=CAS/product_division_code eq '${divisionCode}' and CAS/business_segment_code eq '${code}' and CAS/geocenter eq 'North America'`
        else
        return `${ReportURL}&filter=CAS/product_division_code eq '${divisionCode}' and CAS/business_segment_code eq '${code}' and CAS/geocenter eq 'Europe'`
    }

    const getCustomerReport = (ReportURL, code, region) => {
        code = (code)?encodeURIComponent(code):code;
        if(region?.toLowerCase()==='na')
        return `${ReportURL}&filter=CAS/account_name eq '${code}' and CAS/geocenter eq 'North America'`
        else
        return `${ReportURL}&filter=CAS/account_name eq '${code}' and CAS/geocenter eq 'Europe'`
    }

    const getCustomer_PDReport = (ReportURL, divisionCode, code, region) => {
        code = (code)?encodeURIComponent(code):code;
        if(region?.toLowerCase()==='na')
        return `${ReportURL}&filter=CAS/product_division_code eq '${divisionCode}' and CAS/account_name eq '${code}' and CAS/geocenter eq 'North America'`;
        else
        return `${ReportURL}&filter=CAS/product_division_code eq '${divisionCode}' and CAS/account_name eq '${code}' and CAS/geocenter eq 'Europe'`;
    }

    const getGroup_Report = (ReportURL, region) => {
        if(region?.toLowerCase()==='na')
        return `${ReportURL}&filter=CAS/geocenter eq 'North America'`;
        else
        return `${ReportURL}&filter=CAS/geocenter eq 'Europe'`;
    }

    const getReportLink = (view) => {
        switch (view) {
            case 'overall':
                return process.env.REACT_APP_CAS_OVERALL_LINK
            case 'survey':
                return process.env.REACT_APP_CAS_SURVEY_RESULTS_LINK
            case 'childview':
                return process.env.REACT_APP_CAS_CHILD_LINK
            case 'painpoints':
                return process.env.REACT_APP__PAIN_POINTS_LINK
            default:
                return ''
        }
    }

    if (!utils.isEmpty(props.selectedCustomer)) {
        if (!utils.isEmpty(props.selectedProductDivision)) {
            const { code: divisionCode } = props.selectedProductDivision
            const { code } = props.selectedCustomer
            return getCustomer_PDReport(getReportLink(type), divisionCode, code, region)
        } else {
            const { code } = props.selectedCustomer
            return getCustomerReport(getReportLink(type), code, region)
        }
    }
    else if (!utils.isEmpty(props.selectedSBS)) {
        const { code } = props.selectedSBS;
        const { code: divisionCode } = props.selectedProductDivision;
        return getSBSReport(getReportLink(type), divisionCode, code, region)
    }
    else if (!utils.isEmpty(props.selectedProductDivision)) {
        const { code } = props.selectedProductDivision
        return getProductDivisionReport(getReportLink(type), code, region)
    } else {
        return getGroup_Report(getReportLink(type),region)
    }
}