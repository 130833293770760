import React from 'react'
import styles from './AR.module.scss';
import * as utils from "../../../utils/utils";

function AccountReceivables(props) {
    const { open, due } = props
    //const duePercent = ((due / (open + due)) * 100).toFixed(0)
    let duePercent = ((due / open ) * 100).toFixed(0)
    duePercent = (duePercent && !isNaN(duePercent))?duePercent:0
    console.log("AR.js---open",open," due",due,"--props ",props);
    return (
        <div className={styles.orderContainer}>
            <div className={styles.header}>
               
                {<div>
                    {/*<div className={styles.color}></div>*/}
                    <span className={styles.label}>Past Due</span>
                    <span className={styles.value}>{(due !== undefined)?'$'+utils.convertToInternationalCurrencySystem(due.toFixed(0)):'-'}</span>
                    <span className={styles.value}>{` (${(duePercent)?duePercent+'%':'-'})`}</span>
               </div>}
               {<div>
                <span className={styles.label}>Total Open A/R </span>
             <span className={styles.value}>{(open !== undefined)?'$'+utils.convertToInternationalCurrencySystem(open.toFixed(0)):'-'}</span>
               </div>}
            </div>
            {<div>
                <div className={styles.barProgress} >
                <div className={duePercent === 100 ? styles.myBarFull : styles.myBar} style={{ width: `${duePercent}%` }}></div>
                </div>
            </div>}
            <div className={styles.footer}>
            </div>
        </div>
    )
}

export default AccountReceivables