import React from 'react'
import input from '../../../assets/json/inputs/config-home'
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import ExportLoggingInfo from '../../../components/excel/ExportLoggingInfo';
import styles from './home.module.scss'
import * as utils from '../../../utils/utils';
function Home(props) {

    const breadcrumbList = [
        {
            value: "Dashboard",
            path: "/dashboard",
        },
        {
            value: "Configuration",
            path: "/",
        },
    ];

    const onClick = (element) => {
        props.history.push(element.url)
    }
    const isAdminUser = () => {
        return utils.isAdminUser()
    }
    return (
        <div className={styles.container}>
            <BreadCrumb
                list={breadcrumbList}
                {...props}
                hideFavorite={true}
            />
            {isAdminUser() && <ExportLoggingInfo/>}
            <div className={styles.listcontainer}>
                {
                    input.map((element, index) => (
                        <div className={((index > 0 && index < 3) || (index === 3 && !isAdminUser())) ? `${styles.dataCardWrapper} ${styles.fadeOutLinks}` : `${styles.dataCardWrapper}`} key={index} >
                            <div className={styles.dataCard}>
                                <div className={styles.headerKpi}>{element.label}</div>
                                {
                                    element.values.map((_element, index) => (
                                        <div onClick={() => onClick(_element)} className={styles.chieldKpi} key={index} >{_element.label}
                                            <div className={styles.arrowLeft}></div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Home