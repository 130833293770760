import { connect } from 'react-redux';
import BannerInformation from '../../pages/config/banner-information/BannerInformation';
import * as consts from '../../store/constant';
const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        setBanner: (data) => dispatch({
            type: consts.SET_BANNER,
            data: data
        }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BannerInformation)
