import { SET_BANNER } from "../constant";

const BannerReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_BANNER:
            return {
                ...state,
                data: action.data
            }
        default:
            return state
    }
}

export default BannerReducer
