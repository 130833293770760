export const SET_GROUP = 'SET_GROUP'
export const SET_PRODUCT_DIVISION = 'SET_PRODUCT_DIVISION';
export const SET_SBS = 'SET_SBS';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const SET_SUB_REGION = 'SET_SUB_REGION';
export const SET_CXSCORE_DATA = 'SET_CXSCORE_DATA';
export const SET_BREAD_CRUMB = 'SET_BREAD_CRUMB';
export const SET_NBA_BREAD_CRUMB = 'SET_NBA_BREAD_CRUMB';
export const SET_KPI = 'SET_KPI';
export const SET_NBA = 'SET_NBA';
export const SET_SAS_PARENT = 'SET_SAS_PARENT';
export const SET_FAVORITE = 'SET_FAVORITE';
export const SET_PARENT_KPI = 'SET_PARENT_KPI';
export const SET_CHILD_KPI = 'SET_CHILD_KPI';
export const SET_BANNER = 'SET_BANNER';
export const SET_BANNER_TYPE = 'SET_BANNER_TYPE';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_REGION = 'SET_REGION';
