import React, { useEffect, useState } from "react";
import styles from "../performance/kpi-performance.module.scss";
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import { Spin } from 'antd';
import { useHttpGet } from '../../../components/hooks/useHttp';
import { Button, Form } from "react-bootstrap";
import _ from 'lodash';
import * as utils from '../../../utils/utils';
function BannerInformation(props) {
    const [loading, setLoading] = useState(true);
    let [showSuccesMsg, setSuccessMsg] = useState(false);
    let [isCancelClicked, setCancelFlag] = useState(false);
    const [bannerDetails, setBannerDetails] = useState([]);
    const [isBannerUpdated, setBannerUpdate] = useState(false);
    const breadcrumbList = [
        {
            value: "Dashboard",
            path: "/dashboard",
        },
        {
            value: "Configuration",
            path: "/dashboard/config",
        },
        {
            value: "Banner Information",
            path: "",
        }
    ];
    const processData = (fetchedData) => {
        localStorage.setItem("orginalBannerInfo", JSON.stringify(fetchedData));
        setBannerDetails(fetchedData);
        setLoading(false);
    }
    const [isLoading, fetchedData] = useHttpGet(
        `filePath=kpi-configuration&fileName=banner-information.json`,
        [],
        processData
    );
    async function submitBannerData() {
        setBannerUpdate(true);
        setCancelFlag(false);
        const data = prepareSendData(bannerDetails);
        utils.getData('kpiconfigurations/editbannerinformation', 'POST', data)
            .then(resp => {
                setSuccessMsg(true);
                localStorage.setItem("orginalBannerInfo", JSON.stringify(resp.data));
                props.setBanner([]);
                setTimeout(() => {
                    setSuccessMsg(false);
                }, 3000);
                setBannerUpdate(false);
            })
            .catch(error => {
                console.log(error);
                setBannerUpdate(false);
            })
    }
    const prepareSendData = (bannerDetails) => {
        let orgArr = [];
        let sendArr=[];
        orgArr = JSON.parse(localStorage.getItem("orginalBannerInfo"));
        orgArr.forEach(orgData => {
            bannerDetails.forEach(changeData => {
                if (orgData.page === changeData.page) {
                    if(!_.isEqual(orgData,changeData)){
                        sendArr.push(changeData);
                    }
                }
            });
        });
        return sendArr;
    }
    const cancelBtnHandler = (flag) => {
        let orgArr = [];
        orgArr = JSON.parse(localStorage.getItem("orginalBannerInfo"));
        if (flag) {
            if (_.isEqual(orgArr.sort(), bannerDetails.sort())) {
                console.log('both are equal..');
                props.history.push("/dashboard/config");
            } else {
                setCancelFlag(true);
            }
        } else {
            setCancelFlag(false);
            props.history.push("/dashboard/config");
        }
    }
    const handleChange = (index, element, event) => {
        let obj = [...bannerDetails];
        if (element === 'message') {
            obj[index].message = event.target.value;
        } else if (element === 'switch') {
            obj[index].isVisible = event.target.checked;
        }
        setBannerDetails(obj);
    };
    return (
        <Spin spinning={isLoading || loading} >
            <div className={styles.config_container}>
                <BreadCrumb
                    list={breadcrumbList}
                    {...props}
                    hideFavorite={true}
                />
                <div className={`row ${styles.mTop10} ${styles.resetMargin}`}>
                    <div className={`col-md-10 ${styles.resetPadding} ${styles.kpiPanel}`}>
                        <Form>
                            {bannerDetails && bannerDetails.length > 0 ? bannerDetails.map((item, index) => (
                                <div className={`row  ${styles.resetMargin}`} key={index}>
                                    <div className="col-md-10">
                                        <Form.Group className="mb-3" controlId="bannerForm">
                                            <Form.Label>{item.label}</Form.Label>
                                            <Form.Control name="bannerField" value={item.message} as="textarea" rows={2} maxLength="200" onChange={(e) => handleChange(index, 'message', e)} />
                                        </Form.Group>
                                    </div>
                                    <div className={`col-md-2  ${styles.marginTop50}`}>
                                        <Form.Group className="mb-3" controlId="bannerForm.dashboardSwitch">
                                            <Form.Check
                                                type="switch"
                                                id={item.page}
                                                name={item.page}
                                                label=""
                                                checked={item.isVisible}
                                                onChange={(e) => handleChange(index, 'switch', e)}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                            )) : null}
                            <div className={`row ${styles.resetMargin}`}>
                                <div className={`col-md-9`}>
                                    {isCancelClicked && <span className={styles.discardMsg}>Are you sure you want to cancel? Unsaved changes will be lost and values will reset to default</span>}
                                    {showSuccesMsg && <span className={styles.successMsg}>Banner message updated successfully</span>}
                                </div>
                                <div className="col-md-1">
                                    <Spin spinning={isBannerUpdated} ></Spin>
                                </div>
                                {!isCancelClicked &&
                                    <div className="col-md-1">
                                        <Button variant="primary" className={`${styles.customBtnCancel}`} onClick={() => cancelBtnHandler(true)}>Cancel</Button>
                                    </div>
                                }
                                {isCancelClicked &&
                                    <div className="col-md-1">
                                        <Button variant="primary" className={`${styles.customBtnCancel}`} onClick={() => cancelBtnHandler(false)}>Discard</Button>
                                    </div>
                                }
                                <div className="col-md-1">
                                    <Button variant="primary" className={`${styles.customBtn}`} onClick={() => submitBannerData()}>
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </div>
        </Spin>
    )
}
export default BannerInformation
