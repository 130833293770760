import React, { useState } from "react";
import styles from './cas.module.scss'
import Tabs from "../../components/tabs/Tabs";
import { getCASReport } from './Cas.utils'
import * as utils from "../../utils/utils";
import PowerBi from '../../components/powerbi/PowerBi';

const CAS = (props) => {
    const [region, setRegion] = useState(JSON.parse(sessionStorage.getItem("selectedRegion")));//EMEA Release- for Breadcrumbs
    const CAS = getCASReport({ ...props, region:region, type: 'overall' })
    const survey = getCASReport({ ...props, region:region, type: 'survey' })
    const [tabs, setTabs] = useState([
        {
            value: 'CAS',
            isActive: true,
            url: ''
        },
        {
            value: 'Pain Points Heat Map',
            isActive: false,
            url: ''
        },
        {
            value: 'Survey Results',
            isActive: false,
            url: ''
        }
    ])

    const setActive = (tab) => {
        const _tabs = [...tabs]
        _tabs.forEach(element => {
            if (element.value.toLowerCase() === tab.value.toLowerCase()) {
                element.isActive = true;
            } else {
                element.isActive = false;
            }
        })
        setTabs(_tabs)
    }

    const showChildView = () => {
        props.history.push('/dashboard/cas-childview')
    }

    return (
        <div>
            <Tabs tabs={tabs} setActive={setActive} value="CAS​" />
            {
                tabs[0].isActive ? <div className={`${styles.CAS} ${'row no-gutters'}`}>
                    <div className={`${styles.CASGraph} ${'col-sm-12 col-md-12'}`}>
                        <div className={styles.childheading}>
                            <div className={styles.overall}>Overall CAS | <span>Touchpoints included: Customer Care, Sales, Order Fulfillment, Web</span></div>
                            <div className={styles.resetBtn} onClick={utils.resetPBIView}>Reset</div>
                            <div className={styles.nextbtn} onClick={showChildView} >
                                <span>Detailed View</span>
                            </div>
                        </div>
                        <PowerBi view={CAS} height="550px" />
                    </div>
                </div> : null
            }
            {
                tabs[1].isActive ? <div className={styles.layoutBx}>
                    <div className={styles.underDesign}>Under Design</div>
                </div> : null
            }
            {
                tabs[2].isActive ? <PowerBi view={survey} height="600px" /> : null
            }
        </div>
    )
}

export default CAS