import { connect } from 'react-redux'
import Home from '../components/home/Home'
import * as consts from '../store/constant';
const mapStateToProps = state => {
    return {
        selectedProductDivision: state.ProductDivisionReducer ? state.ProductDivisionReducer.data : null,
        selectedSubRegion: state.SubRegionReducer ? state.SubRegionReducer.data : null,
        customer: state.CustomerReducer ? state.CustomerReducer.data : null,
        cxScoreDetails: state.KPIReducer ? state.KPIReducer.cxScoreDetails : null,
        selectedKPI: state.KPIReducer ? state.KPIReducer.selectedKPI : null,
        nba: state.NBAReducer ? state.NBAReducer.nba : null,
        group: state.GroupReducer ? state.GroupReducer.data : null,
        config: state.KpiConfigReducer ? state.KpiConfigReducer.data : null,
        parentKPI: state.KPIWeightageReducer ? state.KPIWeightageReducer.parentKPI : null,
        bannerData: state.BannerReducer ? state.BannerReducer.data : null,
        bannerType: state.BannerTypeReducer ? state.BannerTypeReducer.data : null
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setUserInfo: (data) => dispatch({
            type: consts.SET_USER_INFO,
            data: data
        }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
