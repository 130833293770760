import { combineReducers } from 'redux'
import ProductDivisionReducer from './ProductDivisionReducer'
import SBSReducer from './SBSReducer'
import SubRegionReducer from './SubRegionReducer'
import CustomerReducer from './CustomerReducer'
import KPIReducer from './KPIReducer'
import NBAReducer from './NBAReducer'
import SASReducer from './SASReducer'
import FavoriteReducer from './FavoriteReducer'
import GroupReducer from './GroupReducer'
import KpiConfigReducer from './KpiConfigReducer'
import KPIWeightageReducer from './KPIWeightage'
import BannerReducer from './BannerReducer';
import BannerTypeReducer from './BannerTypeReducer';
const rootReducer = combineReducers({
    GroupReducer,
    ProductDivisionReducer,
    SBSReducer,
    SubRegionReducer,
    CustomerReducer,
    KPIReducer,
    NBAReducer,
    SASReducer,
    FavoriteReducer,
    KpiConfigReducer,
    KPIWeightageReducer,
    BannerReducer,
    BannerTypeReducer
})

export default rootReducer
