import React, { useEffect, useState } from 'react';
import PowerBi from '../../components/powerbi/PowerBi';
import { Spin } from 'antd';
import style from '../nba/nba.module.scss'
import * as utils from '../../utils/utils';

const AmbiguityView = props => {   
    const [loading, setLoading] = useState(false)
    const ambiguityPBIURL = 'https://app.powerbi.com/reportEmbed?reportId=eec443ee-5611-4ca7-81a3-64a063de1147&autoAuth=true'
    useEffect(() => {
    }, [])

    return (
        <Spin spinning={loading}>
            <div className={style.primarycontainer}>
                <div className={style.layoutbx}>
                    <div className={style.childheading}>
                        <div className={style.resetBtn} onClick={utils.resetPBIView}>Reset</div>
                    </div>
                    <PowerBi view={ambiguityPBIURL} />
                </div>
            </div>
        </Spin>
    );
}

export default AmbiguityView

