import React, { useEffect, useState } from 'react';
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import styles from "./detailedview.module.scss";
import CXScore from "./Tiles/CXScore";
import CAS from "./Tiles/CAS";
import { Spin } from 'antd';
import CXList from './Tiles/CXList';
import * as utils from '../../utils/utils';
import * as consts from '../../utils/constant';
import * as favUtils from '../../utils/favorite.utils';
import Attributes from '../attributes/SBS';
import * as constants from "../../../src/utils/constant";
import * as nbaurls from "../../../src/utils/nba.utils"

const SBS = (props) => {
    const favInput = JSON.parse(JSON.stringify(props.favInput));
    const { selectedProductDivision } = props;
    const { selectedSBS } = props;
    let [FavoriteId, setFavoriteId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [region, setRegion] = useState(JSON.parse(sessionStorage.getItem("selectedRegion")));
    const [sbsDetails, setSbsDetails] = useState(null)
    const primaryRecommendation = (selectedSBS && selectedSBS.primaryRecommendation && selectedSBS.primaryRecommendation.length>0) ? Array.isArray(selectedSBS.primaryRecommendation)? selectedSBS.primaryRecommendation[0].toLowerCase() : selectedSBS.primaryRecommendation.toLowerCase()  : "";//EMEA Release- for fixing Favorite Prod issue
    const secondaryRecommendations = (selectedSBS && selectedSBS.secondaryRecommendations) ? selectedSBS.secondaryRecommendations : [];
    
    const breadcrumbList = [
        {
            value: `Dashboard (${region})`,
            path: "/dashboard",
        },
        {
            value: `${selectedProductDivision.code} (${region})`,
            path: "/dashboard/productdivision",
        },
        {
            value: `${selectedSBS.code} (${region})`,
            path: "/",
        }
    ];
    const formatData = (result) => {
        let businessUnit = []
        let hitsArray = (result.hits && result.hits.hits && result.hits.hits.length > 0) ? result.hits.hits[0] : [];
        const productDivision = (hitsArray && hitsArray.inner_hits && hitsArray.inner_hits.productDivisions && hitsArray.inner_hits.productDivisions.hits && hitsArray.inner_hits.productDivisions.hits.hits && hitsArray.inner_hits.productDivisions.hits.hits.length > 0) ? result.hits.hits[0].inner_hits.productDivisions.hits.hits[0] : [];

        if (productDivision && productDivision['_source'] && productDivision['_source'].businessUnits && productDivision['_source'].businessUnits.length > 0) {
            const businessUnit = productDivision['_source'].businessUnits.filter(item => (item.code.toLowerCase() === selectedSBS.code.toLowerCase() && item.company_region.toLowerCase()=== region.toLowerCase()))[0];
            return businessUnit
        }
        return businessUnit

    }
    useEffect(() => {
        setLoading(true)
  
        const input = {
            name: constants.SBS_ScoreDetails_Index,
            queryParams: {
                "_source": "false",
                "query": {
                    "nested": {
                      "path": "productDivisions",
                      "query": {
                        "bool": {
                          "must": [
                            {
                              "match": {
                                "productDivisions.company_region": region
                              }
                            },
                            {
                              "match": {
                                "productDivisions.code": selectedProductDivision.code
                              }
                            }
                          ]
                        }
                      },
                      "inner_hits": {

                      }
                    }
                }
            }
        }    
        setLoading(true)
        utils.getDataES('-elasticsearch', 'POST', input)
            .then(resp => {
                let groupESData = formatData(resp.data);
                const {company_region} = groupESData //EMEA Release- - Added for region specific data
                setRegion(company_region)
                setSbsDetails(groupESData)
                setLoading(false)
            }).catch(err => setLoading(false))
    }, [])

    let cxScore, cas, cxList, cxListDP;
    const getSortedCXList = (cxList) => {
        let list = [...cxList]
        list.forEach(element => {
            element.difference = element.score - element.lastQuarterValue
            element.differenceCent = ((element.score - element.lastQuarterValue) / (element.lastQuarterValue)) * 100
        });
        return list.sort(utils.sorting('differenceCent', 'number', false))
    }

    if (sbsDetails) {
        cxScore = sbsDetails.cxScore;
        if (sbsDetails && sbsDetails.cxList && sbsDetails.cxList.length > 0) {
            cxListDP = sbsDetails.cxList.find(item => item.name.toLowerCase() === consts.HELD_ORDER_INDEX.toLowerCase());
            cxScore.dataPoints = (cxListDP && cxListDP.dataPoints) ? cxListDP.dataPoints : ''
        }
        cas = sbsDetails.cas;
        cxList = getSortedCXList(sbsDetails.cxList);
    }

    const createBreadCrumb = () => {
        breadcrumbList[breadcrumbList.length - 1].path = '/dashboard/sbsdetail'
        return breadcrumbList;
    }

    const viewScore = (score) => {
        props.setCXScoreData(sbsDetails);
        props.setBreadCrumb(createBreadCrumb());
        if (score === 'cas') {
            props.setSASParent('cas');
            props.history.push("/dashboard/score/cas");
        } else {
            props.setSASParent('cx');
            props.history.push("/dashboard/score");
        }
    };

    const getInsights = (nba) => {
        if (sbsDetails.nba_insights) {
            let insights = sbsDetails.nba_insights.filter(element => element.metrics_name.toLowerCase() === nba.toLowerCase())
            if (insights && insights.length) {
                return insights[0].insights
            }
            return []
        }
        return []
    }

    const primaryView = (nba) => {
        let _nba;
        const { code } = props.selectedSBS;
        const { code: divisionCode } = props.selectedProductDivision;
        let bu_cust_urls = nbaurls.bu_nba_urls(nba, code, divisionCode)
        switch (nba) {
            case consts.IMPROVE_INVOICE_AGING:
                _nba = {
                    name: nba,
                    primaryView: bu_cust_urls.primaryView,
                    childView: bu_cust_urls.childView,
                    historyView: bu_cust_urls.historyView,
                    insights: getInsights(consts.IMPROVE_INVOICE_AGING)
                }
                props.setNBA(_nba);
                props.setBannerType(consts.IMPROVE_INVOICE_AGING.replace(/\s+/g, ''));
                break;
            case consts.REDUCE_RETURN_PERCENTAGE:
                _nba = {
                    name: nba,
                    primaryView: bu_cust_urls.primaryView,
                    childView: bu_cust_urls.childView,
                    historyView: bu_cust_urls.historyView,
                    insights: getInsights(consts.REDUCE_RETURN_PERCENTAGE)
                }
                props.setNBA(_nba);
                props.setBannerType(consts.REDUCE_RETURN_PERCENTAGE.replace(/\s+/g, ''));
                break;
            case consts.IMPROVE_PRODUCT_AVAILABILITY:
                _nba = {
                    name: nba,
                    primaryView: bu_cust_urls.primaryView,
                    childView: bu_cust_urls.childView,
                    historyView: bu_cust_urls.historyView,
                    insights: getInsights(consts.IMPROVE_PRODUCT_AVAILABILITY)
                }
                props.setNBA(_nba);
                props.setBannerType(consts.IMPROVE_PRODUCT_AVAILABILITY.replace(/\s+/g, ''));
                break;
            case consts.NEW_IMPROVE_ORDER_VELOCITY:
                _nba = {
                    name: nba,
                    primaryView: bu_cust_urls.primaryView,
                    childView: bu_cust_urls.childView,
                    historyView: bu_cust_urls.historyView,
                    insights: getInsights(consts.NEW_IMPROVE_ORDER_VELOCITY)
                }
                props.setNBA(_nba);
                props.setBannerType(consts.NEW_IMPROVE_ORDER_VELOCITY.replace(/\s+/g, ''));
                break;
            case consts.IMPROVE_OTIF:
                _nba = {
                    name: nba,
                    primaryView: bu_cust_urls.primaryView,
                    childView: bu_cust_urls.childView,
                    historyView: bu_cust_urls.historyView,
                    insights: getInsights(consts.IMPROVE_OTIF)
                }
                props.setNBA(_nba);
                props.setBannerType(consts.IMPROVE_OTIF.replace(/\s+/g, ''));
                break;
            case consts.INCREASE_CREDITLINE:
                _nba = {
                    name: nba,
                    primaryView: bu_cust_urls.primaryView,
                    childView: bu_cust_urls.childView,
                    historyView: bu_cust_urls.historyView,
                    insights: getInsights(consts.INCREASE_CREDITLINE)
                }
                props.setNBA(_nba);
                props.setBannerType(consts.INCREASE_CREDITLINE.replace(/\s+/g, ''));
                break;
            default:
                break;
        }

        props.setNBABreadCrumb(createBreadCrumb())
        props.history.push('/dashboard/nba/primaryview')
    }

    async function getFavorites() {
        const favorites = await favUtils.getFavorites()
        props.setFavorite(favorites)
    }

    async function addToFavorite(isAlreadyFavorite) {
        try {
            setLoading(true)
            if (!isAlreadyFavorite) {
                favInput.UserId = utils.getUserEmail()
                favInput.ViewType = 1
                favInput.region = region; //Added for EMEA release
                favInput.InputParameters.productDivision = props.selectedProductDivision;
                favInput.InputParameters.sbs = selectedSBS;
                if(favInput.InputParameters.productDivision.company_region)//added for EMEA release
                favInput.InputParameters.group.company_region = favInput.InputParameters.productDivision.company_region;
                const FavoriteId = await favUtils.addFavorites(favInput)
                setFavoriteId(FavoriteId)
            } else {
                await favUtils.removeFavorites(FavoriteId)
                setFavoriteId(null)
            }
            setLoading(false)
            getFavorites()
        } catch (err) {
            console.log(err);
            setLoading(false)
        }
    }

    useEffect(() => {
        setFavoriteId(favUtils.chkIsFavorite(
            props.favorites,
            region, //added for EMEA release
            1,
            selectedProductDivision,
            {},
            selectedSBS))
    }, [])

    const nbas = [
        {
            nba: consts.REDUCE_RETURN_PERCENTAGE,
            primaryRecommendation: primaryRecommendation === consts.REDUCE_RETURN_PERCENTAGE.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.REDUCE_RETURN_PERCENTAGE)
        },
        {
            nba: consts.IMPROVE_PRODUCT_AVAILABILITY,
            primaryRecommendation: primaryRecommendation === consts.IMPROVE_PRODUCT_AVAILABILITY.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.IMPROVE_PRODUCT_AVAILABILITY)
        },
        {
            nba: consts.NEW_IMPROVE_ORDER_VELOCITY,
            primaryRecommendation: primaryRecommendation === consts.NEW_IMPROVE_ORDER_VELOCITY.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.NEW_IMPROVE_ORDER_VELOCITY)
        },
        {
            nba: consts.IMPROVE_OTIF,
            primaryRecommendation: primaryRecommendation === consts.IMPROVE_OTIF.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.IMPROVE_OTIF)
        },
        {
            nba: consts.IMPROVE_INVOICE_AGING,
            primaryRecommendation: primaryRecommendation === consts.IMPROVE_INVOICE_AGING.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.IMPROVE_INVOICE_AGING)
        },
        {
            nba: consts.INCREASE_CREDITLINE,
            primaryRecommendation: primaryRecommendation === consts.INCREASE_CREDITLINE.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.INCREASE_CREDITLINE)
        }
    ]

    return (
        <Spin spinning={loading}>
            <div className={styles.cxContainer}>
                <BreadCrumb
                    list={breadcrumbList}
                    {...props}
                    addToFavorite={addToFavorite}
                    isFavorite={FavoriteId ? true : false} />
                <div className="row no-gutters">
                    <div className={`${styles.leftContent} ${"col-sm-12 col-md-12 col-lg-8"}`}>
                        {
                            sbsDetails ?
                                <React.Fragment>
                                    <div className={styles.scoreGrid}>
                                        {cxScore && <CXScore cxScore={cxScore} {...props} viewScore={viewScore} />}
                                        {cas && <CAS cas={cas} {...props} viewScore={viewScore} />}
                                        {cxList && <CXList cxList={cxList} {...props} />}
                                    </div>
                                    <div className={styles.layoutBx}>
                                        <h2 className={styles.cxHeading}>Next Best Actions</h2>
                                        <div className={styles.nbaContainer}>
                                            {
                                                nbas.map((element, index) => (
                                                    <div
                                                        key={index}
                                                        onClick={() => primaryView(element.nba)}
                                                        className={element.primaryRecommendation ? `${styles.griditem} ${styles.recommended}` :
                                                            element.isOutlineRequired ? `${styles.griditem} ${styles.otherRcmds}` : styles.griditem}
                                                    >{element.nba}</div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    {/*<div className={styles.layoutBx}>
                                        <h2 className={styles.cxHeading}>Journey Map Score</h2>
                                        <div className={styles.underDesign}>Under Design</div>
                                    </div>*/}
                                </React.Fragment> : null
                        }
                    </div>
                    <div className={`${styles.rightContent} ${"col-sm-12 col-md-12 col-lg-4"}`}>
                        <Attributes code={selectedSBS.code} divisionCode={selectedProductDivision.code} />
                    </div>
                </div>
            </div>
        </Spin>
    );
};

export default SBS;
