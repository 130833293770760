import React from 'react'
import styles from "./tiles.module.scss";

const CAS = (props) => {
    const { cas } = props;
    const { responseCount } = props.cas;
    return (
        <div className={styles.cardContainer} onClick={() => props.viewScore('cas')}>
            <div className={styles.cardHeader}>
                <div className={styles.cardTitle}>CAS</div>
                <div className={styles.nValue}>n = {cas.responseCount}</div>
            </div>
            <div className={styles.cxCardBody}>
                <div className={styles.progressVal}>
                    {
                        !responseCount ? `-` : <span>{Number(cas.score).toFixed(0)}/100</span>
                    }
                </div>
                <div className={styles.myProgress}>
                    <div className={cas.score >= cas.benchmark ? `${styles.myBar} bg-green` : `${styles.myBar} bg-red`} style={{ width: `${cas.score}%` }}></div>
                </div>
                {
                    !responseCount ? <span className={styles.indexDesc}>No transactions in current quarter</span> :
                        Number(cas.score).toFixed(0) === Number(cas.lastQuarterValue).toFixed(0) ? <div className={styles.indexDesc}>
                            <span>Same as last quarter</span>
                            <span className={styles.arrowNoChange}></span>
                        </div>
                            :
                            <div className={styles.indexDesc}>
                                <span className={cas.score > cas.lastQuarterValue ? 'text-color-green' : 'text-color-red'}>{Math.abs(Number(cas.score).toFixed(0) - Number(cas.lastQuarterValue).toFixed(0))}</span> Points
                                <span className={cas.score > cas.lastQuarterValue ? styles.arrowUp : styles.arrowDown}></span>from last quarter
                            </div>
                }
            </div>
        </div>
    )
}

export default CAS;