import React, { useEffect, useState } from "react";
import { Spin } from 'antd';
import styles from './ExportLoggingInfo.module.scss';
import { useHttpGet } from "../hooks/useHttp";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
function ExportLoggingInfo(props) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const [excelData, setexcelData] = useState([]);
    let [showNoFoundMsg, setShowNoFoundMsg] = useState(false);
    let [showMissingDateMsg, setShowMissingDateMsg] = useState(false);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const processLoggingData = (fetchedData) => {
        setexcelData(fetchedData);
    }
    const [isUserInfoLoading, fetchedData] = useHttpGet(
        `filePath=kpi-configuration&fileName=user-login-information.json`,
        [],
        processLoggingData
    );
    async function exportToExcel() {
        let filterExcelData = [];
        setShowNoFoundMsg(false);
        setShowMissingDateMsg(false);
        if (startDate && endDate) {
            let finalEndDate = new Date(endDate);
            finalEndDate.setHours(23, 59, 59);
            excelData.forEach(data => {
                if (new Date(data.loginDttm).getTime() >= startDate.getTime() &&
                    new Date(data.loginDttm).getTime() <= finalEndDate.getTime()) {
                    filterExcelData.push(data);
                }
            });
            // converting json data to excel format 
            if (filterExcelData.length > 0) {
                const fileName = `VOICE Login Data - ${prepareDateFormat(startDate)} - ${prepareDateFormat(finalEndDate)}`;
                const ws = XLSX.utils.json_to_sheet(filterExcelData);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data, fileName + fileExtension);
            } else {
                setShowNoFoundMsg(true);
            }
        } else {
            setShowMissingDateMsg(true);
        }
    }
    const prepareDateFormat = (customDate) => {
        const yyyy = customDate.getFullYear();
        let mm = customDate.getMonth() + 1; // Months start at 0!
        let dd = customDate.getDate();
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        const formattedToday = mm + '/' + dd + '/' + yyyy;
        return formattedToday;
    }
    return (
        <div className={styles.exportFlex}>
            <div className={styles.exportLabel}>Export Login Details:</div>
            <div className={styles.customCalendar}>
                <div className={styles.displayFlex}>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => {
                            setStartDate(date);
                        }}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        placeholderText="mm/dd/yyyy"
                    />
                    <span className={styles.space}>-</span>
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => {
                            setEndDate(date);
                        }}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        placeholderText="mm/dd/yyyy"
                    />
                </div>
                {showNoFoundMsg && <span className={styles.errorMsg}>No Records Found.</span>}
                {showMissingDateMsg && <span className={styles.errorMsg}>Missing Date.</span>}
            </div>
            {
                isUserInfoLoading ? <span className={styles.spinAlign}><Spin spinning={isUserInfoLoading}></Spin></span> :
                    <span className={styles.downloadImg} onClick={() => exportToExcel()}></span>
            }
        </div>
    )
}
export default ExportLoggingInfo;