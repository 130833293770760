import React, { useEffect, useState } from 'react';
import Accordion from "../../components/accordion/Accordion";
import styles from './attributes.module.scss';
import { useHttpGet } from "../../components/hooks/useHttp";
import * as utils from '../../utils/utils';
import * as constants from "../../../src/utils/constant";
import { Spin } from 'antd';


function GroupAttribute() {
    const [customers, setCustomers] = useState(null)
    const [customerSegment, setCustomerSegment] = useState(null)
    const [productDivision, setProductDivision] = useState(null)
    //const [custGained, setCustGained] = useState(null) //Remove Customer Gained/Lost section 
    //const [skus, setskus] = useState(null)
    const [open_backorder, setOpen_Backorder] = useState(null)
    const [groupDetails, setgroupDetails] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [region, setRegion] = useState(JSON.parse(sessionStorage.getItem("selectedRegion")));//EMEA Release
    
    //console.log("SKU--group attributes",skus);

    const processData = (fetchedData) => {
        //EMEA Release- - to fetch NA/EMEA region specific data based on Selection
        const selectedRegionIndex = fetchedData.group.findIndex((item) => item.company_region === region)
        const groupDetails = { ...fetchedData.group[selectedRegionIndex] }
        if (groupDetails.revenueDistribution && groupDetails.revenueDistribution.customerSegment) {
            const customerSegment = [...groupDetails.revenueDistribution.customerSegment]
            setCustomerSegment([...customerSegment.sort(utils.sorting('revenue', "number", false))])
        }
        if (groupDetails.revenueDistribution && groupDetails.revenueDistribution.productDivisions) {
            const productDivision = [...groupDetails.revenueDistribution.productDivisions]
            setProductDivision([...productDivision.sort(utils.sorting('revenue', "number", false))])
        }
       //Removed Customer Gained/Lost section
       /* const obj = {
            gained: groupDetails.customerGained,
            lost: groupDetails.customerLost
        }
        setCustGained({ ...obj })*/

        const open_backorder = {
            open: groupDetails.openorder,
            back: groupDetails.backorder
        }
        setOpen_Backorder(open_backorder)
        /*if (groupDetails.revenueDistribution && groupDetails.revenueDistribution.skus) {
            const skus = [...groupDetails.revenueDistribution.skus]
            setskus([...skus.sort(utils.sorting('revenue', "number", false))])
        }*/

        if (groupDetails.revenueDistribution && groupDetails.revenueDistribution.customers) {
            const customers = [...groupDetails.revenueDistribution.customers]
            setCustomers(customers.sort(utils.sorting('revenue', "number", false)))
        }

        return groupDetails
    };
    const getGroupData = (result) => {
        const group = (result && result.hits && result.hits.hits && result.hits.hits.length>0)?result.hits.hits[0]:[];
        return (group && group['_source'])?group['_source']:{};
    }
    useEffect(() => {
        setIsLoading(true)
        const input = {
            name: constants.Group_Attributes_Index,
            queryParams: {
                "query": {
                    "match_all": {}
                }
            }
        }
        
        utils.getDataES('-elasticsearch', 'POST', input)
            .then(resp => {
                let groupESData = getGroupData(resp.data);
                let result = processData(groupESData)
                setgroupDetails(result)
                setIsLoading(false)
            }).catch(err => setIsLoading(false))
    }, [])


    return (
        groupDetails && <div className={styles.attributes}>
            <div className={styles.attributesHeader}>{ `Life Sciences - ${region} (Group Level View)`}</div>
            <div className={styles.attributesBody}>
            {/* <div className={`${styles.groupTopSection}`}>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>YTD Revenue​</div>
                        <div className={styles.bodyValue}>{(groupDetails.totalRevenue) ? '$' + utils.convertToInternationalCurrencySystem(groupDetails.totalRevenue.toFixed(2)) : '-'}</div>
                    </div>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>YTD Growth​</div>
                        <div className={styles.bodyValue}>{(groupDetails.YTDGrowth) ? groupDetails.YTDGrowth.toFixed(2) + '%' : '-'}</div>
                    </div>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>QoQ Growth​</div>
                        <div className={styles.bodyValue}>{(groupDetails.QoQGrowth) ? groupDetails.QoQGrowth.toFixed(2) + '%' : '-'}</div>
                    </div>
                </div> */}
                {//Removed Total YTD revenue section
                /*<div className={`${styles.groupTopSection} ${styles.borderBottom}`}>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>Total YTD Revenue​</div>
                        <div className={styles.bodyValue}>{(groupDetails.globaltotalRevenue !== undefined) ? '$' + utils.convertToInternationalCurrencySystem(groupDetails.globaltotalRevenue.toFixed(2)) : '-'}</div>
                    </div>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>Total YoY Growth​</div>
                        <div className={styles.bodyValue}>{(groupDetails.globalYTDGrowth !== undefined) ? groupDetails.globalYTDGrowth.toFixed(2) + '%' : '-'}</div>
                    </div>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>Total QoQ Growth​</div>
                        <div className={styles.bodyValue}>{(groupDetails.globalQoQGrowth !== undefined) ? groupDetails.globalQoQGrowth.toFixed(2) + '%' : '-'}</div>
                    </div>
                </div>*/}
                <div>
                    {<Accordion
                        title="Top 5 Customers by Revenue​ ($M)"
                        type="customerRevenue" 
                        customers={customers}
                        voiceRevenue={groupDetails.totalRevenue}/>}
                </div>
                <div className={styles.groupTopSection}>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>VOICE YTD Revenue​</div>
                        <div className={styles.bodyValue}>{(groupDetails.totalRevenue !== undefined) ? '$' + utils.convertToInternationalCurrencySystem(groupDetails.totalRevenue.toFixed(2)) : '-'}</div>
                    </div>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>VOICE YoY Growth​</div>
                        <div className={styles.bodyValue}>{(groupDetails.YTDGrowth !== undefined) ? groupDetails.YTDGrowth.toFixed(2) + '%' : '-'}</div>
                    </div>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>VOICE QoQ Growth​</div>
                        <div className={styles.bodyValue}>{(groupDetails.QoQGrowth !== undefined) ? groupDetails.QoQGrowth.toFixed(2) + '%' : '-'}</div>
                    </div>
                </div>
                <div>
                    <Accordion
                        title="Revenue Distribution by Channel (YTD)"
                        type="customerSegment"
                        list={customerSegment}
                    />
                    <Accordion
                        title="Revenue Distribution by Product Division (YTD)"
                        type="productDivision"
                        list={productDivision}
                    />
                    <Accordion title="Open Order & Backorder"
                        type="openOrder"
                        open_backorder={open_backorder}
                    />
                    {/*<Accordion
                        title="Current Revenue for COVID SKUs vs Non COVID SKUs​"
                        type="covid"
                        skus={skus}
                    />*/}
                 
                    {//Removed Customers gained/lost accordion
                    /*<Accordion title="Customers Gained/Lost"
                        type="customerGained"
                        custGained={custGained}
                    />*/}
                </div>
            </div>
        </div>
    )
}

export default GroupAttribute