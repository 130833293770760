import { connect } from 'react-redux'
import AmbiguityView from '../pages/ambiguity/AmbiguityView'

const mapStateToProps = state => {
    return {
        
    }
}

const mapDispatchToProps = dispatch => {
    return {        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AmbiguityView)
