import { SET_BANNER_TYPE } from "../constant";

const BannerTypeReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_BANNER_TYPE:
            return {
                ...state,
                data: action.data
            }
        default:
            return state
    }
}

export default BannerTypeReducer
