import { connect } from 'react-redux'
import History from '../../pages/kpi/history/History'
import favInput from '../../assets/json/user-favorites/input.json'
import * as consts from '../../store/constant'

const mapStateToProps = state => {
    return {
        selectedSubRegion: state.SubRegionReducer ? state.SubRegionReducer.data : null,
        cxScoreDetails: state.KPIReducer ? state.KPIReducer.cxScoreDetails : null,
        breadCrumb: state.KPIReducer ? state.KPIReducer.breadCrumb : null,
        selectedKPI: state.KPIReducer ? state.KPIReducer.selectedKPI : null,
        selectedProductDivision: state.ProductDivisionReducer ? state.ProductDivisionReducer.data : null,
        selectedSBS: state.SBSReducer ? state.SBSReducer.data : null,
        selectedCustomer: state.CustomerReducer ? state.CustomerReducer.data : null,
        sasParent: state.SASReducer ? state.SASReducer.data : null,
        favInput: favInput,
        favorites: state.FavoriteReducer ? state.FavoriteReducer.data : null
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setFavorite: (data) => dispatch({
            type: consts.SET_FAVORITE,
            data: data
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(History)
