// cx index - kpi

export const INVOICING_INDEX = 'INVOICING INDEX';
export const INVOICING_INDEX_CHILD_VIEW = 'Invoicing Index Detailed View';

export const TOTAL_ADMIN_COMPLAINTS = 'TOTAL ADMIN COMPLAINTS';
export const TOTAL_ADMIN_COMPLAINTS_CHILD_VIEW = 'Total Admin Complaints Detailed View';

export const HELD_ORDER_INDEX = 'HELD ORDER INDEX';
export const HELD_ORDER_INDEX_CHILD_VIEW = 'Held Order Index Detailed View';

export const AVAILABILITY_INDEX = 'AVAILABILITY INDEX';
export const AVAILABILITY_INDEX_CHILD_VIEW = 'Availability Index Detailed View';

export const PERFECT_ORDER_INDEX = 'PERFECT ORDER INDEX';
export const PERFECT_ORDER_INDEX_CHILD_VIEW = 'Perfect Order Index Detailed View';

export const AR_DISPUTE_INDEX = 'AR DISPUTE RESOLUTION INDEX';
export const AR_DISPUTE_INDEX_CHILD_VIEW = 'AR Dispute Resolution Index Detailed View';

// nba

export const IMPROVE_INVOICE_AGING = 'Improve Invoice Aging';
export const IMPROVE_INVOICE_AGING_CHILD_VIEW = 'Improve Invoice Aging Detailed View';

export const IMPROVE_OTIF = 'Improve OTIF';
export const IMPROVE_OTIF_CHILD_VIEW = 'Improve OTIF Detailed View';

export const IMPROVE_PRODUCT_AVAILABILITY = 'Improve Product Availability';
export const IMPROVE_PRODUCT_AVAILABILITY_CHILD_VIEW = 'Improve Product Availability Detailed View';

export const REDUCE_RETURN_PERCENTAGE = 'Reduce Return Percentage';
export const REDUCE_RETURN_PERCENTAGE_CHILD_VIEW = 'Reduce Return Percentage Detailed View';

export const NEW_IMPROVE_ORDER_VELOCITY = 'Improve Order Velocity'
export const NEW_IMPROVE_ORDER_VELOCITY_CHILD_VIEW = 'Improve Order Velocity Detailed View'

export const INCREASE_CREDITLINE = 'Increase Credit Limit'
export const INCREASE_CREDITLINE_CHILD_VIEW = 'Increase Credit Limit Detailed View'

export const RECURRING_ORDER = 'Recommend Recurring Order'
export const RECURRING_ORDER_CHILD_VIEW = 'Recommend Recurring Order Detailed View'

//indexes for ES

export const Group_Dashboard_Index = 'tfsdl_nstar_voice_json_group_dashboard'
export const ProductDivision_Dashboard_Index = 'tfsdl_nstar_voice_json_productdivision_dashboard'
export const Group_ScoreDetails_Index = 'tfsdl_nstar_voice_json_group_score_details'
export const PD_ScoreDetails_Index = 'tfsdl_nstar_voice_json_productdivisons_score_details'
export const SBS_ScoreDetails_Index = 'tfsdl_nstar_voice_json_sbs_score_details'
export const Customer_ScoreDetails_Index = 'tfsdl_nstar_voice_json_customer_score_details'
export const Customer_EMEA_ScoreDetails_Index = 'tfsdl_nstar_voice_json_emea_customer_score_details'
export const PD_customer_ScoreDeatils_Index = 'tfsdl_nstar_voice_json_productdivison_customers_score_details'
export const PD_EMEA_customer_ScoreDetails_Index = 'tfsdl_nstar_voice_json_emea_productdivison_customers_score_details'
export const Group_Attributes_Index = 'tfsdl_nstar_voice_json_group_customer_attributes'
export const PD_Attributes_Index = 'tfsdl_nstar_voice_json_productdivision_attributes'
export const SBS_Attributes_Index = 'tfsdl_nstar_voice_json_sbs_customer_attributes'
export const Customer_Attributes_Index = 'tfsdl_nstar_voice_json_customer_attributes_customer'
export const PD_Customer_Attributes_Index = 'tfsdl_nstar_voice_json_productdivision_customer_attributes'
export const SubRegion_Dashboard_Index = 'tfsdl_nstar_voice_json_subregion_dashboard'
export const SubRegion_Score_Index = 'tfsdl_nstar_voice_json_subregion_score_details'
export const SubRegion_Attributes_Index = 'tfsdl_nstar_voice_json_subregion_attributes'


export const kpiWeightageCodes = [
    'QuoteExperienceIndex', 'EmailExperienceIndex', 'WebEngagementIndex',
    'WebContactUsIndex', 'PhoneExperienceIndex'
];

export const kpiPerformanceBucketsCodes = [
    'e3b8ac31-6887-4da1-8a71-b2d1ccb7e40e',
    'ecd05ef6-95e7-4f2d-b91b-c0e8a62998b4',
    'cf4db1c9-23c7-4049-87b8-e10077aef3b5',
    '3132fb16-61c0-4282-b9f5-50be81dd527f',
    '09c4d652-9448-4f3d-9b41-fc2fa5b1b935'
];

export const bannersInitialFlag = [
    {
        page: "Dashboard",
        isVisible: true
    },
    {
        page: "DetailView",
        isVisible: true
    },
    {
        page: "CX",
        isVisible: true
    },
    {
        page: "CAS",
        isVisible: true
    },
    {
        page: "ReduceReturnPercentageNBA",
        isVisible: true
    },
    {
        page: "ImproveProductAvailabilityNBA",
        isVisible: true
    },
    {
        page: "ImproveOrderVelocityNBA",
        isVisible: true
    },
    {
        page: "ImproveOTIFNBA",
        isVisible: true
    },
    {
        page: "ImproveInvoiceAgingNBA",
        isVisible: true
    },
    {
        page: "IncreaseCreditLimitNBA",
        isVisible: true
    },
    {
        page: "RecurringOrderNBA",
        isVisible: true
    },
    {
        page: "TotalAdminComplaintsKPI",
        isVisible: true
    },
    {
        page: "PerfectOrderIndexKPI",
        isVisible: true
    },
    {
        page: "HeldOrderIndexKPI",
        isVisible: true
    },
    {
        page: "AvailabilityIndexKPI",
        isVisible: true
    },
    {
        page: "ARDisputeResolutionIndexKPI",
        isVisible: true
    },
    {
        page: "InvoicingIndexKPI",
        isVisible: true
    }
];
