import React from 'react'
import styles from './listItem.module.scss'


function ListItem(props) {
const {item} = props;
return(
    <div>
        
        <div className={styles.itemId}>{`${item.id} -`}</div>
        <div className={styles.itemName}>{`${item.name}`}</div>
        
    </div>
)

}

export default ListItem