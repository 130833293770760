import * as utils from "./utils"
import * as nbaurls from "../utils/nba.utils"
import * as kpiLinks from '../pages/score/Score.utils'

export const chkIsFavorite = (favorites = [], region, ViewType, productDivision, customer, sbs, kpi, nba, subregion) => {
    let FavoriteId = null
    console.log("Favorites----------", favorites)
    const currentViewFavorites = favorites.filter(element => element.ViewType === ViewType && element?.region.toLowerCase() === region.toLowerCase())//added region condition for EMEA release
    console.log("currentViewFavorites----------Filtered version", currentViewFavorites)
    if ((ViewType === 1 || ViewType === 2 || ViewType === 3 || ViewType === 4 || ViewType === 5 || ViewType === 6 || ViewType === 7 || ViewType === 8 || ViewType === 9) && currentViewFavorites.length) {
        if (!utils.isEmpty(productDivision) && utils.isEmpty(customer) && utils.isEmpty(sbs) && utils.isEmpty(subregion)) {
            //Product Division Level
            const favs = currentViewFavorites.filter(element =>
                element.InputParameters.productDivision.code === productDivision.code
                && utils.isEmpty(element.InputParameters.sbs) && utils.isEmpty(element.InputParameters.customer))
            if (favs && favs.length) {
                if (ViewType === 3 || ViewType === 4 || ViewType === 5) {//KPI 
                    const _favs = favs.filter(element =>
                        element.InputParameters.kpi.name === kpi.name)
                    if (_favs !== undefined && _favs.length)
                        FavoriteId = _favs[0].FavoriteId
                } else if (ViewType === 6 || ViewType === 7 || ViewType === 8) {//NBA
                    const _favs = favs.filter(element =>
                        element.InputParameters.nba.name === nba.name)
                    if (_favs !== undefined && _favs.length)
                        FavoriteId = _favs[0].FavoriteId
                } else if (favs !== undefined && favs.length)
                    FavoriteId = favs[0].FavoriteId
            }
        } else if (utils.isEmpty(productDivision) && !utils.isEmpty(customer) && utils.isEmpty(sbs)  && utils.isEmpty(subregion)) {
            //Group Customer level
            const favs = currentViewFavorites.filter(element =>
                element.InputParameters.customer.code === customer.code && utils.isEmpty(element.InputParameters.productDivision))
            if (ViewType === 3 || ViewType === 4 || ViewType === 5) {//KPI
                const _favs = favs.filter(element =>
                    element.InputParameters.kpi.name === kpi.name)
                if (_favs !== undefined && _favs.length)
                    FavoriteId = _favs[0].FavoriteId
            } else if (ViewType === 6 || ViewType === 7 || ViewType === 8) {//NBA
                const _favs = favs.filter(element =>
                    element.InputParameters.nba.name === nba.name)
                if (_favs !== undefined && _favs.length)
                    FavoriteId = _favs[0].FavoriteId
            } else if (favs !== undefined && favs.length)
                FavoriteId = favs[0].FavoriteId
        } else if (!utils.isEmpty(productDivision) && !utils.isEmpty(customer) && utils.isEmpty(sbs)  && utils.isEmpty(subregion)) {
            //Product Div Customer Level
            const favs = currentViewFavorites.filter(element =>
                element.InputParameters.productDivision.code === productDivision.code &&
                element.InputParameters.customer.code === customer.code)
            if (ViewType === 3 || ViewType === 4 || ViewType === 5) {//KPI
                const _favs = favs.filter(element =>
                    element.InputParameters.kpi.name === kpi.name)
                if (_favs !== undefined && _favs.length)
                    FavoriteId = _favs[0].FavoriteId
            } else if (ViewType === 6 || ViewType === 7 || ViewType === 8) {//NBA
                const _favs = favs.filter(element =>
                    element.InputParameters.nba.name === nba.name)
                if (_favs !== undefined && _favs.length)
                    FavoriteId = _favs[0].FavoriteId
            } else if (favs !== undefined && favs.length)
                FavoriteId = favs[0].FavoriteId
        } else if (!utils.isEmpty(productDivision) && utils.isEmpty(customer) && !utils.isEmpty(sbs)  && utils.isEmpty(subregion)) {
            //Business Unit Level
            const favs = currentViewFavorites.filter(element =>
                element.InputParameters.productDivision.code === productDivision.code &&
                element.InputParameters.sbs.code === sbs.code)
            if (ViewType === 3 || ViewType === 4 || ViewType === 5) {//KPI
                const _favs = favs.filter(element =>
                    element.InputParameters.kpi.name === kpi.name)
                if (_favs !== undefined && _favs.length)
                    FavoriteId = _favs[0].FavoriteId
            } else if (ViewType === 6 || ViewType === 7 || ViewType === 8) {//NBA
                const _favs = favs.filter(element =>
                    element.InputParameters.nba.name === nba.name)
                if (_favs !== undefined && _favs.length)
                    FavoriteId = _favs[0].FavoriteId
            } else if (favs !== undefined && favs.length)
                FavoriteId = favs[0].FavoriteId
        } else if ( !utils.isEmpty(subregion) && utils.isEmpty(productDivision) && utils.isEmpty(customer) && utils.isEmpty(sbs) ){
        // subregion level
        
        
        const favs = currentViewFavorites.filter(element => element.InputParameters.subregion.code === subregion.code)
        if (ViewType === 3 || ViewType === 4 || ViewType === 5) {//KPI
            const _favs = favs.filter(element =>
                element.InputParameters.kpi.name === kpi.name)
            if (_favs !== undefined && _favs.length)
                FavoriteId = _favs[0].FavoriteId
        } else if (ViewType === 6 || ViewType === 7 || ViewType === 8) {//NBA
            const _favs = favs.filter(element =>
                element.InputParameters.nba.name === nba.name)
            if (_favs !== undefined && _favs.length)
                FavoriteId = _favs[0].FavoriteId
        } else if (favs !== undefined && favs.length)
            FavoriteId = favs[0].FavoriteId
        }
        else {
            //group Level
            const lsgItems = currentViewFavorites.filter(element =>
                utils.isEmpty(element.InputParameters.productDivision) &&
                utils.isEmpty(element.InputParameters.customer) &&
                utils.isEmpty(element.InputParameters.sbs))

            if (lsgItems !== undefined && lsgItems.length) {
                if (ViewType === 3 || ViewType === 4 || ViewType === 5) {//KPI
                    const favs = lsgItems.filter(element =>
                        element.InputParameters.kpi.name === kpi.name)
                    if (favs !== undefined && favs.length)
                        FavoriteId = favs[0].FavoriteId
                } else if (ViewType === 6 || ViewType === 7 || ViewType === 8) {//NBA
                    const favs = lsgItems.filter(element =>
                        element.InputParameters.nba.name === nba.name)
                    if (favs !== undefined && favs.length)
                        FavoriteId = favs[0].FavoriteId
                } else {
                    FavoriteId = lsgItems[0].FavoriteId
                }
            }
        }
    } 
    console.log("Favorite ID--return value--in chkIsFav",FavoriteId)
    return FavoriteId;
   }

export const addFavorites = (input) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}userfavorites`, {
            method: 'POST',
            headers: utils.headers(),
            body: JSON.stringify([input])
        })
            .then(response => response.json())
            .then(response => resolve(response[0].FavoriteId))
            .catch((error) => {
                console.log(error)
                reject(error)
                if (error.message === 'Failed to fetch') {
                    window.open(process.env.REACT_APP_URL, '_self')
                }
            })
    })
}

export const getFavorites = () => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}userfavorites?userId=${utils.getUserEmail()}`, {
            method: 'GET',
            headers: utils.headers()
        })
            .then(response => response.json())
            .then(response => resolve(response))
            .catch((error) => {
                console.log(error)
                reject(error)
                if (error.message === 'Failed to fetch') {
                    window.open(process.env.REACT_APP_URL, '_self')
                }
            })
    })
}

export const removeFavorites = (FavoriteId) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}userfavorites/${FavoriteId}`, {
            method: 'DELETE',
            headers: utils.headers()
        })
            .then(response => response.json())
            .then(response => resolve(response))
            .catch((error) => {
                console.log(error)
                reject(error)
                if (error.message === 'Failed to fetch') {
                    window.open(process.env.REACT_APP_URL, '_self')
                }
            })
    })
}

const getType = favorite => {
    switch (favorite.ViewType) {
        case 3:
        case 4:
        case 5:
            return 'KPI'
        case 6:
        case 7:
        case 8:
            return 'NBA'
        default:
            return ''
    }
}

const getSubTitle = favorite => {
    let subTitle = ''
    const { productDivision, customer, sbs, group, subregion } = favorite.InputParameters

    if (!utils.isEmpty(productDivision) && utils.isEmpty(customer) && utils.isEmpty(sbs)) {
        return subTitle.concat(productDivision.code)
    } else if (utils.isEmpty(productDivision) && !utils.isEmpty(customer) && utils.isEmpty(sbs)) {
        return subTitle.concat(utils.textLimitTo(utils.capitalize(customer.name), 20))
    } else if (!utils.isEmpty(productDivision) && !utils.isEmpty(sbs) && utils.isEmpty(customer)) {
        if (favorite.ViewType === 2 || favorite.ViewType === 9) {
            return subTitle.concat(`<div class="favoriteFlex"><div>${productDivision.code}</div><div>${sbs.code}</div></div>`)
        }
        return subTitle.concat(`<div class="favoriteFlex"><div>${productDivision.code}</div><div class="forwardArrowSubTitle"></div><div>${sbs.code}</div></div>`)
    } else if (!utils.isEmpty(productDivision) && utils.isEmpty(sbs) && !utils.isEmpty(customer)) {
        if (favorite.ViewType === 2 || favorite.ViewType === 9) {
            return subTitle.concat(`<div class="favoriteFlex"><div>${productDivision.code}</div><div>${utils.textLimitTo(utils.capitalize(customer.name), 15)}</div></div>`)
        }
        return subTitle.concat(`<div class="favoriteFlex"><div>${productDivision.code}</div><div class="forwardArrowSubTitle"></div><div>${utils.textLimitTo(utils.capitalize(customer.name), 15)}</div></div>`)
    } else if (!utils.isEmpty(subregion) &&  utils.isEmpty(productDivision) && utils.isEmpty(customer) && utils.isEmpty(sbs)) {
        return subTitle.concat(subregion.code)
    }
    else {
        return subTitle.concat(group.name)
    }
}

const getTitle = favorite => {
    switch (favorite.ViewType) {
        case 2:
            switch (favorite.cxPageId) {
                case 1:
                    return favorite.InputParameters.group.name
                case 2:
                    return utils.isEmpty(favorite.InputParameters.productDivision) ?
                        utils.textLimitTo(utils.capitalize(favorite.InputParameters.customer.name), 20) :
                        `<div class="favoriteFlex"><div>${favorite.InputParameters.productDivision.code}</div><div class="forwardArrow"></div><div>${utils.textLimitTo(utils.capitalize(favorite.InputParameters.customer.name), 15)}</div></div>`
                case 3:
                    return favorite.InputParameters.productDivision.code
                case 4:
                    return `<div class="favoriteFlex"><div>${favorite.InputParameters.productDivision.code}</div><div class="forwardArrow"></div><div>${favorite.InputParameters.sbs.code}</div></div>`
                case 5:
                    return favorite.InputParameters.subregion.name
                default:
                    return ''
            }
        case 3:
        case 4:
        case 5:
            return utils.textLimitTo(favorite.InputParameters.kpi.name, 20)
        case 6:
        case 7:
        case 8:
            return utils.textLimitTo(favorite.InputParameters.nba.name, 20)
        case 9:
            switch (favorite.casPageId) {
                case 1:
                    return favorite.InputParameters.group.name
                case 2:
                    return utils.isEmpty(favorite.InputParameters.productDivision) ?
                        utils.textLimitTo(utils.capitalize(favorite.InputParameters.customer.name), 20) :
                        `<div class="favoriteFlex"><div>${favorite.InputParameters.productDivision.code}</div><div class="forwardArrow"></div><div>${utils.textLimitTo(utils.capitalize(favorite.InputParameters.customer.name), 15)}</div></div>`
                case 3:
                    return favorite.InputParameters.productDivision.code
                case 4:
                    return `<div class="favoriteFlex"><div>${favorite.InputParameters.productDivision.code}</div><div class="forwardArrow"></div><div>${favorite.InputParameters.sbs.code}</div></div>`
                case 5:
                    return favorite.InputParameters.subregion.name
                default:
                   return ''
            }
        default:
            return ''
    }
}

const getView = favorite => {
    switch (favorite.ViewType) {
        case 2:
            return favorite.InputParameters.sasParent.toLowerCase() === 'cx' ? 'CX Details Page' : 'CAS Details Page'
        case 3:
        case 6:
            return 'Primary View'
        case 4:
        case 7:
            return 'Detailed View'
        case 5:
        case 8:
            return 'History View'
        case 9:
            return 'Overall CAS Details View'
        default:
            return null
    }
}

// 1 - Group
// 2 - Customer
// 3 - Product Division
// 4 - Business Units
// 5 - subregion
const getDetailPageId = element => {//added detailpageId 5 subregion for EMEA release bhat
    const { productDivision, customer, sbs, subregion } = element.InputParameters

    if (!utils.isEmpty(productDivision) && utils.isEmpty(customer) && utils.isEmpty(sbs) && utils.isEmpty(subregion)) {
        return 3
    } else if (utils.isEmpty(productDivision) && !utils.isEmpty(customer) && utils.isEmpty(sbs) && utils.isEmpty(subregion)) {
        return 2
    } else if (!utils.isEmpty(productDivision) && !utils.isEmpty(sbs) && utils.isEmpty(customer) && utils.isEmpty(subregion)) {
        return 4
    } else if (!utils.isEmpty(productDivision) && utils.isEmpty(sbs) && !utils.isEmpty(customer) && utils.isEmpty(subregion)) {
        return 2
    } 
    else if (utils.isEmpty(productDivision) && utils.isEmpty(sbs) && utils.isEmpty(customer) && !utils.isEmpty(subregion)) {
        return 5
    }
    else {
        return 1
    }
}

const getEntityDetailES = (url, method, input) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_ELASTICSEARCH_URL}${url}`, {
            method: method,
            headers: utils.headers(),
            body: input ? JSON.stringify(input) : undefined
        })
            .then(response => response.json())
            .then(response => resolve(response))
            .catch((error) => {
                console.log(error)
                if (error.message === 'Failed to fetch') {
                    window.open(process.env.REACT_APP_URL, '_self')
                }
            })
    })
}

const filterCustomerData = (result) => {//Group customer detail 
    const group = (result && result.hits && result.hits.hits.length) ? result.hits.hits[0] : [];
    //const customerFilterdArr = (group && group.inner_hits && group.inner_hits['group.customers'] && group.inner_hits['group.customers'].hits) ? group.inner_hits['group.customers'].hits : []
    let customerFilterdArr;
    if(group && group.inner_hits && group.inner_hits['group.customers'] && group.inner_hits['group.customers'].hits){
     customerFilterdArr = group.inner_hits['group.customers'].hits;
    }
    else{
     customerFilterdArr = [];
    }

    let new_array = []
    if (customerFilterdArr && customerFilterdArr.hits && customerFilterdArr.hits.length > 0) {
        new_array = customerFilterdArr.hits.map(function callback(element) {
            let custObj = {};
            //Added for EMEA release
            custObj.company_region = (element.fields['group.customers.company_region.keyword']) ? element.fields['group.customers.company_region.keyword'][0] : '-';
            custObj['name'] = (element.fields['group.customers.name.keyword']) ? element.fields['group.customers.name.keyword'][0] : '-';
            custObj.cxScore = (element.fields['group.customers.cxScore']) ? element.fields['group.customers.cxScore'][0] : '-';
            custObj.lastQuarterCxScore = (element.fields['group.customers.lastQuarterCxScore']) ? element.fields['group.customers.lastQuarterCxScore'][0] : '-';
            custObj.lastQuarterCas = (element.fields['group.customers.lastQuarterCas']) ? element.fields['group.customers.lastQuarterCas'][0] : '-';
            custObj.responseCount = (element.fields['group.customers.responseCount']) ? element.fields['group.customers.responseCount'][0] : '-';
            custObj.code = (element.fields['group.customers.code.keyword']) ? element.fields['group.customers.code.keyword'][0] : '-';
            custObj.id = (element.fields['group.customers.id']) ? element.fields['group.customers.id'][0] : '-';
            custObj.name = (element.fields['group.customers.name.keyword']) ? element.fields['group.customers.name.keyword'][0] : '-';
            custObj.cas = (element.fields['group.customers.cas']) ? element.fields['group.customers.cas'][0] : '-';
            //custObj.volatility = (element.fields['group.customers.volatility.keyword']) ? element.fields['group.customers.volatility.keyword'][0] : '-';
            custObj.primaryRecommendation = (element.fields['group.customers.primaryRecommendation.keyword']) ? element.fields['group.customers.primaryRecommendation.keyword'][0] : '-';
            custObj.secondaryRecommendations = (element.fields['group.customers.secondaryRecommendations.keyword']) ? element.fields['group.customers.secondaryRecommendations.keyword'] : [];
            custObj.whiteGlove = (element.fields['group.customers.whiteGlove.keyword']) ? element.fields['group.customers.whiteGlove.keyword'][0] : '-';
            return custObj
        })
    }
    group['_source'].group.customers = new_array;
    return group['_source'];
}
const formatCustDetail = (_customerDetail) => {
    let custDetailRes = [];
    if (_customerDetail && _customerDetail.data && _customerDetail.data.hits && _customerDetail.data.hits.hits && _customerDetail.data.hits.hits.length) {
        custDetailRes = _customerDetail.data.hits.hits.map(details => {
            let customerDetail = {}
            customerDetail = details['_source'];
            // customerDetail = details['inner_hits'].customers.hits.hits[0]._source // Reverted Code
            return customerDetail;
        })
        return custDetailRes
    }
    return custDetailRes;
}
const formatESData = (result) => {
    const productDivisions = (result.hits && result.hits.hits && result.hits.hits.length > 0) ? result.hits.hits[0] : [];
    const customerFilterdArr = (productDivisions && productDivisions.inner_hits && productDivisions.inner_hits['productDivisions.customers'] && productDivisions.inner_hits['productDivisions.customers'].hits) ? productDivisions.inner_hits['productDivisions.customers'].hits : [];
    let new_array = []
    if (customerFilterdArr && customerFilterdArr.hits && customerFilterdArr.hits.length > 0) {
        new_array = customerFilterdArr.hits.map(function callback(element) {
            let custObj = {};
            //Added for EMEA release
            custObj.company_region = (element.fields['productDivisions.customers.company_region.keyword']) ? element.fields['productDivisions.customers.company_region.keyword'][0] : '-';
            custObj['name'] = (element.fields['productDivisions.customers.name.keyword']) ? element.fields['productDivisions.customers.name.keyword'][0] : '-';
            custObj.cxScore = (element.fields['productDivisions.customers.cxScore']) ? element.fields['productDivisions.customers.cxScore'][0] : '-';
            custObj.lastQuarterCxScore = (element.fields['productDivisions.customers.lastQuarterCxScore']) ? element.fields['productDivisions.customers.lastQuarterCxScore'][0] : '-';
            custObj.lastQuarterCas = (element.fields['productDivisions.customers.lastQuarterCas']) ? element.fields['productDivisions.customers.lastQuarterCas'][0] : '-';
            custObj.responseCount = (element.fields['productDivisions.customers.responseCount']) ? element.fields['productDivisions.customers.responseCount'][0] : '-';
            custObj.code = (element.fields['productDivisions.customers.code.keyword']) ? element.fields['productDivisions.customers.code.keyword'][0] : '-';
            custObj.id = (element.fields['productDivisions.customers.id']) ? element.fields['productDivisions.customers.id'][0] : '-';
            custObj.name = (element.fields['productDivisions.customers.name.keyword']) ? element.fields['productDivisions.customers.name.keyword'][0] : '-';
            custObj.cas = (element.fields['productDivisions.customers.cas']) ? element.fields['productDivisions.customers.cas'][0] : '-';
           // custObj.volatility = (element.fields['productDivisions.customers.volatility.keyword']) ? element.fields['productDivisions.customers.volatility.keyword'][0] : '-';
            custObj.primaryRecommendation = (element.fields['productDivisions.customers.primaryRecommendation.keyword']) ? element.fields['productDivisions.customers.primaryRecommendation.keyword'][0] : '-';
            custObj.secondaryRecommendations=(element.fields['productDivisions.customers.secondaryRecommendations.keyword']) ? element.fields['productDivisions.customers.secondaryRecommendations.keyword'] : [];
            custObj.whiteGlove = (element.fields['productDivisions.customers.whiteGlove.keyword']) ? element.fields['productDivisions.customers.whiteGlove.keyword'][0] : '-';
            custObj.productdivision = (element.fields['productDivisions.customers.productdivision.keyword']) ? element.fields['productDivisions.customers.productdivision.keyword'][0] : '-';
            return custObj
        })
    }
    let allProductDivisions = (productDivisions && productDivisions['_source'] && productDivisions['_source'].productDivisions) ? [...productDivisions['_source'].productDivisions] : []
    const uniquePD = [...new Set(new_array.map(item => item.productdivision))]
    for (let i = 0; i < uniquePD.length; i++) { //Modified for EMEA release( added filter condition)
        let findCustomers = new_array.filter(itm => itm.productdivision === uniquePD[i] && itm.company_region.toLowerCase() === JSON.parse(sessionStorage.getItem("selectedRegion")).toLowerCase() );
        let selectedPDIndex = allProductDivisions.findIndex(item => item.code === uniquePD[i] && item.company_region.toLowerCase() === JSON.parse(sessionStorage.getItem("selectedRegion")).toLowerCase() )
        allProductDivisions[selectedPDIndex].customers = findCustomers
    }
    return allProductDivisions;
}
const formatSBSESData = (result) => {
    let BUArray = [];
    let mainArray = (result.hits && result.hits.hits && result.hits.hits.length > 0) ? result.hits.hits[0] : []
    let BUfilters = (mainArray && mainArray.inner_hits && mainArray.inner_hits.productDivisions && mainArray.inner_hits.productDivisions.hits && mainArray.inner_hits.productDivisions.hits.hits) ? mainArray.inner_hits.productDivisions.hits.hits : [];
    if (BUfilters && BUfilters.length > 0) {
        BUfilters.map((item) => {
            let result = {}
            result['businessUnits'] = item['_source']['businessUnits']
            return BUArray.push(result)
        })
    }
    return BUArray
}
const formatESPDCustomerDetailData = (result) => {
    let custDetailsPDArr = []
    let mainArray = (result.hits && result.hits.hits && result.hits.hits.length > 0) ? result.hits.hits : []
    if (mainArray && mainArray.length > 0) {
        mainArray.map((item) => {
            let result = {}
            result['custDetails'] = item['_source']
            return custDetailsPDArr.push(result);
        })
    }
    return custDetailsPDArr;
}
//Added for EMEA release
const formatESSubregionDetail = (result) =>{
   let subRegion = result.data.hits.hits[0]['_source'].group;
         subRegion.forEach((element) => {
         // element.name = element.name.toUpperCase();
         element.code = utils.capitalize(element.code.trim())
          element.cxBenchmark = element.cxProductDivisionBenchmark;
          element.casBenchmark = element.casProductDivisionBenchmark;
          element.primaryRecommendation = Array.isArray(element.primaryRecommendation)? element.primaryRecommendation[0] : element.primaryRecommendation;
        });
        subRegion.sort(utils.sorting('code', 'string', true))
 return subRegion;
}

//Added for EMEA release
const formatESSubregionNonDetail = (result) =>{
        let subRegionData;
        let hitsArray = (result.hits && result.hits.hits && result.hits.hits.length > 0) ? result.hits.hits[0] : [];
        subRegionData = (hitsArray && hitsArray?._source) ? hitsArray?._source?.group : [];
        return subRegionData;
 }

const updateNBAURls = (name, urls, insights) => {
    let nbaObj = {}
    nbaObj.primaryView = urls.primaryView
    nbaObj.childView = urls.childView
    nbaObj.historyView = urls.historyView
    nbaObj.insights = insights ? insights : []
    nbaObj.name = name;
    return nbaObj;
}
const updateKPIData = (newkpiData, oldkpiData) => {
    const { benchmark, child, dataPoints, history, insights, lastQuarterValue, name, primary, responsibleProcessTeam, score } = newkpiData;
    const { difference, differenceCent, id } = oldkpiData;
    let newkpiDataObj = {}
    newkpiDataObj.benchmark = benchmark;
    newkpiDataObj.child = child;
    newkpiDataObj.dataPoints = dataPoints;
    newkpiDataObj.difference = difference;
    newkpiDataObj.differenceCent = differenceCent;
    newkpiDataObj.id = id;
    newkpiDataObj.insights = insights;
    newkpiDataObj.lastQuarterValue = lastQuarterValue;
    newkpiDataObj.history = history;
    newkpiDataObj.name = name;
    newkpiDataObj.primary = primary;
    newkpiDataObj.responsibleProcessTeam = responsibleProcessTeam;
    newkpiDataObj.score = score;
    return newkpiDataObj
}
export async function processFavorites(favorites) {
    //processFavorites() - gets the dynamic values from Elastic Search and update Favorite tiles in dashboard
    console.log("processFavorites------",favorites);
    //Each item gets updated with view, type etc..
    favorites.map(element => {
        element.view = getView(element)
        element.type = getType(element)
        element.subTitle = getSubTitle(element)
        element.detailPageId = (element.ViewType === 1) ? getDetailPageId(element) : null
        element.cxPageId = (element.ViewType === 2) ? getDetailPageId(element) : null
        element.casPageId = (element.ViewType === 9) ? getDetailPageId(element) : null
        element.title = getTitle(element)
        return element;
    })
    //Updating with latest values
    const detailedViews = favorites.filter(element => (((!utils.isEmpty(element.InputParameters.productDivision) && utils.isEmpty(element.InputParameters.customer) && utils.isEmpty(element.InputParameters.sbs))
            || (utils.isEmpty(element.InputParameters.productDivision) && !utils.isEmpty(element.InputParameters.customer) && utils.isEmpty(element.InputParameters.sbs))
            || (utils.isEmpty(element.InputParameters.productDivision) && utils.isEmpty(element.InputParameters.customer) && utils.isEmpty(element.InputParameters.sbs))
            || (!utils.isEmpty(element.InputParameters.productDivision) && !utils.isEmpty(element.InputParameters.customer) && utils.isEmpty(element.InputParameters.sbs))
            || (!utils.isEmpty(element.InputParameters.productDivision) && utils.isEmpty(element.InputParameters.customer) && !utils.isEmpty(element.InputParameters.sbs)))))
    console.log("Detailed Views--filtered Result", detailedViews)
    if (detailedViews.length) {
        /*Group level-  Other than Detail View */
        let groupDetail;
        let items2 = detailedViews.filter(element => utils.isEmpty(element.InputParameters.productDivision)
            && utils.isEmpty(element.InputParameters.customer)
            && utils.isEmpty(element.InputParameters.sbs)
            && (element.ViewType !== 1))
        if (items2 && items2.length) {
            const groupDetailQuery = utils.groupDetailQuery();
            const groupDetailES = await getEntityDetailES('-elasticsearch', 'POST', groupDetailQuery)
            let _grp = (groupDetailES && groupDetailES.data && groupDetailES.data.hits && groupDetailES.data.hits.hits && groupDetailES.data.hits.hits.length > 0) ? groupDetailES.data.hits.hits[0]['_source'] : [];
            groupDetail = _grp.group.filter(item=> item.company_region.toLowerCase()=== JSON.parse(sessionStorage.getItem("selectedRegion")).toLowerCase())[0]; 
        }

        //Product Division Level - other than Detail page
        let pdDetail;
        let pdDetailAll;
        let items3 = detailedViews.filter(element => !utils.isEmpty(element.InputParameters.productDivision)
            && utils.isEmpty(element.InputParameters.customer)
            && utils.isEmpty(element.InputParameters.sbs)
            && (element.ViewType !== 1))
        if (items3 && items3.length) {
            const pdDetailQuery = utils.pdDetailQuery();
           const _pdDetail = await getEntityDetailES('-elasticsearch', 'POST', pdDetailQuery)
           pdDetailAll = (_pdDetail && _pdDetail.data && _pdDetail.data.hits && _pdDetail.data.hits.hits && _pdDetail.data.hits.hits.length > 0) ? _pdDetail.data.hits.hits[0]['_source']['productDivision'] : [];
           
           pdDetail = pdDetailAll.filter((pd) => pd.company_region.toLowerCase() === JSON.parse(sessionStorage.getItem("selectedRegion")).toLowerCase())
        }

        // Group-Customer level - Detail page
        let parentObj;
        let itemsCustomer = detailedViews.filter(element => utils.isEmpty(element.InputParameters.productDivision)
            && !utils.isEmpty(element.InputParameters.customer)
            && utils.isEmpty(element.InputParameters.sbs)
            && (element.ViewType === 1))
        if (itemsCustomer && itemsCustomer.length) {
            const groupCustomerQuery = utils.groupCustomerQuery(itemsCustomer);
            const _pdDetail = await getEntityDetailES('-elasticsearch', 'POST', groupCustomerQuery)
            parentObj = filterCustomerData(_pdDetail.data);
            
        } else {//Group Level and Prod Div Level
            const getGroupQuery = utils.groupQueryParams();
            let parentObjES = await getEntityDetailES('-elasticsearch', 'POST', getGroupQuery)
            parentObj = (parentObjES && parentObjES.data && parentObjES.data.hits && parentObjES.data.hits.hits && parentObjES.data.hits.hits.length > 0) ? parentObjES.data.hits.hits[0]['_source'] : [];
        }

        //Group Customer level -other than Detail Page
        let customerDetail;
        let items4 = detailedViews.filter(element => utils.isEmpty(element.InputParameters.productDivision)
            && !utils.isEmpty(element.InputParameters.customer)
            && utils.isEmpty(element.InputParameters.sbs)
            && (element.ViewType !== 1))
        if (items4 && items4.length) {
            const custDetailsQuery = utils.custDetailQuery(items4)
            let _customerDetail = await getEntityDetailES('-elasticsearch', 'POST', custDetailsQuery)
            const customerDetailArr = formatCustDetail(_customerDetail);
            customerDetail = customerDetailArr
        }
        // ProductDivison - Customer Level ( Both Detailed and other pages)
        let pdParentObj
        let items1 = detailedViews.filter(element => !utils.isEmpty(element.InputParameters.productDivision)
            && (!utils.isEmpty(element.InputParameters.customer)))
        if (items1 && items1.length) {
            const pdDetailsQuery = utils.pdQuery(items1)
            let esPDResponse = await getEntityDetailES('-elasticsearch', 'POST', pdDetailsQuery)
            const formatES = formatESData(esPDResponse.data);
            pdParentObj = formatES
        }

        //BusinessUnit Level ( Both Detailed and other pages)
        let pdSBSObj
        let items7 = detailedViews.filter(element => !utils.isEmpty(element.InputParameters.productDivision)
            && !utils.isEmpty(element.InputParameters.sbs))
        if (items7 && items7.length) {
            const pdDetailsQuery = utils.pdQuerySBS();
            let esPDResponse = await getEntityDetailES('-elasticsearch', 'POST', pdDetailsQuery)
            const formatES = formatESData(esPDResponse.data);
            pdSBSObj = formatES
        }
        //Business Unit Level - Other than Detail view
        let sbsDetail;
        let items5 = detailedViews.filter(element => !utils.isEmpty(element.InputParameters.productDivision)
            && utils.isEmpty(element.InputParameters.customer)
            && !utils.isEmpty(element.InputParameters.sbs)
            && (element.ViewType !== 1))
        if (items5 && items5.length) { 
            const pdSBSDetailsQuery = utils.pdSBSDetailsQuery(items5)
            let esPDSBSResponse = await getEntityDetailES('-elasticsearch', 'POST', pdSBSDetailsQuery)
           const formatES = formatSBSESData(esPDSBSResponse.data);
           sbsDetail = formatES
        }
        //ProductDiv Customer Level - Other than Detail view
        let pdCustomerDetail;
        let items6 = detailedViews.filter(element => !utils.isEmpty(element.InputParameters.productDivision)
            && !utils.isEmpty(element.InputParameters.customer)
            && utils.isEmpty(element.InputParameters.sbs)
            && (element.ViewType !== 1))
        if (items6 && items6.length) {
            const pdCustomerQuery = utils.pdCsutomerQuery(items6);
            let esPDResponse = await getEntityDetailES('-elasticsearch', 'POST', pdCustomerQuery)
            let ESformatedData = formatESPDCustomerDetailData(esPDResponse.data)
            pdCustomerDetail = ESformatedData
        }

        //subregion level detail view
        let subregionDetail;
        let itemsSubRegion =  detailedViews.filter(element => !utils.isEmpty(element.InputParameters.subregion)
        && utils.isEmpty(element.InputParameters.productDivision)
        && utils.isEmpty(element.InputParameters.customer)
        && utils.isEmpty(element.InputParameters.sbs)
        && (element.ViewType === 1))

        if(itemsSubRegion && itemsSubRegion.length){
        const subRgDetailQuery = utils.subrgDetailQuery(itemsSubRegion);
        let esSubregionDetailResponse = await getEntityDetailES('-elasticsearch', 'POST', subRgDetailQuery)
        let esFormattedSubregionDetail = formatESSubregionDetail(esSubregionDetailResponse)
        subregionDetail = esFormattedSubregionDetail ? esFormattedSubregionDetail :[];
        }

        //sub region other than detailed view ( Score/kPI/NBA)
        let subregionNonDetail;
        let itemsSubRegionNonDetail =  detailedViews.filter(element => !utils.isEmpty(element.InputParameters.subregion)
        && utils.isEmpty(element.InputParameters.productDivision)
        && utils.isEmpty(element.InputParameters.customer)
        && utils.isEmpty(element.InputParameters.sbs)
        && (element.ViewType !== 1))

        if(itemsSubRegionNonDetail && itemsSubRegionNonDetail.length){
        const subRgNonDetailQuery = utils.subrgNonDetailQuery(itemsSubRegionNonDetail);
        let esSubregionNonDetailResponse = await getEntityDetailES('-elasticsearch', 'POST', subRgNonDetailQuery)
        let esFormattedSubregionNonDetail = formatESSubregionNonDetail(esSubregionNonDetailResponse.data)
        subregionNonDetail = esFormattedSubregionNonDetail ? esFormattedSubregionNonDetail :[]
        }


        detailedViews.forEach(element => {
            if (utils.isEmpty(element.InputParameters.productDivision) && utils.isEmpty(element.InputParameters.customer) && utils.isEmpty(element.InputParameters.subregion)) {
                //Group level
                let selectedGroupIndex = parentObj.group.findIndex(item => (item.company_region.toLowerCase() === element.region.toLowerCase() ))

                element.InputParameters.group = parentObj.group[selectedGroupIndex];
                element.InputParameters.group.cxBenchmark = parentObj.group[selectedGroupIndex].cxGroupBenchmark;
                element.InputParameters.group.casBenchmark = parentObj.group[selectedGroupIndex].casGroupBenchmark;

                if (element.ViewType !== 1) {
                    if (Object.keys(groupDetail).length>0) {
        
                        if (!utils.isEmpty(element.InputParameters.nba) && (element.ViewType === 6 || element.ViewType === 7 || element.ViewType === 8)) {
                            let nba_insightsArray = (groupDetail && groupDetail.nba_insights) ? groupDetail.nba_insights : []
                            const insights_filtered_obj = nba_insightsArray.filter(item => item.metrics_name === element.InputParameters.nba.name)[0]
                            const insights_result = (insights_filtered_obj) ? insights_filtered_obj.insights : []
                            const name = element.InputParameters.nba.name;
                            let region = JSON.parse(sessionStorage.getItem("selectedRegion")).toLowerCase();
                            const urls = nbaurls.group_nba_urls(name, region);
                            let newNBAObj = updateNBAURls(name, urls, insights_result);
                            element.InputParameters.nba = newNBAObj
                        }
                        if (!utils.isEmpty(element.InputParameters.kpi) && (element.ViewType === 3 || element.ViewType === 4 || element.ViewType === 5)) {
                            let newCXList = [...groupDetail.cxList]
                            let updatedCxList = kpiLinks.CreateScoreLinks([], true, { "cxList": newCXList }, element.InputParameters)
                            const kpiName = element.InputParameters.kpi.name;
                            let updatedKPIData = updatedCxList.filter(item => item.name === kpiName)
                            let UpdatedKPIObj = updateKPIData(updatedKPIData[0], element.InputParameters.kpi);
                            element.InputParameters.kpi = UpdatedKPIObj;
                        }
                        element.InputParameters.cxScoreDetails = groupDetail
                    }
                }
            } else if (!utils.isEmpty(element.InputParameters.productDivision) && utils.isEmpty(element.InputParameters.customer) && utils.isEmpty(element.InputParameters.sbs) && utils.isEmpty(element.InputParameters.subregion)) {
                //Prod Div level
                
                let selectedGroupIndex = parentObj.group.findIndex(item => (item.company_region.toLowerCase() === element.region.toLowerCase() ))
                const productDivisions = parentObj.group[selectedGroupIndex].productDivisions.filter(_element => _element.code === element.InputParameters.productDivision.code && _element.company_region.toLowerCase() === element.region.toLowerCase()) //Modified for EMEA release
                
                if (Array.isArray(productDivisions)&& productDivisions.length>0) {
                    element.InputParameters.productDivision = productDivisions[0]
                    element.InputParameters.productDivision.cxBenchmark = parentObj.group.cxGroupBenchmark;
                    element.InputParameters.productDivision.casBenchmark = parentObj.group.casGroupBenchmark;
                    
                    if (element.ViewType !== 1) {
                        let sltdPDs = pdDetail.filter(item => item.code === element.InputParameters.productDivision.code)
                        
                        if (Array.isArray(sltdPDs)&& sltdPDs.length>0 ) {
                            const pdDetails = sltdPDs[0];
                            if (!utils.isEmpty(element.InputParameters.nba) && (element.ViewType === 6 || element.ViewType === 7 || element.ViewType === 8)) {
                                let nba_insightsArray = (pdDetails && pdDetails.nba_insights) ? pdDetails.nba_insights : []
                                const insights_filtered_obj = nba_insightsArray.filter(item => item.metrics_name === element.InputParameters.nba.name)[0]
                                const insights_result = (insights_filtered_obj) ? insights_filtered_obj.insights : []
                                const name = element.InputParameters.nba.name;
                                const code = (pdDetails && pdDetails.code) ? pdDetails.code : '';
                                let region = JSON.parse(sessionStorage.getItem("selectedRegion")).toLowerCase();
                                const urls = nbaurls.pd_nba_urls(name, code, region);
                                let newNBAObj = updateNBAURls(name, urls, insights_result);
                                element.InputParameters.nba = newNBAObj
                            }
                            if (!utils.isEmpty(element.InputParameters.kpi) && (element.ViewType === 3 || element.ViewType === 4 || element.ViewType === 5)) {
                                let updatedCxList = kpiLinks.CreateScoreLinks([], true, { "cxList": pdDetails.cxList }, element.InputParameters)
                                const kpiName = element.InputParameters.kpi.name;
                                let updatedKPIData = updatedCxList.filter(item => item.name === kpiName)
                                let UpdatedKPIObj = updateKPIData(updatedKPIData[0], element.InputParameters.kpi);
                                element.InputParameters.kpi = UpdatedKPIObj;
                            }
                            element.InputParameters.cxScoreDetails = sltdPDs[0]
                        }
                    }
                }
            } else if (utils.isEmpty(element.InputParameters.productDivision) && !utils.isEmpty(element.InputParameters.customer) && utils.isEmpty(element.InputParameters.sbs) && utils.isEmpty(element.InputParameters.subregion)) {
                //Group Customer Level
               
                let groupCustomer = (parentObj && parentObj.group && parentObj.group.customers) ? parentObj.group.customers : []
                const customers = groupCustomer.filter(_element => _element.code === element.InputParameters.customer.code &&  _element.company_region.toLowerCase()=== element.region.toLowerCase() ) //added for EMEA release

                if ( Array.isArray(customers) &&  customers.length > 0) {
                    element.InputParameters.customer = customers[0];
                    element.InputParameters.customer.cxBenchmark = parentObj.group.cxGroupBenchmark;
                    element.InputParameters.customer.casBenchmark = parentObj.group.casGroupBenchmark;

                    if (element.ViewType === 2 || element.ViewType === 9) {
                        let sltdPDs = customerDetail.filter(item => item.code === element.InputParameters.customer.code &&  item.company_region.toLowerCase()=== element.region.toLowerCase())
                        if (Array.isArray(sltdPDs) && sltdPDs.length>0) {
                            element.InputParameters.cxScoreDetails = sltdPDs[0]
                        }
                    }
                   } else {
                    if (element.ViewType !== 1) {
                        let sltdPDs = customerDetail.filter(item => item.code === element.InputParameters.customer.code &&  item.company_region.toLowerCase()=== element.region.toLowerCase())
                        if (Array.isArray(sltdPDs) && sltdPDs.length>0) {
                            let custDetails = sltdPDs[0];
                            //for NBA views
                            if (!utils.isEmpty(element.InputParameters.nba) && (element.ViewType === 6 || element.ViewType === 7 || element.ViewType === 8)) {
                                let nba_insightsArray = (custDetails && custDetails.nba_insights) ? custDetails.nba_insights : []
                                const insights_filtered_obj = nba_insightsArray.filter(item => item.metrics_name === element.InputParameters.nba.name)[0]
                                const insights_result = (insights_filtered_obj) ? insights_filtered_obj.insights : []
                                const name = element.InputParameters.nba.name;
                                const custCode = custDetails.code;
                                let region = JSON.parse(sessionStorage.getItem("selectedRegion")).toLowerCase();
                                const urls = nbaurls.customer_nba_urls(name, custCode, region);
                                let newNBAObj = updateNBAURls(name, urls, insights_result);
                                element.InputParameters.nba = newNBAObj
                            }
                            //for KPI views
                            if (!utils.isEmpty(element.InputParameters.kpi) && (element.ViewType === 3 || element.ViewType === 4 || element.ViewType === 5)) {
                                let updatedCxList = kpiLinks.CreateScoreLinks([], true, { "cxList": custDetails.cxList }, element.InputParameters)
                                const kpiName = element.InputParameters.kpi.name;
                                let updatedKPIData = updatedCxList.filter(item => item.name === kpiName)
                                let UpdatedKPIObj = updateKPIData(updatedKPIData[0], element.InputParameters.kpi);
                                element.InputParameters.kpi = UpdatedKPIObj;
                            }
                            element.InputParameters.cxScoreDetails = custDetails
                            
                        }
                    }
                }
            } else if (!utils.isEmpty(element.InputParameters.productDivision) && !utils.isEmpty(element.InputParameters.customer) && utils.isEmpty(element.InputParameters.sbs) && utils.isEmpty(element.InputParameters.subregion)) {
                //Product Division Customer level
               //PDivison level filter  
            let productDivisions = pdParentObj.filter(_element => _element.code === element.InputParameters.productDivision.code && _element.company_region.toLowerCase() === element.region.toLowerCase())
            if (Array.isArray(productDivisions) && productDivisions.length>0) {
                    element.InputParameters.productDivision = {
                        ...element.InputParameters.productDivision,
                        ...productDivisions[0]
                    }
                    element.InputParameters.productDivision.cxBenchmark = parentObj.group.cxProductDivisionBenchmark;
                    element.InputParameters.productDivision.casBenchmark = parentObj.group.casProductDivisionBenchmark;
                    
                    //PDivison-Customer level filter
                    let customers = productDivisions[0].customers?.filter(_element => _element.code.toLowerCase() === element.InputParameters.customer.code.toLowerCase() && _element.company_region.toLowerCase() === element.region.toLowerCase()) // commented for EMEA release
                   
                    if (Array.isArray(customers) && customers.length>0) {
                        element.InputParameters.customer = customers[0]
                        element.InputParameters.customer.cxBenchmark = productDivisions[0].cxCustomerBenchmark;
                        element.InputParameters.customer.casBenchmark = productDivisions[0].casCustomerBenchmark;

                        if (element.ViewType !== 1) {
                            
                            if (!utils.isEmpty(element.InputParameters.nba) && (element.ViewType === 6 || element.ViewType === 7 || element.ViewType === 8)) {
                                
                                const pdCustDetails = (Array.isArray(pdCustomerDetail) && pdCustomerDetail.length>0 && pdCustomerDetail[0].custDetails) ? pdCustomerDetail[0].custDetails : {};
                                let nba_insightsArray = (Object.keys(pdCustDetails).length>0 && pdCustDetails.nba_insights) ? pdCustDetails.nba_insights : []
                                const insights_filtered_obj = nba_insightsArray.filter(item => item.metrics_name === element.InputParameters.nba.name)[0]
                                const insights_result = (insights_filtered_obj) ? insights_filtered_obj.insights : []
                                const name = element.InputParameters.nba.name;
                                const custCode = pdCustDetails.code;
                                const divisionCode = pdCustDetails.product_division_code
                                const urls = nbaurls.pd_customer_nba_urls(name, custCode, divisionCode);
                                let newNBAObj = updateNBAURls(name, urls, insights_result);
                                element.InputParameters.nba = newNBAObj
                            }
                            if (!utils.isEmpty(element.InputParameters.kpi) && (element.ViewType === 3 || element.ViewType === 4 || element.ViewType === 5)) {
                                let pdCustDetails = (Array.isArray(pdCustomerDetail) && pdCustomerDetail.length>0 && pdCustomerDetail[0].custDetails) ? pdCustomerDetail[0].custDetails : [];
                                let updatedCxList = kpiLinks.CreateScoreLinks([], true, { "cxList": pdCustDetails.cxList }, element.InputParameters)
                                const kpiName = element.InputParameters.kpi.name;
                                let updatedKPIData = updatedCxList.filter(item => item.name === kpiName)
                                let UpdatedKPIObj = updateKPIData(updatedKPIData[0], element.InputParameters.kpi);
                                element.InputParameters.kpi = UpdatedKPIObj;
                            }
                            let finalresult = pdCustomerDetail.filter(items => {
                                return (items.custDetails.code === element.InputParameters.cxScoreDetails.code && items.custDetails.product_division_code === element.InputParameters.cxScoreDetails.product_division_code)
                            })

                            if (Array.isArray(finalresult) && finalresult.length>0) {
                                element.InputParameters.cxScoreDetails = finalresult[0].custDetails;
                            }
                        }
                    }
            }
            } else if (!utils.isEmpty(element.InputParameters.productDivision) && !utils.isEmpty(element.InputParameters.sbs) && utils.isEmpty(element.InputParameters.subregion)) {
                //Business Unit Level
                
                let productDivisions = pdSBSObj.filter(_element => _element.code === element.InputParameters.productDivision.code && _element.company_region.toLowerCase() === element.region.toLowerCase())
                
                if (Array.isArray(productDivisions) && productDivisions.length>0) {
                    element.InputParameters.productDivision = {
                        ...element.InputParameters.productDivision,
                        ...productDivisions[0]
                    }
                    element.InputParameters.productDivision.cxBenchmark = parentObj.group.cxProductDivisionBenchmark;
                    element.InputParameters.productDivision.casBenchmark = parentObj.group.casProductDivisionBenchmark;
                
                    let businessSegments = productDivisions[0].businessSegments.filter(_element => _element.code === element.InputParameters.sbs.code && _element.company_region.toLowerCase() === element.region.toLowerCase() )
                   
                    if (Array.isArray(businessSegments) && businessSegments.length>0) {
                        element.InputParameters.sbs = businessSegments[0]
                        element.InputParameters.sbs.cxBenchmark = businessSegments[0].cxSbsBenchmark;
                        element.InputParameters.sbs.casBenchmark = businessSegments[0].casSbsBenchmark;

                        if (element.ViewType !== 1) {
                            if (Array.isArray(sbsDetail) && sbsDetail.length > 0) {
                                let finalresult = sbsDetail[0].businessUnits.filter(res => res.code === element.InputParameters.sbs.code)
                                if (Array.isArray(finalresult) && finalresult.length>0) {
                                    if (!utils.isEmpty(element.InputParameters.nba) && (element.ViewType === 6 || element.ViewType === 7 || element.ViewType === 8)) {
                                        let buDetails = finalresult[0];
                                        //const pdCustDetails = (buDetails) ? buDetails : [];
                                        let nba_insightsArray = (buDetails && buDetails.nba_insights) ? buDetails.nba_insights : []
                                        const insights_filtered_obj = nba_insightsArray.filter(item => item.metrics_name === element.InputParameters.nba.name)[0]
                                        const insights_result = (insights_filtered_obj) ? insights_filtered_obj.insights : []
                                        const name = element.InputParameters.nba.name;
                                        const custCode = buDetails.code;
                                        const divisionCode = element.InputParameters.productDivision.code;
                                        const urls = nbaurls.bu_nba_urls(name, custCode, divisionCode);
                                        let newNBAObj = updateNBAURls(name, urls, insights_result);
                                        element.InputParameters.nba = newNBAObj
                                    }
                                    if (!utils.isEmpty(element.InputParameters.kpi) && (element.ViewType === 3 || element.ViewType === 4 || element.ViewType === 5)) {
                                        let buDetails = finalresult[0];
                                        let updatedCxList = kpiLinks.CreateScoreLinks([], true, { "cxList": buDetails.cxList }, element.InputParameters)
                                        const kpiName = element.InputParameters.kpi.name;
                                        let updatedKPIData = updatedCxList.filter(item => item.name === kpiName)
                                        let UpdatedKPIObj = updateKPIData(updatedKPIData[0], element.InputParameters.kpi);
                                        element.InputParameters.kpi = UpdatedKPIObj;
                                    }
                                    element.InputParameters.cxScoreDetails = finalresult[0];
                                }
                            }
                        }
                    }
                }
            }else if (!utils.isEmpty(element.InputParameters.subregion) && utils.isEmpty(element.InputParameters.productDivision) && utils.isEmpty(element.InputParameters.sbs) ) {
                //Sub Region Level

                let subregionDtl = subregionDetail ? subregionDetail : []
                let filteredSubRegionDetail = subregionDtl.filter((sub)=> sub.company_region.toLowerCase() === element.InputParameters.subregion.company_region.toLowerCase() && sub.code.toLowerCase() === element.InputParameters.subregion.code.toLowerCase())
               
                if (Array.isArray(filteredSubRegionDetail) && filteredSubRegionDetail.length > 0) {
                element.InputParameters.subregion.cxBenchmark = filteredSubRegionDetail[0]?.cxSubregionBenchmark;
                element.InputParameters.subregion.casBenchmark = filteredSubRegionDetail[0]?.casSubregionBenchmark;
                }
              
                if (element.ViewType !== 1) {
                        if (Array.isArray(subregionNonDetail) && subregionNonDetail.length > 0) {
                            let filteredSubRegionNonDetail = subregionNonDetail.filter((sub)=> sub.company_region.toLowerCase() === element.InputParameters.subregion.company_region.toLowerCase() && sub.code.toLowerCase() === element.InputParameters.subregion.code.toLowerCase())
                            if (Array.isArray(filteredSubRegionNonDetail) && filteredSubRegionNonDetail.length>0) {
                                if (!utils.isEmpty(element.InputParameters.nba) && (element.ViewType === 6 || element.ViewType === 7 || element.ViewType === 8)) {
                                    let subregionDetails = filteredSubRegionNonDetail[0];
                                    let nba_insightsArray = (subregionDetails && subregionDetails.nba_insights) ? subregionDetails.nba_insights : []
                                    const insights_filtered_obj = nba_insightsArray.filter(item => item.metrics_name === element.InputParameters.nba.name)[0]
                                    const insights_result = (insights_filtered_obj) ? insights_filtered_obj.insights : []
                                    const name = element.InputParameters.nba.name;
                                    const srCode = subregionDetails.code;
                                    const urls = nbaurls.sr_nba_urls(name, srCode, "EMEA");
                                    let newNBAObj = updateNBAURls(name, urls, insights_result);
                                    element.InputParameters.nba = newNBAObj;
                                }
                                if (!utils.isEmpty(element.InputParameters.kpi) && (element.ViewType === 3 || element.ViewType === 4 || element.ViewType === 5)) {
                                    let subregionDetails = filteredSubRegionNonDetail[0];
                                    let updatedCxList = kpiLinks.CreateScoreLinks([], true, { "cxList": subregionDetails.cxList }, element.InputParameters)
                                    const kpiName = element.InputParameters.kpi.name;
                                    let updatedKPIData = updatedCxList.filter(item => item.name === kpiName)
                                    let UpdatedKPIObj = updateKPIData(updatedKPIData[0], element.InputParameters.kpi);
                                    element.InputParameters.kpi = UpdatedKPIObj;
                                }
                                element.InputParameters.cxScoreDetails = filteredSubRegionNonDetail[0];
                            }
                        }
                }


            }

        })
    }

 
    detailedViews.sort(utils.sorting('CreatedDateTime', 'date', false))
    console.log("DetailedViews before return",detailedViews)
    return detailedViews
 
}