import React from 'react'
import styles from './customers.module.scss'
import * as utils from "../../../utils/utils";

function Customers(props) {
    const { custGained } = props
    return (
        <div className={styles.container}>
            <div className={styles.gained}>
                <div className={styles.label}>Customers Gained</div>
                <div className={styles.value}>{(custGained && custGained.gained !== undefined)?utils.convertToInternationalCurrencySystem(custGained.gained):'-'}</div>
            </div>
            <div className={styles.lost}>
                <div className={styles.label}>Customers Lost</div>
                <div className={styles.value}>{(custGained && custGained.lost !== undefined)?utils.convertToInternationalCurrencySystem(custGained.lost):'-'}</div>
            </div>
        </div>
    )
}

export default Customers