import React, { useEffect, useState } from "react";
import styles from "./kpi-performance.module.scss";
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb'
import { Dropdown, Button } from "react-bootstrap"
import * as configUtils from "../../../utils/config.utils";
import { Spin } from 'antd';
import _ from 'lodash'

function EditKPIperformance(props) {
    let [configData, setConfig] = useState([])
    let [showSuccesMsg, setSuccessMsg] = useState(false)
    let [isCancelClicked, setCancelFlag] = useState(false)
    let [childKPIDetails, setSelectedChildName] = useState({ BucketValues: [], KPIName: '', Unit: '' })
    let [childKPIList, setChildKPIList] = useState([])
    let [parentKPI, setParentKPI] = useState([])
    let [firstChildKPI, setFirstChildKPI] = useState([])
    let [selectedChildKPI, setselectedChildKPI] = useState([])
    let [selectedParentKPI, setselectedParentKPI] = useState([])
    const [loading, setLoading] = useState(false)
    const [isKPIUpdated, setKPIUpdate] = useState(false)

    let [showChildDropDown, setshowChildDropDown] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        loadSelectedKPIData()
        localStorage.setItem("orginalChildKpiInfo", JSON.stringify(props.config.childInfo));
    }, [])

    const loadSelectedKPIData = () => {
        let obj = {};
        let arr = []
        let childInfoObj = {}
        let obj1 = [...props.config.configData.KPIDetails]
        obj1.forEach(kpi => {
            if (kpi.ParentKPI === null) {
                parentKPI.push({ KPIName: kpi.KPIName, KPIId: kpi.KPIId })
            }

        });
        setParentKPI(parentKPI)
        childInfoObj = { ...props.config.childInfo }
        childInfoObj.BucketValues = childInfoObj.BucketValues
        childInfoObj.Unit = childInfoObj.Unit
        childInfoObj.KPIName = childInfoObj.KPIName
        setSelectedChildName(childInfoObj)
        setChildKPIList([...props.config.childKPIInfo])
        setselectedChildKPI(props.config.selectedChildKPI)
        setselectedParentKPI(props.config.selectedKPI)
        showParentKPI(props.config.selectedKPI)
    }

    const setValueHnadler = (value, curreIdx, parentidx) => {
        let obj = {
            ...childKPIDetails
        }
        obj.BucketValues[parentidx].Value[curreIdx] = value.target.value

        setSelectedChildName(obj)
    }

    const showParentKPI = (KpiId) => {
        let currentChildKPI = [];
        let showChildDropDown = props.config.configData.KPIDetails.filter(obj => obj.KPIName === KpiId);
        let currentChildData = props.config.configData.KPIDetails.filter(obj => obj.ParentKPI === showChildDropDown[0].KPIId);
        if (currentChildData && currentChildData.length) {

            currentChildData.forEach(firstKPI => {
                currentChildKPI = props.config.configData.KPIDetails.filter(obj => obj.ParentKPI === firstKPI.KPIId)
            })
            if (currentChildKPI && currentChildKPI.length) {
                setshowChildDropDown(true)
            }
            else {
                setshowChildDropDown(false)
            }

            setFirstChildKPI(currentChildData)
        }
        else {
            setshowChildDropDown(false)
        }
    }

    const breadcrumbList = [
        {
            value: "Dashboard",
            path: "/dashboard",
        },
        {
            value: "Configuration",
            path: "/dashboard/config",
        },
        {
            value: "KPI Performance Buckets",
            path: "/dashboard/config/kpi/performance",
        },
        {
            value: "Edit KPI Performance Buckets",
            path: "",
        }
    ];
    async function submitKPIData(data) {
        setKPIUpdate(true)
        setCancelFlag(false)
        configData = await configUtils.submitChildKPI(data)
        setSelectedChildName(configData)
        localStorage.setItem("orginalChildKpiInfo", JSON.stringify(configData));
        setSuccessMsg(true)
        setTimeout(() => {
            setSuccessMsg(false)
        }, 3000)
        setKPIUpdate(false)
    }
    async function getChildKPIInfo(data) {
        setLoading(true)
        const childData = await configUtils.getChildKPIInfo(data.KPIId)
        setSelectedChildName(childData)
        setLoading(false)
    }
    async function loadChildInfo(data) {
        setselectedChildKPI(data.KPIName)
        let selectKPIId = props.config.configData.KPIDetails.filter(obj => obj.ParentKPI === data.KPIId);
        setChildKPIList(selectKPIId)
        setLoading(true)
        const childData = await configUtils.getChildKPIInfo(selectKPIId[0].KPIId)
        setSelectedChildName(childData)
        setLoading(false)
    }
    async function loadTopParentInfo(data) {
        setselectedParentKPI(data.KPIName)
        setcurrentChildKPI(data.KPIId)
    }
    const setcurrentChildKPI = (value) => {
        let currentChildData = props.config.configData.KPIDetails.filter(obj => obj.ParentKPI === value && obj.ParentKPI !== null);
        if (currentChildData && currentChildData.length) {
            setshowChildDropDown(true)
            setselectedChildKPI(currentChildData[0].KPIName)
            checkChildkKPiExist(currentChildData[0].KPIId, currentChildData)
            setFirstChildKPI(currentChildData)
        }


    }
    async function checkChildkKPiExist(value, currentChild) {
        let currentChildData = props.config.configData.KPIDetails.filter(obj => obj.ParentKPI === value);
        if (currentChildData && currentChildData.length) {
            setChildKPIList(currentChildData)
            let obj = {
                ...childKPIDetails
            }
            obj.KPIName = currentChildData[0].KPIName
            setSelectedChildName(obj)
            setLoading(true)
            const childData = await configUtils.getChildKPIInfo(currentChildData[0].KPIId)
            setSelectedChildName(childData)
            setLoading(false)

        }
        else {
            setshowChildDropDown(false)
            setChildKPIList(currentChild)
            let obj = {
                ...childKPIDetails
            }
            obj.KPIName = currentChild[0].KPIName
            setSelectedChildName(obj)
            setLoading(true)
            const childData = await configUtils.getChildKPIInfo(currentChild[0].KPIId)
            setSelectedChildName(childData)
            setLoading(false)
        }
    }

    const selectSlot = (value, index) => {
        let obj = {
            ...childKPIDetails
        }
        obj.BucketValues[index].Scale = value

        setSelectedChildName(obj)

    }
    const cancelBtnHandler = (value) => {
        let orgObj = {}
        orgObj = JSON.parse(localStorage.getItem("orginalChildKpiInfo"));
        if (value) {

            setCancelFlag(true)
            if (_.isEqual(orgObj.BucketValues.sort(), childKPIDetails.BucketValues.sort())) {
                setCancelFlag(false)
                props.history.push("/dashboard/config/kpi/performance");
            }
            else {
                setCancelFlag(true)
            }

        }
        else {
            setCancelFlag(false)
            setSelectedChildName(orgObj)
            props.history.push("/dashboard/config/kpi/performance");
        }


    }
    const selectOperator = (value, index) => {

        let obj = {
            ...childKPIDetails
        }
        if (value === 'between' && obj.BucketValues[index].Value.length === 1) {
            obj.BucketValues[index].Value.push(0)
        }
        else if (value === 'between' && obj.BucketValues[index].Value.length === 2) {

        }
        else {
            obj.BucketValues[index].Value.splice(1, 1)
        }
        obj.BucketValues[index].Operator = value

        setSelectedChildName(obj)

    }

    const operatorSign = [{ 'value': '>' }, { 'value': '<' }, { 'value': 'between' }]
    const slots = [{ 'value': '0' }, { 'value': '10' }, { 'value': '20' }, { 'value': '30' }, { 'value': '40' }, { 'value': '50' }, { 'value': '60' }, { 'value': '70' }, { 'value': '80' }, { 'value': '90' }, { 'value': '100' }]
    return (
        <div className={`config_edit_panel ${styles.config_container}`}>
            <BreadCrumb
                list={breadcrumbList}
                hideFavorite={true}
                {...props} />
            <div className={`row ${styles.marTop15} ${styles.resetMargin}`}>
                <div className={`col-md-10 ${styles.resetPadding}`}>
                    <div className={`row ${styles.resetMargin}`}>
                        <div className={`col-md-4 ${styles.resetPadding}`}>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" disabled={isCancelClicked} id="dropdown-basic2" className={`${styles.btn_success_config}`}>
                                    <span title={selectedParentKPI} className={styles.truncate}>{selectedParentKPI}</span>
                                    <span className={styles.styleArrow}>X</span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className={styles.dropdownMenu1}>
                                    {
                                        parentKPI.length ? parentKPI.map((kpi, index) =>
                                        (
                                            <Dropdown.Item key={index} onClick={() => loadTopParentInfo(kpi)} title={kpi.KPIName}><span className={styles.truncate}>{kpi.KPIName}</span></Dropdown.Item>
                                        )
                                        )
                                            : null}

                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        {
                            showChildDropDown &&
                            <div className={`col-md-4`} >

                                <Dropdown>
                                    <Dropdown.Toggle variant="success" disabled={isCancelClicked} id="dropdown-basic2" className={`${styles.btn_success_config}`}>
                                        <span title={selectedChildKPI} className={styles.truncate}>{selectedChildKPI}</span>
                                        <span className={styles.styleArrow}>X</span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className={styles.dropdownMenu1}>
                                        {
                                            firstChildKPI.length ? firstChildKPI.map((kpi, index) =>
                                            (
                                                <Dropdown.Item key={index} onClick={() => loadChildInfo(kpi)}><span className={styles.truncate} title={kpi.KPIName}>{kpi.KPIName}</span></Dropdown.Item>
                                            )
                                            )
                                                : null}

                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        }
                        <div className={`col-md-4 ${showChildDropDown ? "padLeft0" : ""}`}>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" disabled={isCancelClicked} id="dropdown-basic3" className={`${styles.btn_success_config}`}>
                                    <span title={childKPIDetails.KPIName} className={styles.truncate}>{childKPIDetails.KPIName}</span>
                                    <span className={styles.styleArrow}>X</span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className={styles.dropdownMenu1}>
                                    {
                                        childKPIList.length ? childKPIList.map((kpi, idx) =>
                                        (
                                            <Dropdown.Item key={idx} onClick={() => getChildKPIInfo(kpi)}><span className={styles.truncate} title={kpi.KPIName}>{kpi.KPIName}</span></Dropdown.Item>
                                        )
                                        )
                                            : null}

                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className="col-md-2">

                </div>
            </div>
            <Spin spinning={loading} >
                <div className={`row ${styles.mTop10} ${styles.resetMargin}`}>
                    <div className={`col-md-10 ${styles.resetPadding} ${styles.kpiPanel}`}>
                        <div className={`row  ${styles.resetMargin}`}>
                            <div className="col-md-6"> <span>Child KPI: </span>
                                <span>{childKPIDetails.KPIName}</span>
                            </div>
                            <div className="col-md-6">
                                <span>Unit: </span>
                                <span>{childKPIDetails.Unit}</span>
                            </div>
                        </div>
                        <div className={`row ${styles.mTop20} ${styles.resetMargin}`}>
                            <div className="col-md-2">
                                Operator
                    </div>
                            <div className={`col-md-4 ${styles.center}`}>
                                Values
                    </div>
                            <div className="col-md-2">

                            </div>
                            <div className="col-md-3">
                                Performance Bucket
                    </div>
                        </div>

                        {
                            childKPIDetails && childKPIDetails.BucketValues && childKPIDetails.BucketValues.length ? childKPIDetails.BucketValues.map((bucket, buketIdx) =>
                            (
                                <div key={buketIdx} className={`row ${styles.mTop20} ${styles.resetMargin}`}>
                                    <div className="col-md-2">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" className={`${styles.btn_success_config}`}>
                                                {bucket.Operator}
                                                <span className={styles.styleArrow}>X</span>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className={styles.dropdownMenu1}>
                                                {
                                                    operatorSign.map((opt, index) =>
                                                    (
                                                        <Dropdown.Item key={index} onClick={() => selectOperator(opt.value, buketIdx)} key={index}>{opt.value}</Dropdown.Item>
                                                    )
                                                    )
                                                }

                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    {
                                        bucket.Value.map((element, index) => (
                                            <div key={index} className={`col-md-2 ${index !== 0 ? styles.resetRighPadding : "col-md-2"}`}>
                                                <div className="form-group">

                                                    <React.Fragment key={index} >
                                                        {
                                                            bucket.Operator === 'between' && index !== 0 ? <div className={styles.dispFlex}> <span className={styles.operatorBtw}>&</span> <input name={index} type="text" className="form-control" value={element} onChange={(e) => setValueHnadler(e, index, buketIdx)} /></div>
                                                                : <div>
                                                                    <input name={index} type="text" className="form-control" value={element} onChange={(e) => setValueHnadler(e, index, buketIdx)} />
                                                                </div>
                                                        }

                                                    </React.Fragment>

                                                </div>
                                            </div>
                                        ))}
                                    <div className={`${bucket.Operator === 'between' ? "col-md-2" : "col-md-4"}`}></div>

                                    <div className="col-md-2">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" className={`${styles.btn_success_config}`}>
                                                {bucket.Scale}
                                                <span className={styles.styleArrow}>X</span>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className={styles.dropdownMenu1}>
                                                {
                                                    slots.map((slot, index) =>
                                                    (
                                                        <Dropdown.Item onClick={() => selectSlot(slot.value, buketIdx)} key={index}>{slot.value}</Dropdown.Item>
                                                    )
                                                    )
                                                }

                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="col-md-2"></div>
                                </div>
                            )) : null}

                        <div className={`row ${styles.resetMargin}`}>
                            <div className={`col-md-9`}>
                                {isCancelClicked && <span className={styles.discardMsg}>Are you sure you want to cancel? Unsaved changes will be lost and values will reset to default</span>}
                                {showSuccesMsg && <span className={styles.successMsg}>Child KPI updated successfully</span>}
                            </div>
                            <div className="col-md-1">
                                <Spin spinning={isKPIUpdated} ></Spin>
                            </div>
                            {!isCancelClicked &&
                                <div className="col-md-1">
                                    <Button variant="primary" className={`${styles.customBtnCancel}`} onClick={() => cancelBtnHandler(true)}>Cancel</Button>
                                </div>
                            }
                            {isCancelClicked &&
                                <div className="col-md-1">
                                    <Button variant="primary" className={`${styles.customBtnCancel}`} onClick={() => cancelBtnHandler(false)}>Discard</Button>
                                </div>
                            }
                            <div className="col-md-1">

                                <Button variant="primary" className={`${styles.customBtn}`} onClick={() => submitKPIData(childKPIDetails)}>
                                    Save
                                </Button>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </Spin>
        </div>
    )
}
export default EditKPIperformance;