import React, { useEffect } from 'react'
import c3 from "c3";
function CXBarChart(props) {
    const { height } = props
    const keys = [...props.keys]
     const values = [...props.values];
    let csTrendVals ={
        x: 'x',
        columns:
        [
          ['x', ...keys],
          ['CX Score', ...values]
        ],
        type: 'bar',
        labels: true,
        // colors: {
        //     CxScore: 'rgb(155, 211, 222)'
        //     //CxScore: '#ffbb78'
        // },
        // colors: {
        //     CxScore: function(d) {
        //       if(d.value){
        //           var colors = ['#1f77b4', '#aec7e8', '#ff7f0e', '#ffbb78', '#2ca02c', '#98df8a', '#d62728', '#ff9896', '#9467bd', '#c5b0d5', '#8c564b', '#c49c94', '#e377c2', '#f7b6d2', '#7f7f7f', '#c7c7c7', '#bcbd22', '#dbdb8d', '#17becf', '#9edae5'];                    
        //           let colorCode = colors[d.index];
        //          return colorCode;
        //       }
        //   }
        // }
      }
    useEffect(() => {
        var chart = c3.generate({
            bindto: `#cxScoreTrandingChart`,
            size: {
                height: height,
                width: 550
            },
            data: csTrendVals,
            axis: {
                x: {
                    type: 'category',
                    // height: 180
                    tick: {
                        rotate: -50,
                        multiline: false
                    },
                    width: 400,
                }
            },
            bar: {
                width: {
                    ratio: 0.5 // this makes bar width 50% of length between ticks
                },
                // or
                // width: 100 // this makes bar width 100px
            },
            legend: {
                show: false
            },
            padding: {
                bottom: 60 //adjust chart padding bottom
                // bottom: 20 //adjust chart padding bottom
            }
            // size: {
            //    height:height
            //   }
        });

    })

    return (
        <div id="cxScoreTrandingChart" />
    )

}


export default CXBarChart