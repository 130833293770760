import Dashboard from '../pages/dashboard/Dashboard';
import { connect } from 'react-redux'
import * as consts from '../store/constant';

const mapStateToProps = state => {
    return {
        selectedProductDivision: state.ProductDivisionReducer ? state.ProductDivisionReducer.data : null,
        userInfo: state.GroupReducer ? state.GroupReducer.userInfo : null,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setGroup: (data) => dispatch({
            type: consts.SET_GROUP,
            data: data
        }),
        setBanner: (data) => dispatch({
            type: consts.SET_BANNER,
            data: data
        }),
        setSelectedSBS: (data) => dispatch({
            type: consts.SET_SBS,
            data: data
        }),
        setProductDivision: (data) => dispatch({
            type: consts.SET_PRODUCT_DIVISION,
            data: data
        }),
        setCustomer: (data) => dispatch({
            type: consts.SET_CUSTOMER,
            data: data
        }),
        setSubRegion: (data) => dispatch({
            type: consts.SET_SUB_REGION,
            data: data
        }), 
        setCXScoreData: (data) => dispatch({
            type: consts.SET_CXSCORE_DATA,
            data: data
        }),
        setBreadCrumb: (data) => dispatch({
            type: consts.SET_BREAD_CRUMB,
            data: data
        }),
        setSASParent: (data) => dispatch({
            type: consts.SET_SAS_PARENT,
            data: data
        }),
        setKPI: (data) => dispatch({
            type: consts.SET_KPI,
            data: data
        }),
        setFavorite: (data) => dispatch({
            type: consts.SET_FAVORITE,
            data: data
        }), 
        setNBA: (data) => dispatch({
            type: consts.SET_NBA,
            data: data
        }),
        setNBABreadCrumb: (data) => dispatch({
            type: consts.SET_NBA_BREAD_CRUMB,
            data: data
        }),
        setRegion: (data) => dispatch({
            type: consts.SET_REGION,
            data: data
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
