import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import PowerBi from '../../../components/powerbi/PowerBi';
import * as favUtils from '../../../utils/favorite.utils';
import * as utils from '../../../utils/utils';
import style from '../nba.module.scss'
import { Spin } from 'antd';

const History = props => {
    const favInput = JSON.parse(JSON.stringify(props.favInput));
    let [FavoriteId, setFavoriteId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [region, setRegion] = useState(JSON.parse(sessionStorage.getItem("selectedRegion")));//EMEA Release- 
    const { selectedSubRegion } = props;//added for EMEA release
    const breadCrumb = [...props.breadCrumb, {
        value: 'History',
        path: "/",
    }]

    const goBack = () => {
        props.history.push('/dashboard/nba/childview')
    };

    async function getFavorites() {
        const favorites = await favUtils.getFavorites()
        props.setFavorite(favorites)
    }

    async function addToFavorite(isAlreadyFavorite) {
        try {
            setLoading(true)
            if (!isAlreadyFavorite) {
                favInput.UserId = utils.getUserEmail()
                favInput.ViewType = 8
                favInput.region = region; //Added for EMEA release
                favInput.InputParameters.productDivision = props.selectedProductDivision;
                favInput.InputParameters.customer = props.selectedCustomer;
                favInput.InputParameters.sbs = props.selectedSBS;
                favInput.InputParameters.breadcrumb = props.breadCrumb;
                favInput.InputParameters.nba = props.nba;
                favInput.InputParameters.subregion = selectedSubRegion;//added for EMEA release
                favInput.InputParameters.group.company_region = region;//added for EMEA release
                favInput.InputParameters.nba.company_region = region;//added for EMEA release
                /*if(Object.keys(props.selectedProductDivision).length>0 && Object.keys(props.nba).length>0){
                    favInput.InputParameters.group.company_region = region;//favInput.InputParameters.productDivision.company_region;
                    favInput.InputParameters.nba.company_region = region;//favInput.InputParameters.productDivision.company_region;
                }
                if(Object.keys(props.selectedSBS).length>0 && Object.keys(props.nba).length>0){
                    favInput.InputParameters.group.company_region = region;//favInput.InputParameters.sbs.company_region
                    favInput.InputParameters.nba.company_region = region;//favInput.InputParameters.sbs.company_region;
                }
                if(Object.keys(props.selectedCustomer).length>0 && Object.keys(props.nba).length>0){
                    favInput.InputParameters.group.company_region = region;//favInput.InputParameters.customer.company_region
                    favInput.InputParameters.nba.company_region = region;//favInput.InputParameters.customer.company_region;
                }
                if(Object.keys(selectedSubRegion).length>0 && Object.keys(props.nba).length>0){
                    favInput.InputParameters.group.company_region = region;//favInput.InputParameters.subregion.company_region
                    favInput.InputParameters.nba.company_region = region;//favInput.InputParameters.subregion.company_region;
                }*/
                const FavoriteId = await favUtils.addFavorites(favInput)
                setFavoriteId(FavoriteId)
            } else {
                await favUtils.removeFavorites(FavoriteId)
                setFavoriteId(null)
            }
            setLoading(false)
            getFavorites()
        } catch (err) {
            console.log(err);
            setLoading(false)
        }
    }

    useEffect(() => {
        setFavoriteId(favUtils.chkIsFavorite(
            props.favorites,
            region,//added for EMEA release
            8,
            props.selectedProductDivision,
            props.selectedCustomer,
            props.selectedSBS,
            '',
            props.nba,
            selectedSubRegion))
    }, [])

    return (
        <Spin spinning={loading}>
            <div className={style.primarycontainer}>
                <BreadCrumb
                    list={breadCrumb}
                    addToFavorite={addToFavorite}
                    isFavorite={FavoriteId ? true : false}
                    {...props} />
                <div className={style.layoutbx}>
                    <div className={style.childheading}>
                        <div className={style.backBtn} onClick={goBack}>Back</div>
                        <div className={style.resetBtn} onClick={utils.resetPBIView}>Reset</div>
                        <span className={style.childheadingtext}>
                        </span>
                    </div>
                    <PowerBi view={props.nba.historyView} height="700px" />
                </div>
            </div>
        </Spin>
    );
}

export default History

