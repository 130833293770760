import React from 'react'

function PowerBi(props) {
    console.log(props.view)
    return (
        <iframe
            width="100%"
            height={props.height || "1000px"}
            src={props.view}
            frameBorder="0"
            id="powerbi"
            allowFullScreen={true}></iframe>
    )
}

export default PowerBi