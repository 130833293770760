import React, { useEffect, useState, useRef } from "react";
import { useHttpGet } from "../../components/hooks/useHttp";
import Product from "../../components/product/Product";
import Title from "../../components/title/Title";
import styles from "./productdivision.module.scss";
import Carousel, { consts } from "react-elastic-carousel";
import { Spin } from 'antd';
import { capitalize } from "../../utils/utils";
import * as utils from "../../utils/utils";
import * as constants from "../../../src/utils/constant";

const ProductDivision = (props) => {
  const [fetchedData, setfetchedData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [region, setRegion] = useState(JSON.parse(sessionStorage.getItem("selectedRegion")));//EMEA Release- for Breadcrumbs initial loading
  const [customers, setCustomers] = useState([]);
  const [businessSegments, setbusinessSegments] = useState([]);
  const processData = (fetchedData) => {
    return fetchedData.filter(
      (element) => (element.code.toLowerCase() === props.selectedProductDivision.code.toLowerCase() && element.company_region.toLowerCase() === region.toLowerCase())
    );
  };

  const mycarousel = useRef(null);
  const myBUCarousel = useRef(null);

  // const [isLoading, fetchedData] = useHttpGet(
  //   process.env.REACT_APP_PRODUCTDIVISION_DATA,
  //   [],
  //   processData
  // );

  let productDivision;
  let buSegments;
  productDivision = props.selectedProductDivision;

  let customerz = [];
  useEffect(() => {
    if (Array.isArray(fetchedData) && fetchedData.length > 0) {
      buSegments = fetchedData[0].businessSegments;
      buSegments.forEach((element) => {
        //element.fullname = element.name.trim()
        //element.name = element.code.trim()
        element.cxBenchmark = fetchedData[0].cxSbsBenchmark;
        element.casBenchmark = fetchedData[0].casSbsBenchmark;
      });
      buSegments.sort(utils.sorting('code', 'string', true))
      setbusinessSegments(buSegments)
      console.log("BU Segments -- setBUSegments",buSegments)

      customerz = fetchedData[0].customers;

      customerz.sort(utils.sorting('name', 'string', true))
      customerz.forEach((element) => {
        element.name = capitalize(element.name.trim())
        element.cxBenchmark = fetchedData[0].cxCustomerBenchmark;
        element.casBenchmark = fetchedData[0].casCustomerBenchmark;
      });
      setCustomers(customerz)
      //initialLoadArrow();
    }
  }, [fetchedData])


  const showProductDivisionDetail = (item) => {
    props.history.push("/dashboard/productdivisiondetail");
  };

  const showCustomerDetail = (item) => {
    props.setCustomer(item)
    props.history.push("/dashboard/customerdetail");
  };

  const showSBSDetail = (item) => {
    props.setSelectedSBS(item)
    props.history.push('/dashboard/sbsdetail')
  };

  const goBack = () => {
    props.history.push('/dashboard')
  };
  const formatData = (result) => {
    const productDivisions = result.hits.hits[0];
    const customerFilterdArr = productDivisions.inner_hits['productDivisions.customers'].hits
    let new_array = []
    if (customerFilterdArr && customerFilterdArr.hits && customerFilterdArr.hits.length > 0) {
      new_array = customerFilterdArr.hits.map(function callback(element) {
        let custObj = {};
        //EMEA Release- - Added company_region
        custObj.productDivision = (element.fields['productDivisions.customers.productdivision.keyword']) ? element.fields['productDivisions.customers.productdivision.keyword'][0] : '-';
        custObj.company_region = (element.fields['productDivisions.customers.company_region.keyword']) ? element.fields['productDivisions.customers.company_region.keyword'][0] : '-';
        custObj['name'] = (element.fields['productDivisions.customers.name.keyword']) ? element.fields['productDivisions.customers.name.keyword'][0] : '-';
        custObj.cxScore = (element.fields['productDivisions.customers.cxScore']) ? element.fields['productDivisions.customers.cxScore'][0] : '-';
        custObj.lastQuarterCxScore = (element.fields['productDivisions.customers.lastQuarterCxScore']) ? element.fields['productDivisions.customers.lastQuarterCxScore'][0] : '-';
        custObj.code = (element.fields['productDivisions.customers.code.keyword']) ? element.fields['productDivisions.customers.code.keyword'][0] : '-';
        custObj.id = (element.fields['productDivisions.customers.id']) ? element.fields['productDivisions.customers.id'][0] : '-';
        custObj.name = (element.fields['productDivisions.customers.name.keyword']) ? element.fields['productDivisions.customers.name.keyword'][0] : '-';
        custObj.cas = (element.fields['productDivisions.customers.cas']) ? element.fields['productDivisions.customers.cas'][0] : '-';
        custObj.lastQuarterCas = (element.fields['productDivisions.customers.lastQuarterCas']) ? element.fields['productDivisions.customers.lastQuarterCas'][0] : '-';
        custObj.responseCount = (element.fields['productDivisions.customers.responseCount']) ? element.fields['productDivisions.customers.responseCount'][0] : '-';
        //custObj.volatility = (element.fields['productDivisions.customers.volatility.keyword']) ? element.fields['productDivisions.customers.volatility.keyword'][0] : '-';
        custObj.primaryRecommendation = (element.fields['productDivisions.customers.primaryRecommendation.keyword']) ? element.fields['productDivisions.customers.primaryRecommendation.keyword'][0] : '-';
        custObj.secondaryRecommendations = (element.fields['productDivisions.customers.secondaryRecommendations.keyword']) ? element.fields['productDivisions.customers.secondaryRecommendations.keyword'] : [];
        custObj.whiteGlove = (element.fields['productDivisions.customers.whiteGlove.keyword']) ? element.fields['productDivisions.customers.whiteGlove.keyword'][0] : '-';
        return custObj
      })
    }
    let allProductDivisions = [...productDivisions['_source'].productDivisions]
    //EMEA Release- - commented old code
    let selectedPDIndex = allProductDivisions.findIndex(item => (item.code.toLowerCase() === productDivision.code.toLowerCase() && item.company_region.toLowerCase() === region.toLowerCase()))
    allProductDivisions[selectedPDIndex].customers = new_array.filter((item) => (item.company_region.toLowerCase() === region.toLowerCase() && item.productDivision.toLowerCase() === productDivision.code.toLowerCase()))
    return allProductDivisions;
  }

  //scroll - Arrow changes
  const initialLoadArrow = () => {
    document.querySelector("#pdCustomerContainer #scrollToStart").style.display = "none";
    document.querySelector("#pdCustomerContainer #scrollToEnd").style.display = "none";
    console.log("Initial Load Arrow- BU");
   // if (document.querySelector("#BUContainer .rec.rec-arrow-left") && businessSegments && businessSegments.length >4) {
       document.querySelector("#BUContainer #BUScrollToStart").style.display = "none";
       document.querySelector("#BUContainer #BUScrollToEnd").style.display = "none";
    //}
  }

  const showHideLeftArrow = () => {
    document.querySelector("#pdCustomerContainer #scrollToEnd").style.display = "none";
    if(document.querySelector("#pdCustomerContainer .rec.rec-arrow-left")) {
      document.querySelector("#pdCustomerContainer #scrollToEnd").style.display = "none";
      if(document.querySelector("#pdCustomerContainer .rec.rec-arrow-left").disabled) {
        document.querySelector("#pdCustomerContainer #scrollToEnd").style.display = "block";
      }
      else{
        document.querySelector("#pdCustomerContainer #scrollToEnd").style.display = "none";
      }
    }
  }

  const onClickScrollToEnd = () => {
    document.querySelector("#pdCustomerContainer #scrollToEnd").style.display = "none";
    mycarousel.current.goTo(customers.length);
  }

  const showHideRightArrow = () => {
    document.querySelector("#pdCustomerContainer #scrollToStart").style.display = "none";
    if (document.querySelector("#pdCustomerContainer .rec.rec-arrow-right")) {
      document.querySelector("#pdCustomerContainer #scrollToStart").style.display = "none";
      if (document.querySelector("#pdCustomerContainer .rec.rec-arrow-right").disabled) {
        document.querySelector("#pdCustomerContainer #scrollToStart").style.display = "block";
      }
      else {
        document.querySelector("#pdCustomerContainer #scrollToStart").style.display = "none";
      }

    }
  }

  const showHideLeftBUArrow = () => {
    //document.querySelector("#BUContainer #BUScrollToEnd").style.display = "none";
    console.log("BU left arrow - 0", businessSegments);
    if (document.querySelector("#BUContainer .rec.rec-arrow-left") && businessSegments && businessSegments.length >4) {
      console.log("BU left arrow - 1");
       document.querySelector("#BUContainer #BUScrollToEnd").style.display = "none";
      if (document.querySelector("#BUContainer .rec.rec-arrow-left").disabled && businessSegments && businessSegments.length >4) {
        console.log("BU left arrow - 2");
        document.querySelector("#BUContainer #BUScrollToEnd").style.display = "block";

      }
      else {
        console.log("BU left arrow - 3");
        document.querySelector("#BUContainer #BUScrollToEnd").style.display = "none";
      }
    }
  }

  const showHideRightBUArrow = () => {
    console.log("BU Right arrow - 0");
   // document.querySelector("#BUContainer #BUScrollToStart").style.display = "none";
    if (document.querySelector("#BUContainer .rec.rec-arrow-right") && businessSegments && businessSegments.length >4) {
      console.log("BU Right arrow - 1");
      document.querySelector("#BUContainer #BUScrollToStart").style.display = "none";
      if (document.querySelector("#BUContainer .rec.rec-arrow-right").disabled  && businessSegments && businessSegments.length >4) {
        console.log("BU Right arrow - 2");
        document.querySelector("#BUContainer #BUScrollToStart").style.display = "block";
      }
      else {
        console.log("BU Right arrow - 3");
        document.querySelector("#BUContainer #BUScrollToStart").style.display = "none";
      }

    }
  }

  const onClickScrollToStart = () => {//right arrow at end
    document.querySelector("#pdCustomerContainer #scrollToStart").style.display = "none";
    mycarousel.current.goTo(0);
  }

  const onClickBUScrollToStart = () => {/*Right PD Arrow- which takes to beginning */
    document.querySelector("#BUContainer #BUScrollToStart").style.display = "none";
    myBUCarousel?.current?.goTo(0);
  }

  const onClickBUScrollToEnd = () => {/*Left BU Arrow- which takes to dead end */
    document.querySelector("#BUContainer #BUScrollToEnd").style.display = "none";
    myBUCarousel?.current?.goTo(businessSegments.length);
  }

  const myCarouselChange = (current, pagenum) => {
    showHideLeftArrow();
    showHideRightArrow();
  }

  const myBUCarouselChange = (current, pagenum) => {
    showHideLeftBUArrow();
    showHideRightBUArrow();
  }

  useEffect(() => {
    const input = {
      name: constants.ProductDivision_Dashboard_Index,
      queryParams: {////EMEA Release-
        "_source": {
          "excludes": "*.customers"
        },
        "query": {
          "nested": {
            "path": "productDivisions.customers",
            "query": {
              "bool": {
                "must": [
                  {
                    "match": {
                      "productDivisions.customers.whiteGlove": true
                    }
                  },
                  {
                    "match": {
                      "productDivisions.customers.productdivision": productDivision.code
                    }
                  },
                  {
                    "match": {
                      "productDivisions.customers.company_region": region
                    }
                  }
                ]
              }
            },
            "inner_hits": {
              "_source": false,
              "size": 50,
              "docvalue_fields": [
                "productDivisions.customers.company_region.keyword",
                "productDivisions.customers.code.keyword",
                "productDivisions.customers.name.keyword",
                "productDivisions.customers.whiteGlove.keyword",
                "productDivisions.customers.cxScore",
                "productDivisions.customers.lastQuarterCxScore",
                "productDivisions.customers.lastQuarterCas",
                "productDivisions.customers.responseCount",
                "productDivisions.customers.cas",
                "productDivisions.customers.productdivision.keyword",
                "productDivisions.customers.id",
                //"productDivisions.customers.volatility.keyword",
                "productDivisions.customers.primaryRecommendation.keyword",
                "productDivisions.customers.secondaryRecommendations.keyword"
              ]
            }
          }
        }
      }
    }
    setIsLoading(true)
    utils.getDataES('-elasticsearch', 'POST', input)
      .then(resp => {
        let ESData = formatData(resp.data);
        let result = processData(ESData)
        result.forEach((element) => {
          element.businessSegments.forEach((ele) => {
            //Fix for Adding text in Tile when primary recommendation is blank
            //ele.primaryRecommendation = ele.primaryRecommendation[0];
            ele.primaryRecommendation = ele.primaryRecommendation[0] ? ele.primaryRecommendation[0] === "-" ? null : ele.primaryRecommendation[0] : null;
          })
        })
        setfetchedData(JSON.parse(JSON.stringify(result)))
        setIsLoading(false)
        showHideLeftArrow();
        showHideRightArrow();
        showHideLeftBUArrow();
        showHideRightBUArrow();
      }).catch(err => setIsLoading(false))
    props.setCustomer({})
    props.setSelectedSBS({})

    initialLoadArrow();
  }, [])

  useEffect(() => {
    console.log("useEffect BU")
    myBUCarouselChange();
  }, [businessSegments.length]);

  const productBreakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 850, itemsToShow: 3, itemsToScroll: 2 },
    { width: 1020, itemsToShow: 3, itemsToScroll: 2 },
    { width: 1450, itemsToShow: 4, itemsToScroll: 3 },
    { width: 1750, itemsToShow: 5, itemsToScroll: 4 }
  ]
  const customerBreakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 850, itemsToShow: 3, itemsToScroll: 2 },
    { width: 1120, itemsToShow: 5, itemsToScroll: 4 },
    { width: 1450, itemsToShow: 6, itemsToScroll: 5 },
    { width: 1750, itemsToShow: 7, itemsToScroll: 6 }
  ]
  return <Spin spinning={isLoading}>
    <div className={styles.container}>
      <div id="BUContainer" className={styles.productsContainer}>
        {
          productDivision ?
            <div>
              <div className={styles.productHead}>
                <div className={styles.backBtn} onClick={goBack}>
                  Back
                </div>
                <Title value="Product Division" />
              </div>
              <Product
                product={productDivision}
                {...props}
                type="product"
                showDetail={showProductDivisionDetail}
              />
            </div> : null
        }
        {
          <div className={styles.scrollEndParent}>
            <div id="BUScrollToEnd" className={`${styles.leftBUArrow} ${styles.buttonStyle} `} onClick={() => onClickBUScrollToEnd()}>{"❮"}</div>
          </div>
        }
        {businessSegments && businessSegments.length ? (
          <div className={styles.productBox}>
            <Title value="Business Units​" count={businessSegments.length} />
            <div className="outerGrid">
              <Carousel ref={myBUCarousel}
                onChange={
                  (currentItem, pageIndex) => myBUCarouselChange(currentItem, pageIndex)
                }
                breakPoints={productBreakPoints}>
                {businessSegments.map((item, index) => {
                  return (
                    <Product
                      key={index}
                      product={item}
                      {...props}
                      type="businessunits"
                      showDetail={showSBSDetail}
                    />
                  );
                })}
              </Carousel>
            </div>
          </div>
        ) : null}
        {<div className={styles.scrollStartParent}>
          <div id="BUScrollToStart" className={`${styles.rightBUArrow} ${styles.buttonStyle} `} onClick={() => onClickBUScrollToStart()}>{"❯"}</div>
        </div>
        }
      </div>
      <div id="pdCustomerContainer" className={styles.productsContainer}>
        {<div className={styles.scrollEndParent}>
          <div id="scrollToEnd" className={`${styles.scrollToEnd} ${styles.buttonStyle} `} onClick={() => onClickScrollToEnd()}>{"❮"}</div>
        </div>
        }
        {customers && customers.length ? (
          <div className={styles.customerLayoutBx}>
            <Title value="Customers" count={customers.length} />
            <Carousel ref={mycarousel}
              onChange={
                (currentItem, pageIndex) => myCarouselChange(currentItem, pageIndex)
              }

              breakPoints={customerBreakPoints}>
              {customers.map((item, index) => {
                return (
                  <Product
                    key={index}
                    product={item}
                    {...props}
                    type="customer"
                    showDetail={showCustomerDetail}
                  />
                );
              })}
            </Carousel>
          </div>
        ) : null
        }
        {<div className={styles.scrollStartParent}>
          <div id="scrollToStart" className={`${styles.scrollToStart} ${styles.buttonStyle} `} onClick={() => onClickScrollToStart()}>{"❯"}</div>
        </div>
        }
      </div>
    </div>
  </Spin>
};

export default ProductDivision;
