import * as utils from "../../utils/utils";
import * as constants from "../../../src/utils/constant";

function debouncingMethod(timeout) {
    let timer, region;
     
    return function (text, productDivision) {
        return new Promise((resolve, reject) => {
            clearTimeout(timer)
            if (text.length) {
                timer = setTimeout(() => {      
                    region = JSON.parse(sessionStorage.getItem("selectedRegion"));
                    let params = {
                      "_source": {
                        "excludes": "*.customers"
                      },
                      "query": {
                        "nested": {
                          "path": "group.customers",
                          "query": {
                            "bool": {
                              "must": [
                                {
                                  "bool": {
                                    "should": [
                                      {
                                        "match_phrase_prefix": {
                                          "group.customers.name": {
                                            "query": text
                                          }
                                        }
                                      },
                                      {
                                        "match": {
                                          "group.customers.name": {
                                            "query": text,
                                            "fuzziness": "3"
                                          }
                                        }
                                      }
                                    ]
                                  }
                                }
                                ,
                                {
                                  "match": {
                                    "group.customers.company_region": region
                                  }
                                }
                              ]
                            }
                          },
                          "inner_hits": {
                            "_source": false,
                            "size": 50,
                            "docvalue_fields": [
                              "group.customers.company_region.keyword",
                              "group.customers.code.keyword",
                              "group.customers.name.keyword",
                              "group.customers.whiteGlove.keyword",
                              "group.customers.cxScore",
                              "group.customers.lastQuarterCxScore",
                              "group.customers.lastQuarterCas",
                              "group.customers.responseCount",
                              "group.customers.cas",
                              "group.customers.productdivision.keyword",
                              "group.customers.id",
                              //"group.customers.volatility.keyword",
                              "group.customers.primaryRecommendation.keyword",
                              "group.customers.secondaryRecommendations.keyword"
                            ]
                          }
                        }
                      }
                    }                          
                    if (productDivision !== '') {
                      // EMEA Release- - Added for EMEA region specific- Prod Div Search Level
                        params = {
                          "_source": {
                            "excludes": "*.customers"
                          },
                          "query": {
                            "nested": {
                              "path": "productDivisions.customers",
                              "query": {
                                "bool": {
                                  "must": [
                                    {
                                      "bool": {
                                        "should": [
                                          {
                                            "match_phrase_prefix": {
                                              "productDivisions.customers.name": {
                                                "query": text
                                              }
                                            }
                                          },
                                          {
                                            "match": {
                                              "productDivisions.customers.name": {
                                                "query": text,
                                                "fuzziness": "3"
                                              }
                                            }
                                          }
                                        ]
                                      }
                                    },
                                    {
                                      "match": {
                                        "productDivisions.customers.productdivision": productDivision
                                      }
                                    },
                                    {
                                      "match": {
                                        "productDivisions.customers.company_region": region
                                      }
                                    }
                                  ]
                                }
                              },
                              "inner_hits": {
                                "_source": false,
                                "size": 50,
                                "docvalue_fields": [
                                  "productDivisions.customers.company_region.keyword",
                                  "productDivisions.customers.code.keyword",
                                  "productDivisions.customers.name.keyword",
                                  "productDivisions.customers.whiteGlove.keyword",
                                  "productDivisions.customers.cxScore",
                                  "productDivisions.customers.lastQuarterCxScore",
                                  "productDivisions.customers.lastQuarterCas",
                                  "productDivisions.customers.responseCount",
                                  "productDivisions.customers.cas",
                                  "productDivisions.customers.productdivision.keyword",
                                  "productDivisions.customers.id",
                                  //"productDivisions.customers.volatility.keyword",
                                  "productDivisions.customers.primaryRecommendation.keyword",
                                  "productDivisions.customers.secondaryRecommendations.keyword"
                                ]
                              }
                           }
                          }
                        }
                   
                    }
                    const input = {
                        name: (productDivision) ? constants.ProductDivision_Dashboard_Index : constants.Group_Dashboard_Index,
                        queryParams: params
                    }
               
                    utils.getDataES('-elasticsearch', 'POST', input)
                        .then(resp => resolve(resp)).catch(err => console.log(err))
                }, timeout);
            }
        })
    }
}

const betterFunction = debouncingMethod(300)

export default betterFunction
