import React, { useEffect, useState } from 'react';
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import styles from "./detailedview.module.scss";
import CXScore from "./Tiles/CXScore";
import CAS from "./Tiles/CAS";
import CXList from './Tiles/CXList';
import { Spin } from 'antd';
import * as utils from '../../utils/utils';
import * as consts from '../../utils/constant';
import * as favUtils from '../../utils/favorite.utils';
import * as constants from "../../../src/utils/constant";
import * as nbaurls from "../../../src/utils/nba.utils";
import Attributes from '../attributes/subRegion';


const SubRegion = (props) => {
    const favInput = JSON.parse(JSON.stringify(props.favInput));
    const { selectedSubRegion } = props;
    let [FavoriteId, setFavoriteId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [subRegionDetails, setSubRegionDetails] = useState(null)
    const primaryRecommendation = (selectedSubRegion && selectedSubRegion.primaryRecommendation && selectedSubRegion.primaryRecommendation.length>0) ?  Array.isArray(selectedSubRegion.primaryRecommendation)? selectedSubRegion.primaryRecommendation[0].toLocaleLowerCase() : selectedSubRegion.primaryRecommendation.toLocaleLowerCase() : ""
    const secondaryRecommendations = (selectedSubRegion && selectedSubRegion.secondaryRecommendations) ? selectedSubRegion.secondaryRecommendations : [];
    const [region, setRegion] = useState(JSON.parse(sessionStorage.getItem("selectedRegion")));//EMEA Release-

    const breadcrumbList = [
        {
            value: `Dashboard (${region})`,
            path: "/dashboard",
        },
        {
            value: `${selectedSubRegion.code} (${region})`,
            path: "/",
        }
      ];

    const formatData = (result) => {
        let subRegionData;
        let hitsArray = (result.hits && result.hits.hits && result.hits.hits.length > 0) ? result.hits.hits[0] : [];
        subRegionData = (hitsArray && hitsArray?._source) ? hitsArray?._source?.group : [];
        let filteredSubRegionData = subRegionData.filter((item) => item.code.toLowerCase() === selectedSubRegion.code.toLowerCase() )[0];
        return filteredSubRegionData;
    }

    useEffect(() => {
        //props.setIsSubregion(false)
        const input = {
            name: constants.SubRegion_Score_Index,
            queryParams: {
                "query": {
                    "match_all": {}
                }
            }
        }    
        setLoading(true)
        utils.getDataES('-elasticsearch', 'POST', input)
            .then(resp => {
                let groupESData = formatData(resp.data);
                setSubRegionDetails(groupESData)
                setLoading(false)
            }).catch(err => setLoading(false))
    }, [])

    let cxScore, cas, cxList, cxListDP;
    const getSortedCXList = (cxList) => {
        let list = [...cxList]
        list.forEach(element => {
            element.difference = element.score - element.lastQuarterValue
            element.differenceCent = ((element.score - element.lastQuarterValue) / (element.lastQuarterValue)) * 100
        });
        return list.sort(utils.sorting('differenceCent', 'number', false))
    }

    if (subRegionDetails) {
        cxScore = subRegionDetails.cxScore;
        if (subRegionDetails && subRegionDetails.cxList && subRegionDetails.cxList.length > 0) {
            cxListDP = subRegionDetails.cxList.find(item => item.name.toLowerCase() === consts.HELD_ORDER_INDEX.toLowerCase());
            cxScore.dataPoints = (cxListDP && cxListDP.dataPoints) ? cxListDP.dataPoints : ''
        }
        cas = subRegionDetails.cas;
        cxList = getSortedCXList(subRegionDetails.cxList);
    }

    const createBreadCrumb = () => {
        breadcrumbList[breadcrumbList.length - 1].path = '/dashboard/subregiondetail'
        return breadcrumbList;
    }

    const viewScore = (score) => {
        props.setCXScoreData(subRegionDetails);
        props.setBreadCrumb(createBreadCrumb());
        if (score === 'cas') {
            props.setSASParent('cas');
            props.history.push("/dashboard/score/cas");//need to check
        } else {
            props.setSASParent('cx');
            props.history.push("/dashboard/score"); //need to check
        }
    };

    const getInsights = (nba) => {
        if (subRegionDetails.nba_insights) {
            let insights = subRegionDetails.nba_insights.filter(element => element.metrics_name.toLowerCase() === nba.toLowerCase())
            if (insights && insights.length) {
                return insights[0].insights
            }
            return []
        }
        return []
    }

    
    const primaryView = (nba) => {
        let _nba;
        const { code } = props.selectedSubRegion;
        let subreg_urls = nbaurls.sr_nba_urls(nba, code, region)
        switch (nba) {
            case consts.IMPROVE_INVOICE_AGING:
                _nba = {
                    name: nba,
                    primaryView: subreg_urls.primaryView,
                    childView: subreg_urls.childView,
                    historyView: subreg_urls.historyView,
                    insights: getInsights(consts.IMPROVE_INVOICE_AGING)
                }
                props.setNBA(_nba);
                props.setBannerType(consts.IMPROVE_INVOICE_AGING.replace(/\s+/g, ''));
                break;
            case consts.REDUCE_RETURN_PERCENTAGE:
                _nba = {
                    name: nba,
                    primaryView: subreg_urls.primaryView,
                    childView: subreg_urls.childView,
                    historyView: subreg_urls.historyView,
                    insights: getInsights(consts.REDUCE_RETURN_PERCENTAGE)
                }
                props.setNBA(_nba);
                props.setBannerType(consts.REDUCE_RETURN_PERCENTAGE.replace(/\s+/g, ''));
                break;
            case consts.IMPROVE_PRODUCT_AVAILABILITY:
                _nba = {
                    name: nba,
                    primaryView: subreg_urls.primaryView,
                    childView: subreg_urls.childView,
                    historyView: subreg_urls.historyView,
                    insights: getInsights(consts.IMPROVE_PRODUCT_AVAILABILITY)
                }
                props.setNBA(_nba);
                props.setBannerType(consts.IMPROVE_PRODUCT_AVAILABILITY.replace(/\s+/g, ''));
                break;
            case consts.NEW_IMPROVE_ORDER_VELOCITY:
                _nba = {
                    name: nba,
                    primaryView: subreg_urls.primaryView,
                    childView: subreg_urls.childView,
                    historyView: subreg_urls.historyView,
                    insights: getInsights(consts.NEW_IMPROVE_ORDER_VELOCITY)
                }
                props.setNBA(_nba);
                props.setBannerType(consts.NEW_IMPROVE_ORDER_VELOCITY.replace(/\s+/g, ''));
                break;
            case consts.IMPROVE_OTIF:
                _nba = {
                    name: nba,
                    primaryView: subreg_urls.primaryView,
                    childView: subreg_urls.childView,
                    historyView: subreg_urls.historyView,
                    insights: getInsights(consts.IMPROVE_OTIF)
                }
                props.setNBA(_nba);
                props.setBannerType(consts.IMPROVE_OTIF.replace(/\s+/g, ''));
                break;
            case consts.INCREASE_CREDITLINE:
                _nba = {
                    name: nba,
                    primaryView: subreg_urls.primaryView,
                    childView: subreg_urls.childView,
                    historyView: subreg_urls.historyView,
                    insights: getInsights(consts.INCREASE_CREDITLINE)
                }
                props.setNBA(_nba);
                props.setBannerType(consts.INCREASE_CREDITLINE.replace(/\s+/g, ''));
                break;
            default:
                break;
        }

        props.setNBABreadCrumb(createBreadCrumb())
        props.history.push('/dashboard/nba/primaryview')
    }

    async function getFavorites() {
        const favorites = await favUtils.getFavorites()
        props.setFavorite(favorites)
    }

    async function addToFavorite(isAlreadyFavorite) {
        try {
            setLoading(true)
            if (!isAlreadyFavorite) {
                favInput.UserId = utils.getUserEmail()
                favInput.ViewType = 1
                favInput.region = region
                favInput.InputParameters.subregion = selectedSubRegion;//added for EMEA release
                if(Object.keys(selectedSubRegion).length > 0)//added for EMEA release
                favInput.InputParameters.group.company_region = favInput.InputParameters.subregion.company_region;
                const FavoriteId = await favUtils.addFavorites(favInput)
                setFavoriteId(FavoriteId)
            } else {
                await favUtils.removeFavorites(FavoriteId)
                setFavoriteId(null)
            }
            setLoading(false)
            getFavorites()
        } catch (err) {
            console.log(err);
            setLoading(false)
        }
    }

    useEffect(() => {
        setFavoriteId(favUtils.chkIsFavorite(
            props.favorites,
            region, //added for EMEA release
            1,
            {},
            {},
            {},
            '',
            '',
            selectedSubRegion))//Need to modify it
    }, [])

    
    const nbas = [
        {
            nba: consts.REDUCE_RETURN_PERCENTAGE,
            primaryRecommendation: primaryRecommendation === consts.REDUCE_RETURN_PERCENTAGE.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.REDUCE_RETURN_PERCENTAGE)
        },
        {
            nba: consts.IMPROVE_PRODUCT_AVAILABILITY,
            primaryRecommendation: primaryRecommendation === consts.IMPROVE_PRODUCT_AVAILABILITY.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.IMPROVE_PRODUCT_AVAILABILITY)
        },
        {
            nba: consts.NEW_IMPROVE_ORDER_VELOCITY,
            primaryRecommendation: primaryRecommendation === consts.NEW_IMPROVE_ORDER_VELOCITY.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.NEW_IMPROVE_ORDER_VELOCITY)
        },
        {
            nba: consts.IMPROVE_OTIF,
            primaryRecommendation: primaryRecommendation === consts.IMPROVE_OTIF.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.IMPROVE_OTIF)
        },
        {
            nba: consts.IMPROVE_INVOICE_AGING,
            primaryRecommendation: primaryRecommendation === consts.IMPROVE_INVOICE_AGING.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.IMPROVE_INVOICE_AGING)
        },
        {
            nba: consts.INCREASE_CREDITLINE,
            primaryRecommendation: primaryRecommendation === consts.INCREASE_CREDITLINE.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.INCREASE_CREDITLINE)
        }
    ]
    
    return (
        <Spin spinning={loading}>
            <div className={styles.cxContainer}>
                <BreadCrumb
                    list={breadcrumbList}
                    {...props}
                    addToFavorite={addToFavorite}
                    isFavorite={FavoriteId ? true : false} />
                <div className="row no-gutters">
                    <div className={`${styles.leftContent} ${"col-sm-12 col-md-12 col-lg-8"}`}>
                        {
                            subRegionDetails ?
                                <React.Fragment>
                                    <div className={styles.scoreGrid}>
                                        {cxScore && <CXScore cxScore={cxScore} {...props} viewScore={viewScore} />}
                                        {cas && <CAS cas={cas} {...props} viewScore={viewScore} />}
                                        {cxList && <CXList cxList={cxList} {...props} />}
                                    </div>
                                    <div className={styles.layoutBx}>
                                        <h2 className={styles.cxHeading}>Next Best Actions</h2>
                                        <div className={styles.nbaContainer}>
                                            {
                                                nbas.map((element, index) => (
                                                    <div
                                                        key={index}
                                                        onClick={() => primaryView(element.nba)}
                                                        className={element.primaryRecommendation ? `${styles.griditem} ${styles.recommended}` :
                                                            element.isOutlineRequired ? `${styles.griditem} ${styles.otherRcmds}` : styles.griditem}
                                                    >{element.nba}</div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                   {/* <div className={styles.layoutBx}>
                                        <h2 className={styles.cxHeading}>Journey Map Score</h2>
                                        <div className={styles.underDesign}>Under Design</div>
                                    </div>*/}
                                </React.Fragment> : null
                        }
                    </div>
                    <div className={`${styles.rightContent} ${"col-sm-12 col-md-12 col-lg-4"}`}>
                        {<Attributes code={selectedSubRegion.code} />}
                    </div>
                </div>
            </div>
        </Spin>
    );
};

export default SubRegion;