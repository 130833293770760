import * as consts from "../../utils/constant";
import * as utils from "../../utils/utils";

export const CreateScoreLinks = (props,isFromFav,updatedCXList,favProps) => {

    const { cxList } = (!isFromFav)?props.cxScoreDetails:updatedCXList

    const getProductDivisionReport = (ReportURL, code, PBI_Table,region) => {
        if(region?.toLowerCase()==='na')
        return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${code}' and Company_region_sm/company_region eq '${region}'`
        else
        return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${code}' and Company_region_sm/company_region eq '${region}' and Rank/company_sub_region eq '-'`
    }

    const getProductDivisionReportDetailPOAL = (ReportURL, code, PBI_Table,region) => {
        return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${code}' and Sales/company_region eq '${region}'`
    }

    const getProductDivisionReportHistoryPOAL = (ReportURL, code, PBI_Table,region) => {
        return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${code}' and Sales/company_region eq '${region}'`
    }

    const getProductDivisionReportDetailAR = (ReportURL, code, PBI_Table,region) => {
        return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${code}' and Invoice/company_region eq '${region}'`
    }

    const getProductDivisionReportTACHistory = (ReportURL, code, PBI_Table,region) =>{
        return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${code}' and Complaints/company_region eq '${region}'`
       }
    
   const getProductDivisionReportHODetail = (ReportURL, code, PBI_Table,region) =>{
    return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${code}' and Company_Region_Hold/company_region eq '${region}'`
   }

   const getProductDivisionReportHistoryAR = (ReportURL, code, PBI_Table,region) => {
    return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${code}' and Invoice/company_region eq '${region}'`
   }

   const getProductDivisionReportHOHistory = (ReportURL, code, PBI_Table,region) => {
    return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${code}' and Hold/company_region eq '${region}'`
   }

   const getProductDivisionReportTACDetail = (ReportURL, code, PBI_Table,region) =>{
    return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${code}' and Complaints/company_region eq '${region}'`
   }





   
   const getHeldOrderIndexSubRegionPrimary = (url, divisionCode = '-', buCode = '-', customer = '-', region, subregion) => {
    return `${url}&filter=Salesmetrics/product_division_code eq '-' and Salesmetrics/business_segment_code eq '-' and Salesmetrics/account_name eq '${customer}' and Company_region_sm/company_region eq '${region}' and Company_sub_region/company_sub_region eq '${subregion}'` //Added for EMEA release  
   }

   const getTotalAdminComplaintsSubRegionPrimary = (ReportURL, pd, PBI_Table, region, subregion) =>{
   return `${ReportURL} and ${PBI_Table}/product_division_code eq '-' and Company_region_sm/company_region eq '${region}' and Company_sub_region/company_sub_region eq '${subregion}'`
   }

   
   const getHeldOrderIndexSubRegionDetail = (ReportURL, pd , PBI_Table,region, subregion) => {
    return `${ReportURL}&filter=Sales/company_sub_region eq '${subregion}'` //${PBI_Table}/product_division_code eq '-' and 
   }


  const getTotalAdminComplaintsSubRegionDetail = (ReportURL, code, PBI_Table,region, subregion) =>{
   return `${ReportURL}&filter=Complaints/company_sub_region eq '${subregion}'`//${PBI_Table}/product_division_code eq '-' and 
  }


   const getSubRegionReportDetailPOAL = (ReportURL, code, PBI_Table,region, subregion) => {
    return `${ReportURL}&filter=Sales/company_sub_region eq '${subregion}'`;//and Sales/company_region eq '${region}' //${PBI_Table}/product_division_code eq '${code}' and 
   }

    const getSubRegionReportDetailAR = (ReportURL, code, PBI_Table,region, subregion) => {
        return `${ReportURL}&filter=Invoice/company_region eq '${region}' and Invoice/company_sub_region eq '${subregion}'` //${PBI_Table}/product_division_code eq '${code}' and 
    }

    const getARHistorySubRegion = (ReportURL, code, PBI_Table,region, subregion) => {
        return `${ReportURL}&filter=Invoice/company_region eq '${region}' and Invoice/company_sub_region eq '${subregion}'` //${PBI_Table}/product_division_code eq '${code}' and 
    }

    const getPOALHistorySubRegion = (ReportURL, code, PBI_Table,region, subregion) => {
        return `${ReportURL}&filter=Sales/company_sub_region eq '${subregion}'`;//and Sales/company_region eq '${region}' and// ${PBI_Table}/product_division_code eq '${code}' and 
    }

   const getHeldOrderIndexSubRegionHistory = (ReportURL, code, PBI_Table,region, subregion) =>{
    return `${ReportURL}&filter=Hold/company_sub_region eq '${subregion}'`//${PBI_Table}/product_division_code eq '-' and 
   }

  
   const getTotalAdminComplaintsSubRegionHistory = (ReportURL, code, PBI_Table,region, subregion) =>{
    return `${ReportURL}&filter=Complaints/company_region eq '${region}' and Complaints/company_sub_region eq '${subregion}'` //${PBI_Table}/product_division_code eq '${code}' and 
   }




    const getSBSReport = (ReportURL, divisionCode, code, PBI_Table) => {
        return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${divisionCode}' and ${PBI_Table}/business_segment_code eq '${code}'`
    }

    const getSBSReport_HO_detail = (ReportURL, divisionCode, code, PBI_Table, region) => {
        return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${divisionCode}' and ${PBI_Table}/business_segment_code eq '${code}' and Company_Region_Hold/company_region eq '${region}'`
    }

    const getSBSReport_HO_history = (ReportURL, divisionCode, code, PBI_Table,region) => {
        return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${divisionCode}' and ${PBI_Table}/business_segment_code eq '${code}' and Hold/company_region eq '${region}'`
    }

    const getSBSReport_TA_detail = (ReportURL, divisionCode, code, PBI_Table, region) => {
        return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${divisionCode}' and ${PBI_Table}/business_segment_code eq '${code}' and Complaints/company_region eq '${region}'`
    }

    const getSBSReport_TA_history = (ReportURL, divisionCode, code, PBI_Table, region) => {
        return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${divisionCode}' and ${PBI_Table}/business_segment_code eq '${code}' and Complaints/company_region eq '${region}'`
    }

    const getSBSReport_POAL_detail = (ReportURL, divisionCode, code, PBI_Table, region) => {
        return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${divisionCode}' and ${PBI_Table}/business_segment_code eq '${code}' and Sales/company_region eq '${region}'`
    }

    const getSBSReport_POAL_history = (ReportURL, divisionCode, code, PBI_Table, region) => {
        return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${divisionCode}' and ${PBI_Table}/business_segment_code eq '${code}' and Sales/company_region eq '${region}'`
    }

    const getSBSReport_AR_detail = (ReportURL, divisionCode, code, PBI_Table, region) => {
        return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${divisionCode}' and ${PBI_Table}/business_segment_code eq '${code}' and Invoice/company_region eq '${region}'`
    }

    const getSBSReport_AR_history = (ReportURL, divisionCode, code, PBI_Table, region) => {
        return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${divisionCode}' and ${PBI_Table}/business_segment_code eq '${code}' and Invoice/company_region eq '${region}'`
    }

    const getCustomerReport = (ReportURL, code, PBI_Table) => {
        return `${ReportURL}&filter=${PBI_Table}/account_name eq '${code}'` // Added for EMEA release
    }

    const getCustomerReport_HO_detail = (ReportURL, code, PBI_Table, region) => {
        return `${ReportURL}&filter=${PBI_Table}/account_name eq '${code}' and Company_Region_Hold/company_region eq '${region}'` // Added for EMEA release
    }

    const getCustomerReport_TA_detail = (ReportURL, code, PBI_Table, region) => {
        return `${ReportURL}&filter=${PBI_Table}/account_name eq '${code}' and Complaints/company_region eq '${region}'` // Added for EMEA release
    }

    const getCustomerReport_HO_history = (ReportURL, code, PBI_Table, region) => {
        return `${ReportURL}&filter=${PBI_Table}/account_name eq '${code}' and Hold/company_region eq '${region}'` // Added for EMEA release
    }

    const getCustomerReport_TA_history = (ReportURL, code, PBI_Table, region) => {
        return `${ReportURL}&filter=${PBI_Table}/account_name eq '${code}' and Complaints/company_region eq '${region}'` // Added for EMEA release
    }

    const getCustomerReport_POAL_detail = (ReportURL, code, PBI_Table, region) => {
        return `${ReportURL}&filter=${PBI_Table}/account_name eq '${code}' and Sales/company_region eq '${region}'` // Added for EMEA release
    }

    const getCustomerReport_POAL_history = (ReportURL, code, PBI_Table, region) => {
        return `${ReportURL}&filter=${PBI_Table}/account_name eq '${code}' and Sales/company_region eq '${region}'` // Added for EMEA release
    }

    const getCustomerReport_AR_detail = (ReportURL, code, PBI_Table, region) => {
        return `${ReportURL}&filter=${PBI_Table}/account_name eq '${code}' and Invoice/company_region eq '${region}'` // Added for EMEA release
    }

    const getCustomerReport_AR_history = (ReportURL, code, PBI_Table, region) => {
        return `${ReportURL}&filter=${PBI_Table}/account_name eq '${code}' and Invoice/company_region eq '${region}'` // Added for EMEA release
    }

    const getCustomer_PDReport = (ReportURL, divisionCode, code, PBI_Table, region) => {
        return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${divisionCode}' and ${PBI_Table}/account_name eq '${code}'`;
    }
    
    const getCustomer_PDReport_HO_detail = (ReportURL, divisionCode, code, PBI_Table, region) => {
        return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${divisionCode}' and ${PBI_Table}/account_name eq '${code}' and Company_Region_Hold/company_region eq '${region}'`;
    }

    const getCustomer_PDReport_TA_detail = (ReportURL, divisionCode, code, PBI_Table, region) => {
        return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${divisionCode}' and ${PBI_Table}/account_name eq '${code}' and Complaints/company_region eq '${region}'`;
    }

    const getCustomer_PDReport_HO_history = (ReportURL, divisionCode, code, PBI_Table, region) => {
        return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${divisionCode}' and ${PBI_Table}/account_name eq '${code}' and Hold/company_region eq '${region}'`;
    }

    const getCustomer_PDReport_TA_history = (ReportURL, divisionCode, code, PBI_Table, region) => {
        return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${divisionCode}' and ${PBI_Table}/account_name eq '${code}' and Complaints/company_region eq '${region}'`;
    }

    const getCustomer_PDReport_POAL_detail = (ReportURL, divisionCode, code, PBI_Table, region) => {
        return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${divisionCode}' and ${PBI_Table}/account_name eq '${code}' and Sales/company_region eq '${region}'`;
    }

    const getCustomer_PDReport_POAL_history = (ReportURL, divisionCode, code, PBI_Table, region) => {
        return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${divisionCode}' and ${PBI_Table}/account_name eq '${code}' and Sales/company_region eq '${region}'`;
    }

    const getCustomer_PDReport_AR_detail = (ReportURL, divisionCode, code, PBI_Table, region) => {
        return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${divisionCode}' and ${PBI_Table}/account_name eq '${code}' and Invoice/company_region eq '${region}'`;
    }

    const getCustomer_PDReport_AR_history = (ReportURL, divisionCode, code, PBI_Table, region) => {
        return `${ReportURL}&filter=${PBI_Table}/product_division_code eq '${divisionCode}' and ${PBI_Table}/account_name eq '${code}' and Invoice/company_region eq '${region}'`;
    }


      // for Group level- TAC
      const getTAC_groupPrimaryReport = (url, divisionCode = '-', buCode = '-', customer = '-', region) => {
        if(region?.toLowerCase()==="na")
        return `${url}&filter=Salesmetrics/product_division_code eq '${divisionCode}' and Salesmetrics/business_segment_code eq '${buCode}' and Salesmetrics/account_name eq '${customer}' and Company_region_sm/company_region eq 'NA'` 
        else
        return `${url}&filter=Salesmetrics/product_division_code eq '${divisionCode}' and Salesmetrics/business_segment_code eq '${buCode}' and Salesmetrics/account_name eq '${customer}' and Salesmetrics/company_region eq 'EMEA' and Salesmetrics/company_sub_region eq '-' and Complaints/company_region eq 'EMEA'`// and Complaints/product_division_code eq '' and Complaints/business_segment_code eq '' and Complaints/account_name eq '' and Complaints/company_sub_region eq '-'` // and Rank/company_sub_region eq '-' 
    }

    // for Held Order Index Primary Link
    const getHeldOrderIndexPrimaryReportLink = (url, divisionCode = '-', buCode = '-', customer = '-', region) => {
        if(region?.toLowerCase()==="na")
        return `${url}&filter=Salesmetrics/product_division_code eq '${divisionCode}' and Salesmetrics/business_segment_code eq '${buCode}' and Salesmetrics/account_name eq '${customer}' and Company_region_sm/company_region eq 'NA'` 
        else
        return `${url}&filter=Salesmetrics/product_division_code eq '${divisionCode}' and Salesmetrics/business_segment_code eq '${buCode}' and Salesmetrics/account_name eq '${customer}' and Company_region_sm/company_region eq 'EMEA' and Salesmetrics/company_sub_region eq '-'` // and Rank/company_sub_region eq '-' 
    }

    const getHeldOrderIndexPrimaryReportRegionLink = (url, divisionCode = '-', buCode = '-', customer = '-', region) => {
        if(divisionCode!=='-' && buCode==='-' && customer==='-'){//product division level 
            if(region?.toLowerCase()==='na')
            return `${url}&filter=Salesmetrics/product_division_code eq '${divisionCode}' and Salesmetrics/business_segment_code eq '${buCode}' and Salesmetrics/account_name eq '${customer}' and Company_region_sm/company_region eq '${region}'` //Added for EMEA release
            else
            return `${url}&filter=Salesmetrics/product_division_code eq '${divisionCode}' and Salesmetrics/business_segment_code eq '${buCode}' and Salesmetrics/account_name eq '${customer}' and Company_region_sm/company_region eq '${region}' and Salesmetrics/company_sub_region eq '-'` //Added for EMEA release
        }
        else{
            return `${url}&filter=Salesmetrics/product_division_code eq '${divisionCode}' and Salesmetrics/business_segment_code eq '${buCode}' and Salesmetrics/account_name eq '${customer}'` //Added for EMEA release
        }
        
       // return `${url}&filter=Salesmetrics/product_division_code eq '${divisionCode}' and Salesmetrics/business_segment_code eq '${buCode}' and Salesmetrics/ship_to_national_sales_group_name eq '${customer}'`*/ //Added for EMEA release
    }



    // for availability index
    const getProductDivisionReport_TAC_Primary = (ReportURL, code, PBI_Table, region, url) => {
        if(region?.toLowerCase()==='na')
        return `${ReportURL} and ${PBI_Table}/product_division_code eq '${code}'`// and Company_region_sm/company_region eq '${region}'`
        else
        //return `${ReportURL} and ${PBI_Table}/product_division_code eq '${code}'`// and Company_region_sm/company_region eq '${region}' and Rank/company_sub_region eq '-'`
        return `${url}&filter=Salesmetrics/product_division_code eq '${code}' and Salesmetrics/business_segment_code eq '-' and Salesmetrics/account_name eq '-' and Salesmetrics/company_region eq 'EMEA' and Salesmetrics/company_sub_region eq '-' and Complaints/product_division_code eq '${code}' and Complaints/company_region eq 'EMEA'`//  and Complaints/business_segment_code eq '-' and Complaints/account_name eq '-' and Rank/company_sub_region eq '-'  and Complaints/company_sub_region eq '-' 
    }

    const getSBSReport_TA_primary = (ReportURL, divisionCode, code, PBI_Table, region, url) => {
        if(region?.toLowerCase()==='na')
        return `${ReportURL} and ${PBI_Table}/product_division_code eq '${divisionCode}' and ${PBI_Table}/business_segment_code eq '${code}'`// and Company_region_sm/company_region eq 'NA'`
        else
        //return `${ReportURL} and ${PBI_Table}/product_division_code eq '${divisionCode}' and ${PBI_Table}/business_segment_code eq '${code}'`// and Company_region_sm/company_region eq 'EMEA' and Rank/company_sub_region eq '-'`
        return `${url}&filter=Salesmetrics/product_division_code eq '${divisionCode}' and Salesmetrics/business_segment_code eq '${code}' and Salesmetrics/account_name eq '-' and Salesmetrics/company_region eq 'EMEA' and Salesmetrics/company_sub_region eq '-' and Complaints/product_division_code eq '${divisionCode}' and Complaints/business_segment_code eq '${code}' and Complaints/company_region eq 'EMEA'` // and Rank/company_sub_region eq '-' 
    }

    const getCustomerReport_TA_primary = (ReportURL, code, PBI_Table, region, url) => {
        if(region?.toLowerCase()==='na')
        return `${ReportURL} and ${PBI_Table}/account_name eq '${code}' and Company_region_sm/company_region eq 'NA'` //Added for EMEA release 
        else
        //return `${ReportURL} and ${PBI_Table}/account_name eq '${code}' and Company_region_sm/company_region eq 'EMEA'` // and Rank/company_sub_region eq '-'
        return `${url}&filter=Salesmetrics/product_division_code eq '-' and Salesmetrics/business_segment_code eq '-' and Salesmetrics/account_name eq '${code}' and Salesmetrics/company_region eq 'EMEA' and Salesmetrics/company_sub_region eq '-' and Complaints/account_name eq '${code}' and Complaints/company_region eq 'EMEA'` // and Rank/company_sub_region eq '-' 
    }

    const getCustomer_PDReport_TA_primary = (ReportURL, divisionCode, code, PBI_Table, region, url) => {
        if(region?.toLowerCase()==="na")
        return `${ReportURL} and ${PBI_Table}/product_division_code eq '${divisionCode}' and ${PBI_Table}/account_name eq '${code}' and Company_region_sm/company_region eq 'NA'`; //Added for EMEA release 
        else
        //return `${ReportURL} and ${PBI_Table}/product_division_code eq '${divisionCode}' and ${PBI_Table}/account_name eq '${code}' and Company_region_sm/company_region eq 'EMEA'`; // and Rank/company_sub_region eq '-'
        return `${url}&filter=Salesmetrics/product_division_code eq '${divisionCode}' and Salesmetrics/business_segment_code eq '-' and Salesmetrics/account_name eq '${code}' and Salesmetrics/company_region eq 'EMEA' and Salesmetrics/company_sub_region eq '-' and Complaints/product_division_code eq '${divisionCode}' and Complaints/account_name eq '${code}' and Complaints/company_region eq 'EMEA'` // and Rank/company_sub_region eq '-' 
    }

    const getReport_salesmetrics_avail_primary = (ReportURL, division = '-', customer = '-', segment = '-', region, subregion) => {   
       if(subregion!=='-' && subregion!== undefined){
        return `${ReportURL}&filter=Salesmetrics/product_division_code eq '${division}' and Salesmetrics/business_segment_code eq '${segment}' and Salesmetrics/account_name eq '${customer}' and Company_region_sm/company_region eq '${region}' and Company_sub_region/company_sub_region eq '${subregion}'`; 
       }
       else{
        if(region?.toLowerCase()==="emea")
        return `${ReportURL}&filter=Salesmetrics/product_division_code eq '${division}' and Salesmetrics/business_segment_code eq '${segment}' and Salesmetrics/account_name eq '${customer}' and Company_region_sm/company_region eq '${region}' and Salesmetrics/company_sub_region eq '-'`;// 
        else
        return `${ReportURL}&filter=Salesmetrics/product_division_code eq '${division}' and Salesmetrics/business_segment_code eq '${segment}' and Salesmetrics/account_name eq '${customer}' and Company_region_sm/company_region eq '${region}'`; 
       }
    }

    const getReport_salesmetrics_primary = (ReportURL, division = '-', customer = '-', segment = '-', region, subregion) => {
        if(subregion!=='-' && subregion!== undefined){ //Subregion 
            return `${ReportURL}&filter=Salesmetrics/product_division_code eq '${division}' and Salesmetrics/business_segment_code eq '${segment}' and Salesmetrics/account_name eq '${customer}' and Company_region_sm/company_region eq '${region}' and Company_sub_region/company_sub_region eq '${subregion}'`; 
        }
        else {
            if(region?.toLowerCase()==='emea')
            return `${ReportURL}&filter=Salesmetrics/product_division_code eq '${division}' and Salesmetrics/business_segment_code eq '${segment}' and Salesmetrics/account_name eq '${customer}' and Company_region_sm/company_region eq '${region}' and Salesmetrics/company_sub_region eq '-'`; //Added for EMEA release // and Rank/company_sub_region eq '-'
            else
            return `${ReportURL}&filter=Salesmetrics/product_division_code eq '${division}' and Salesmetrics/business_segment_code eq '${segment}' and Salesmetrics/account_name eq '${customer}' and Company_region_sm/company_region eq '${region}'`; //Added for EMEA release
        }

    }

    const getDetailedReport_Invoice = (ReportURL, division=undefined, customer=undefined, segment=undefined, region,subregion) => {
       let filterParams='';
        if(!customer&& division && !segment)
        {//Product Division
            filterParams = `&filter=Invoice/product_division_code eq '${division}' and Invoice/company_region eq '${region}'`;
        }
        if(customer&& !division && !segment)
        {//Group Customer
            filterParams = `&filter=Invoice/account_name eq '${customer}' and Invoice/company_region eq '${region}'`; //Added for EMEA release
        }
        if(customer&& division && !segment)
        {//PD customer
            filterParams = `&filter=Invoice/product_division_code eq '${division}' and Invoice/account_name eq '${customer}' and Invoice/company_region eq '${region}'` ; // Added for EMEA release
        }
        if(!customer&& division && segment)
        {//Business Unit
            filterParams = `&filter=Invoice/product_division_code eq '${division}' and Invoice/business_segment_code eq '${segment}' and Invoice/company_region eq '${region}'`
        }
        if(subregion && !customer && !division && !segment){//Subregion
            filterParams = `&filter=Invoice/company_region eq '${region}' and Invoice/company_sub_region eq '${subregion}'`;//Invoice/product_division_code eq '-' and 
        }
        if(!subregion && !customer && !division && !segment){//group
            filterParams = `&filter=Invoice/company_region eq '${region}'`;
        }

        return `${ReportURL}${filterParams}`;
    }


    const getReportLink = (primary, { name }) => {
        switch (primary) {
            case 'primary':
                switch (name.toLowerCase()) {
                    case consts.TOTAL_ADMIN_COMPLAINTS.toLowerCase():
                        return process.env.REACT_APP_TOTAL_ADMIN_COMPLAINTS_PRIMARY_LINK
                    case consts.HELD_ORDER_INDEX.toLowerCase():
                        return process.env.REACT_APP_HELD_ORDER_INDEX_PRIMARY_LINK
                    case consts.AVAILABILITY_INDEX.toLowerCase():
                        return process.env.REACT_APP_AVAILABILITY_INDEX_PRIMARY_LINK
                    case consts.PERFECT_ORDER_INDEX.toLowerCase():
                        return process.env.REACT_APP_PERFECT_ORDER_INDEX_PRIMARY_LINK
                    case consts.INVOICING_INDEX.toLowerCase():
                        return process.env.REACT_APP_INVOICING_INDEX_PRIMARY_LINK
                    case consts.AR_DISPUTE_INDEX.toLowerCase():
                        return process.env.REACT_APP_AR_DISPUTE_INDEX_PRIMARY_LINK
                    default:
                        return ''
                }
            case 'child':
                switch (name.toLowerCase()) {
                    case consts.TOTAL_ADMIN_COMPLAINTS.toLowerCase():
                        return process.env.REACT_APP_TOTAL_ADMIN_COMPLAINTS_CHILD_LINK
                    case consts.HELD_ORDER_INDEX.toLowerCase():
                        return process.env.REACT_APP_HELD_ORDER_INDEX_CHILD_LINK
                    case consts.AVAILABILITY_INDEX.toLowerCase():
                        return process.env.REACT_APP_AVAILABILITY_INDEX_CHILD_LINK
                    case consts.PERFECT_ORDER_INDEX.toLowerCase():
                        return process.env.REACT_APP_PERFECT_ORDER_INDEX_CHILD_LINK
                    case consts.INVOICING_INDEX.toLowerCase():
                        return process.env.REACT_APP_INVOICING_INDEX_CHILD_LINK
                    case consts.AR_DISPUTE_INDEX.toLowerCase():
                        return process.env.REACT_APP_AR_DISPUTE_INDEX_CHILD_LINK
                    default:
                        return ''
                }
            case 'history':
                switch (name.toLowerCase()) {
                    case consts.TOTAL_ADMIN_COMPLAINTS.toLowerCase():
                        return process.env.REACT_APP_TOTAL_ADMIN_COMPLAINTS_HISTORY_LINK
                    case consts.HELD_ORDER_INDEX.toLowerCase():
                        return process.env.REACT_APP_HELD_ORDER_INDEX_HISTORY_LINK
                    case consts.AVAILABILITY_INDEX.toLowerCase():
                        return process.env.REACT_APP_AVAILABILITY_INDEX_HISTORY_LINK
                    case consts.PERFECT_ORDER_INDEX.toLowerCase():
                        return process.env.REACT_APP_PERFECT_ORDER_INDEX_HISTORY_LINK
                    case consts.INVOICING_INDEX.toLowerCase():
                        return process.env.REACT_APP_INVOICING_INDEX_HISTORY_LINK
                    case consts.AR_DISPUTE_INDEX.toLowerCase():
                        return process.env.REACT_APP_AR_DISPUTE_HISTORY_LINK
                    default:
                        return ''
                }
        }
    }

    cxList.forEach(element => {
        //Held ORDER and Total Admin Complaints
        if (element.name.toLowerCase() === consts.HELD_ORDER_INDEX.toLowerCase() || element.name.toLowerCase() === consts.TOTAL_ADMIN_COMPLAINTS.toLowerCase()) {
            if (!utils.isEmpty(props && props.selectedCustomer) || !utils.isEmpty(favProps && favProps.customer)) {
                if (!utils.isEmpty(props && props.selectedProductDivision) || !utils.isEmpty(favProps && favProps.productDivision)) {
                   //ProductDivision Customer Level
                    const { code: divisionCode } = (props && props.selectedProductDivision)?props.selectedProductDivision:favProps.productDivision
                    const { code:CustomerName } = (props && props.selectedCustomer)?props.selectedCustomer:favProps.customer
                    let region = JSON.parse(sessionStorage.getItem("selectedRegion"));
                    const code = (CustomerName)?encodeURIComponent(CustomerName):CustomerName;
                    if (element.name.toLowerCase() === consts.HELD_ORDER_INDEX.toLowerCase()) {
                        element.primary = getHeldOrderIndexPrimaryReportLink(getReportLink('primary', element), divisionCode, '-', code, region);
                        element.child = getCustomer_PDReport_HO_detail(getReportLink('child', element), divisionCode, code, 'Sales',region)
                        element.history = getCustomer_PDReport_HO_history(getReportLink('history', element), divisionCode, code, 'Sales', region)
                    } 
                    if (element.name.toLowerCase() === consts.TOTAL_ADMIN_COMPLAINTS.toLowerCase()) {
                        element.primary = getCustomer_PDReport_TA_primary(getHeldOrderIndexPrimaryReportLink(getReportLink('primary', element), divisionCode, '-', code, region), divisionCode, code, 'Complaints',region,getReportLink('primary', element))
                        element.child = getCustomer_PDReport_TA_detail(getReportLink('child', element), divisionCode, code, 'Sales',region)
                        element.history = getCustomer_PDReport_TA_history(getReportLink('history', element), divisionCode, code, 'Sales', region)
                    } 
                 
                } else {
                    //Group Customer
                    const { code:CustomerName } = (props && props.selectedCustomer)?props.selectedCustomer:favProps.customer
                    const code = (CustomerName)?encodeURIComponent(CustomerName):CustomerName;
                    let region = JSON.parse(sessionStorage.getItem("selectedRegion"));
                    if (element.name.toLowerCase() === consts.HELD_ORDER_INDEX.toLowerCase()) {
                        element.primary = getHeldOrderIndexPrimaryReportLink(getReportLink('primary', element), '-', '-', code, region);
                        element.child = getCustomerReport_HO_detail(getReportLink('child', element), code, 'Sales', region)
                        element.history = getCustomerReport_HO_history(getReportLink('history', element), code, 'Sales', region)
                    } 
                    if (element.name.toLowerCase() === consts.TOTAL_ADMIN_COMPLAINTS.toLowerCase()) {
                        element.primary = getCustomerReport_TA_primary(getHeldOrderIndexPrimaryReportLink(getReportLink('primary', element), '-', '-', code, region), code, 'Complaints',region, getReportLink('primary', element))
                        element.child = getCustomerReport_TA_detail(getReportLink('child', element), code, 'Sales', region)
                        element.history = getCustomerReport_TA_history(getReportLink('history', element), code, 'Sales', region)
                    } 
                   
                }
            } else if (!utils.isEmpty(props && props.selectedSBS) || !utils.isEmpty(favProps && favProps.sbs)) {
                //Business Unit Level
                const { code } = (props && props.selectedSBS)?props.selectedSBS:favProps.sbs;
                const { code: divisionCode } = (props && props.selectedProductDivision)?props.selectedProductDivision:favProps.productDivision
                let region = JSON.parse(sessionStorage.getItem("selectedRegion"));
                if (element.name.toLowerCase() === consts.HELD_ORDER_INDEX.toLowerCase()) {
                    element.primary = getHeldOrderIndexPrimaryReportLink(getReportLink('primary', element), divisionCode, code, '-',region);
                    element.child = getSBSReport_HO_detail(getReportLink('child', element), divisionCode, code, 'Sales', region)
                    element.history = getSBSReport_HO_history(getReportLink('history', element), divisionCode, code, 'Sales', region)
                } 
                if (element.name.toLowerCase() === consts.TOTAL_ADMIN_COMPLAINTS.toLowerCase()) {
                    element.primary = getSBSReport_TA_primary(getHeldOrderIndexPrimaryReportLink(getReportLink('primary', element), divisionCode, code,'-', region), divisionCode, code, 'Complaints', region, getReportLink('primary', element))
                    element.child = getSBSReport_TA_detail(getReportLink('child', element), divisionCode, code, 'Sales', region)
                    element.history = getSBSReport_TA_history(getReportLink('history', element), divisionCode, code, 'Sales', region)
                } 
                
            } else if (!utils.isEmpty(props && props.selectedProductDivision) || !utils.isEmpty(favProps && favProps.productDivision)) {
                // Product Division Level
                let region = JSON.parse(sessionStorage.getItem("selectedRegion"));
                const { code } = (props && props.selectedProductDivision)?props.selectedProductDivision:favProps.productDivision
                if (element.name.toLowerCase() === consts.HELD_ORDER_INDEX.toLowerCase()) {
                    element.primary = getHeldOrderIndexPrimaryReportRegionLink(getReportLink('primary', element), code,'-','-',region); // Added for EMEA release
                    element.child = getProductDivisionReportHODetail(getReportLink('child', element), code, 'Sales', region)
                    element.history = getProductDivisionReportHOHistory(getReportLink('history', element), code, 'Sales', region)
                }  
                if (element.name.toLowerCase() === consts.TOTAL_ADMIN_COMPLAINTS.toLowerCase()) {
                    element.primary = getProductDivisionReport_TAC_Primary(getHeldOrderIndexPrimaryReportRegionLink(getReportLink('primary', element), code,'-','-',region), code, 'Complaints',region, getReportLink('primary', element)) //added for EMEA release
                    element.child = getProductDivisionReportTACDetail(getReportLink('child', element), code, 'Sales', region)
                    element.history = getProductDivisionReportTACHistory(getReportLink('history', element), code, 'Sales', region)
                } 
                
            } else if(!utils.isEmpty(props && props.selectedSubRegion) || !utils.isEmpty(favProps && favProps.subregion)){
                //SubRegion Level
                let region = JSON.parse(sessionStorage.getItem("selectedRegion"));
                const { code } = (props && props.selectedSubRegion)?props.selectedSubRegion:favProps.subregion
                

                if (element.name.toLowerCase() === consts.HELD_ORDER_INDEX.toLowerCase()) {
                    element.primary = getHeldOrderIndexSubRegionPrimary(getReportLink('primary', element), '-','-','-',region,code); // Added for EMEA release
                    element.child = getHeldOrderIndexSubRegionDetail(getReportLink('child', element),'-' , 'Sales', region, code)
                    element.history = getHeldOrderIndexSubRegionHistory(getReportLink('history', element),  '-', 'Sales', region, code)
                }
               
            
                if (element.name.toLowerCase() === consts.TOTAL_ADMIN_COMPLAINTS.toLowerCase()){
                    element.primary = getTotalAdminComplaintsSubRegionPrimary(getHeldOrderIndexSubRegionPrimary(getReportLink('primary', element),'-' ,'-','-',region,code), '-', 'Complaints',region,code) //added for EMEA release
                    element.child = getTotalAdminComplaintsSubRegionDetail(getReportLink('child', element), '-', 'Sales', region, code)
                    element.history = getTotalAdminComplaintsSubRegionHistory(getReportLink('history', element), '-', 'Sales', region, code)
                }
            
            }
            else {
                //Group Level
                let region = JSON.parse(sessionStorage.getItem("selectedRegion"));
                if (element.name.toLowerCase() === consts.HELD_ORDER_INDEX.toLowerCase()) {
                    element.primary = getHeldOrderIndexPrimaryReportLink(getReportLink('primary', element),'-','-','-',region);
                }

                if ( element.name.toLowerCase() === consts.TOTAL_ADMIN_COMPLAINTS.toLowerCase()) {
                    element.primary = getTAC_groupPrimaryReport(getReportLink('primary', element),'-','-','-',region);
                }
             
                if (element.name.toLowerCase() === consts.HELD_ORDER_INDEX.toLowerCase()) {
                    element.child = getReportLink('child', element) +`&filter=Company_Region_Hold/company_region eq '${region}'`;
                    element.history = getReportLink('history', element) +`&filter=Hold/company_region eq '${region}'`;
                }

                if (element.name.toLowerCase() === consts.TOTAL_ADMIN_COMPLAINTS.toLowerCase()){

                    element.child = getReportLink('child', element) + `&filter=Complaints/company_region eq '${region}'`;
                    element.history = getReportLink('history', element)+ `&filter=Complaints/company_region eq '${region}'`;
                }

            }
        } 
        //Availability Index --- Perfect Order Index -- Invoicing Index -- AR dispute Index
        else if (element.name.toLowerCase() === consts.AVAILABILITY_INDEX.toLowerCase()
            || element.name.toLowerCase() === consts.PERFECT_ORDER_INDEX.toLowerCase()
            || element.name.toLowerCase() === consts.INVOICING_INDEX.toLowerCase()
            || element.name.toLowerCase() === consts.AR_DISPUTE_INDEX.toLowerCase()) {
            const { code: divisionCode } = (props && props.selectedProductDivision)?props.selectedProductDivision:favProps.productDivision
            const { code: sbsCode } = (props && props.selectedSBS)?props.selectedSBS:favProps.sbs;
            const { code: customerCode1 } = (props && props.selectedCustomer)?props.selectedCustomer:favProps.customer;
            const { code: subRegion } = (props && props.selectedSubRegion)?props.selectedSubRegion:favProps.subregion
            const customerCode = (customerCode1)?encodeURIComponent(customerCode1):customerCode1;
            let region = JSON.parse(sessionStorage.getItem("selectedRegion"));
            if (element.name.toLowerCase() === consts.AVAILABILITY_INDEX.toLowerCase()) {
                element.primary = getReport_salesmetrics_avail_primary(getReportLink('primary', element), divisionCode, customerCode, sbsCode, region, subRegion);
            } else {
                element.primary = getReport_salesmetrics_primary(getReportLink('primary', element), divisionCode, customerCode, sbsCode, region, subRegion);
            }

            if (!utils.isEmpty(props && props.selectedCustomer) || !utils.isEmpty(favProps && favProps.customer)) {
                if (!utils.isEmpty(props && props.selectedProductDivision) || !utils.isEmpty(favProps && favProps.productDivision)) {
                    //Product Div Customer Level
                    //const { code: divisionCode } = props.selectedProductDivision
                    const { code:CustomerName } = (props && props.selectedCustomer)?props.selectedCustomer:favProps.customer
                    const code = (CustomerName)?encodeURIComponent(CustomerName):CustomerName;
                    let region = JSON.parse(sessionStorage.getItem("selectedRegion"));
                    if (element.name.toLowerCase() === consts.INVOICING_INDEX.toLowerCase()) {
                        element.child = getDetailedReport_Invoice(getReportLink('child', element), divisionCode, code, sbsCode, region)
                    }
                    if (element.name.toLowerCase() === consts.PERFECT_ORDER_INDEX.toLowerCase() || element.name.toLowerCase() === consts.AVAILABILITY_INDEX.toLowerCase()) {
                        element.child = getCustomer_PDReport_POAL_detail(getReportLink('child', element), divisionCode, code, 'Sales', region)
                    } 
                    if (element.name.toLowerCase() === consts.AR_DISPUTE_INDEX.toLowerCase()) {
                        element.child = getCustomer_PDReport_AR_detail(getReportLink('child', element), divisionCode, code, 'Invoice', region)
                     } 
                    if (element.name.toLowerCase() === consts.AR_DISPUTE_INDEX.toLowerCase() || element.name.toLowerCase() === consts.INVOICING_INDEX.toLowerCase()) {
                        element.history = getCustomer_PDReport_AR_history(getReportLink('history', element), divisionCode, code, 'Invoice', region)
                    } else {
                        element.history = getCustomer_PDReport_POAL_history(getReportLink('history', element), divisionCode, code, 'Sales', region)
                    }
                } else {
                    //Group Customer Level
                    const { code:CustomerName } = (props && props.selectedCustomer)?props.selectedCustomer:favProps.customer
                    const code = (CustomerName)?encodeURIComponent(CustomerName):CustomerName;
                    let region = JSON.parse(sessionStorage.getItem("selectedRegion"));
                    if (element.name.toLowerCase() === consts.INVOICING_INDEX.toLowerCase()) {
                        element.child = getDetailedReport_Invoice(getReportLink('child', element), divisionCode, code, sbsCode, region)
                    } 
                    if (element.name.toLowerCase() === consts.PERFECT_ORDER_INDEX.toLowerCase() || element.name.toLowerCase() === consts.AVAILABILITY_INDEX.toLowerCase()) {
                        element.child = getCustomerReport_POAL_detail(getReportLink('child', element), code, 'Sales', region)
                    } 
                    if (element.name.toLowerCase() === consts.AR_DISPUTE_INDEX.toLowerCase()) {
                        element.child = getCustomerReport_AR_detail(getReportLink('child', element), code, 'Invoice', region)
                    } 
                  
                    if (element.name.toLowerCase() === consts.AR_DISPUTE_INDEX.toLowerCase() || element.name.toLowerCase() === consts.INVOICING_INDEX.toLowerCase()) {
                        element.history = getCustomerReport_AR_history(getReportLink('history', element), code, 'Invoice', region)
                    } else {
                        element.history = getCustomerReport_POAL_history(getReportLink('history', element), code, 'Sales', region)
                    }
                }
            } else if(!utils.isEmpty(props && props.selectedSubRegion) || !utils.isEmpty(favProps && favProps.subregion)){
                //Sub Region Level
             //  const { code } = (props && props.selectedProductDivision)?props.selectedProductDivision:favProps.productDivision
               const { code } = (props && props.selectedSubRegion)?props.selectedSubRegion:favProps.subregion
               let region = JSON.parse(sessionStorage.getItem("selectedRegion"));
               //Detail/Child
               if (element.name.toLowerCase() === consts.INVOICING_INDEX.toLowerCase()) 
                   element.child = getDetailedReport_Invoice(getReportLink('child', element), undefined, undefined, undefined, region, code)
               
               if (element.name.toLowerCase() === consts.PERFECT_ORDER_INDEX.toLowerCase() || element.name.toLowerCase() === consts.AVAILABILITY_INDEX.toLowerCase()) 
                 element.child = getSubRegionReportDetailPOAL(getReportLink('child', element), '-', 'Sales', region, code)
              
               if (element.name.toLowerCase() === consts.AR_DISPUTE_INDEX.toLowerCase()) {
                   element.child = getSubRegionReportDetailAR(getReportLink('child', element), '-', 'Invoice', region, code)
               } 
       
               //History
               if (element.name.toLowerCase() === consts.AR_DISPUTE_INDEX.toLowerCase() || element.name.toLowerCase() === consts.INVOICING_INDEX.toLowerCase()) {
                  element.history = getARHistorySubRegion(getReportLink('history', element), '-', 'Invoice', region, code)
               } else {
                 element.history = getPOALHistorySubRegion(getReportLink('history', element), '-', 'Sales', region, code)
                 
               }
            }
            else if (!utils.isEmpty(props && props.selectedSBS) || !utils.isEmpty(favProps && favProps.sbs)) {
                //Business Unit Level
                const { code } = (props && props.selectedSBS)?props.selectedSBS:favProps.sbs;
                let region = JSON.parse(sessionStorage.getItem("selectedRegion"));
                let url;
                //const { code: divisionCode } = props.selectedProductDivision;
                if (element.name.toLowerCase() === consts.INVOICING_INDEX.toLowerCase()) {
                    element.child = getDetailedReport_Invoice(getReportLink('child', element), divisionCode, undefined, code, region)
                }
                if (element.name.toLowerCase() === consts.PERFECT_ORDER_INDEX.toLowerCase() || element.name.toLowerCase() === consts.AVAILABILITY_INDEX.toLowerCase()) {
                    element.child = getSBSReport_POAL_detail(getReportLink('child', element), divisionCode, code, 'Sales', region)
                } 
                if (element.name.toLowerCase() === consts.AR_DISPUTE_INDEX.toLowerCase()) {
                    element.child = getSBSReport_AR_detail(getReportLink('child', element), divisionCode, code, 'Invoice', region)
                } 
                if (element.name.toLowerCase() === consts.AR_DISPUTE_INDEX.toLowerCase() || element.name.toLowerCase() === consts.INVOICING_INDEX.toLowerCase()) {
                    element.history = getSBSReport_AR_history(getReportLink('history', element), divisionCode, code, 'Invoice', region)
                } else {
                    element.history = getSBSReport_POAL_history(getReportLink('history', element), divisionCode, code, 'Sales', region)
                }
            } else if (!utils.isEmpty(props && props.selectedProductDivision) || !utils.isEmpty(favProps && favProps.productDivision)) {
                //Product Division Level
                let region = JSON.parse(sessionStorage.getItem("selectedRegion"));
                const { code } = (props && props.selectedProductDivision)?props.selectedProductDivision:favProps.productDivision
                if (element.name.toLowerCase() === consts.INVOICING_INDEX.toLowerCase()) {
                    element.child = getDetailedReport_Invoice(getReportLink('child', element), code, undefined, undefined, region)
                }
                if (element.name.toLowerCase() === consts.PERFECT_ORDER_INDEX.toLowerCase() || element.name.toLowerCase() === consts.AVAILABILITY_INDEX.toLowerCase()) {
                  element.child = getProductDivisionReportDetailPOAL(getReportLink('child', element), code, 'Sales', region)
                } 
                if (element.name.toLowerCase() === consts.AR_DISPUTE_INDEX.toLowerCase()) {
                    element.child = getProductDivisionReportDetailAR(getReportLink('child', element), code, 'Invoice', region)
                } 
          
                if (element.name.toLowerCase() === consts.AR_DISPUTE_INDEX.toLowerCase() || element.name.toLowerCase() === consts.INVOICING_INDEX.toLowerCase()) {
                    element.history = getProductDivisionReportHistoryAR(getReportLink('history', element), code, 'Invoice', region)
                } else {
                   element.history = getProductDivisionReportHistoryPOAL(getReportLink('history', element), code, 'Sales', region)
                }
            } else {
                //Group Level
                let region = JSON.parse(sessionStorage.getItem("selectedRegion"));
                if (element.name.toLowerCase() === consts.INVOICING_INDEX.toLowerCase()) {
                    element.child = getDetailedReport_Invoice(getReportLink('child', element),undefined,undefined,undefined,region)
                }
                if (element.name.toLowerCase() === consts.PERFECT_ORDER_INDEX.toLowerCase() || element.name.toLowerCase() === consts.AVAILABILITY_INDEX.toLowerCase()) {
                    element.child = getReportLink('child', element) + `&filter=Sales/company_region eq '${region}'`;
                }
                if( element.name.toLowerCase() === consts.AR_DISPUTE_INDEX.toLowerCase()){
                    element.child = getReportLink('child', element) + `&filter=Invoice/company_region eq '${region}'`; 
                }    
                // } else {
                //     element.child = getReport_salesmetrics_avail_primary(getReportLink('child', element),'-','-','-',region)
                // }

                if (element.name.toLowerCase() === consts.AR_DISPUTE_INDEX.toLowerCase() || element.name.toLowerCase() === consts.INVOICING_INDEX.toLowerCase()) {
                element.history = getReportLink('history', element) + `&filter=Invoice/company_region eq '${region}'`
                }
                else{
                element.history = getReportLink('history', element)+ `&filter=Sales/company_region eq '${region}'`
                }
            }
        }
    })
    return cxList;
}