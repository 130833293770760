import { SET_GROUP,SET_USER_INFO, SET_REGION } from "../constant";

const GroupReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_GROUP:
            return {
                ...state,
                data: action.data
            }
        case SET_USER_INFO:
            return {
                ...state,
                userInfo: action.data
            }
        case SET_REGION:
            return{
                ...state,
                region: action.data
            }
        default:
            return state
    }
}

export default GroupReducer