import React, { useEffect } from 'react'
import c3 from "c3";

function BarChart(props) {
    const { id, height, y2axis } = props
    const keys = [...props.keys]
     const values = [...props.values];
     const getMillionVal= values.map(val=>(val/1000000).toFixed(2));
    const pctGrowth = [...props.pctGrowth]
    useEffect(() => {
        c3.generate({
            bindto: `#${id}`,
            size: {
                height: height
            },
            bar: {
                width: 10
            },
            padding: {
                left: 120
            },
            data: {
                x: 'x',
                columns:
                    [
                        ['x', ...keys],
                         ['Revenue', ...getMillionVal],
                         [`${y2axis}`, ...pctGrowth]
                    ],
                type: 'bar',
                axes: {
                    [`${y2axis}`]: 'y2'
                }//,
               // labels: true
            },
            axis: {
                rotated: true,
                x: {
                    type: 'category'
                },
                y2: {
                    show: false
                }
            },
            tooltip: {
                grouped: false,
                format: {
                    value: function (value, ratio, id) {
                        if (id === 'Growth' || id === 'Contribution') return `${value}%`
                        const revenue = y2axis === 'Contribution' ? `$${value}M` : `$${value}M`
                        return revenue
                    }
                }
            },
            legend: {
                show: false  //Remove Contribution % from 5 Top Customers/SGNs by Revenue and Contribution
            }
        })
    }, [])
    return (
        <div id={id} />
    )
}

export default BarChart