import { SET_SUB_REGION } from "../constant";

const SubRegionReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_SUB_REGION:
            return {
                ...state,
                data: action.data
            }
        default:
            return state
    }
}

export default SubRegionReducer